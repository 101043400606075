import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import { TextBoxLatest } from "../../common/TextBoxLatest/TextBoxLatest";
import Breadcrumbs from "../../common2/Breadcrumbs";
import { generateErrorList } from "../../../pages/new-ticket/LatestTicket/Form";
import { ValidationResultS } from "../../../pages/new-ticket/LatestTicket/Type";

const AddTeamsConfig = () => {
  const param: any = useParams();
  const mailIdRef: any = createRef();
  const nameRef: any = createRef();
  const bodyRef: any = createRef();
  const subjectRef: any = createRef();
  const fromRef: any = createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = React.useState<ValidationResultS>({
    isValid: false,
    errors: {},
  });
  console.log("param", param);

  const submitHandler = () => {
    
  };
  const backHandler = () => {
    navigate("/TeamsConfig");
  };

  useEffect(() => {
    // if (param?.id) {
    //   dispatch(getEmailExceptionByID(param.id, getTemplateByIdCB));
    // }
  }, []);
  const getTemplateByIdCB = (val: any) => {
    console.log("response", val);
    toggleForm(val);
  };
  const toggleForm = (val: any) => {
    if (mailIdRef?.current) mailIdRef.current.value = val.mailId;
    if (fromRef?.current) fromRef.current.value = val.from;
    if (nameRef?.current) nameRef.current.value = val.name;
    if (bodyRef?.current) bodyRef.current.value = val.body;
    if (subjectRef?.current) subjectRef.current.value = val.subject;
  };

  return (
    <>
      <Breadcrumbs
        data={[
          {
            title: "System Management",
            path: "/adminPanel",
          },
          {
            title: "Teams Config",
            path: "/TeamsConfig",
          },
          {
            title: `${!param.id ? "Add " : "Edit "}Teams Config`,
            path: `${
              !param.id
                ? "/AddTeamsConfig"
                : `/AddTeamsConfig/${param.id}`
            }`,
          },
        ]}
      />
      <br />
      <>
        <Box className="mb2 pb2 email-templates-headings">
          <Typography variant="h6" className="emailFields">
            {!param.id ? "Add " : "Edit "}
            Teams Config
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item md={3} xs={3}>
            <Box className="emailFields">
              <TextBoxLatest
                placeholder="Plase Enter"
                title={"Title"}
                ref={nameRef}
                isMandotary={true}
              />
              {generateErrorList(error.isValid, error.errors, "name")}
              <TextBoxLatest
                placeholder="Plase Enter"
                title={"Client ID "}
                ref={fromRef}
                isMandotary={true}
              />
              <TextBoxLatest
                placeholder="Please Enter"
                title={"Tenent ID"}
                ref={mailIdRef}
                isMandotary={true}
              />
              {generateErrorList(error.isValid, error.errors, "email")}
              <TextBoxLatest
                placeholder="Please Enter"
                title={"Client Secret"}
                ref={subjectRef}
                isMandotary={true}
              />
            </Box>
            <Box style={{ textAlign: "center" }}>
              <Button
                className="emailFields-button"
                variant="outlined"
                onClick={backHandler}
              >
                <CancelIcon style={{ marginRight: "5px" }} />
                Cancel
              </Button>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Button
                className="emailFields-button"
                variant="contained"
                onClick={submitHandler}
              >
                {!param?.id ? "Save" : "Update"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default AddTeamsConfig;
