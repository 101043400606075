import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getRole, getSystemTypes, singleRole, updateRole } from '../../../redux/actions/userManagement/role'
import { IState } from '../../../redux/reducers/rootReducers';
import ArticlesTable from './ArticlesTable';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import CustomSelect from '../../common/customSelect/CustomSelect';
import { iFormData } from '../../../pages/new-ticket/LatestTicket/Type';
import Breadcrumbs from "./../../common2/Breadcrumbs";
import { getArticles } from '../../../redux/actions/KnowledgeBase/article';
import { handleTime } from '../../Dashboard/TicketManagement/TicketDashboardHolder';
export const generateList = (articleId:any,adminTypeId=431,allowAccessPermissionId=null) =>{
    const permissionFormat = {
        "adminTypeId": adminTypeId,//systemType [id]adminTypes[adminTypeId]
        "adminChildIdLeavel1": articleId,// INCIDENT (COL-1) [ID]
        "adminChildIdLeavel2": null,
        "adminChildIdLeavel3": null,
        "adminChildIdLeavel4": null,
        "permissionId": allowAccessPermissionId,//PERMISSIONS LIST - (ALLOW ACCESS -ID(18)) - STATIC
        "isActive": true,
        "isContact":false,//SSP role
        "isAgentAdmin": true, // FROM AGENT PT ADMIN
        "isSystemAdmin": false // FROM admin PT ADMIN
    };
    return permissionFormat;
}
interface IArticlesAndPermissionsProps {
    systemTypes?:any;
    rolesList?:any[];
    permissions?:any[]
}
const ArticlesAndPermissions = (props:IArticlesAndPermissionsProps) =>{
    const {systemTypes={},rolesList=[],permissions=[]} = props;
    const dispatch = useDispatch();
    const [allArticles, setAllArticles] = useState<any>([]);
    const [roles,setRoles] = useState([]);
    // const systemTypes:any = useSelector((state: IState) => state?.systemTypeReducer?.systemTpesByName);
    // const rolesList = useSelector((state: IState) => state?.roleReducer?.Data?.data);
    useEffect(()=>{
        dispatch(getRole())
        if(!(systemTypes && Object.keys(systemTypes).length > 0)){
            dispatch(getSystemTypes())
        }
    },[])
    useEffect(()=>{
        if(systemTypes?.Article?.id){
            dispatch(getArticles(getCallback))
        }
    },[systemTypes?.Article?.id])
    const getCallback = (resStatus:string,res:any)=>{

        if(resStatus==="0"){
            const allowAccessPermissionId = permissions.find((x:any)=>x.name==="AllowAccess")?.id;
            const tempArr:any[] = [];
            res.map((i:any,index:number)=>{
                if(i.isActive){
                    console.log('generateList',systemTypes?.Article?.id);
                    tempArr.push({
                        name : i.name,
                        id: i.id,
                        ...generateList(i?.id,systemTypes?.Article?.id,allowAccessPermissionId),
                    })
                }
            })
            setAllArticles(tempArr);
        }
    }
    useEffect(()=>{
        if(rolesList?.length){
            const rolesFiltered:any = [];
            rolesList.filter((x:any)=>{
                if(x.isActive && !x.isSuperAdmin){
                    rolesFiltered.push({
                        id: x.id,
                        label: x.name,
                        value: x.name
                    })
                }
            })
            setRoles(rolesFiltered);
        }
    },[rolesList?.length])
    return(<>
        {(systemTypes?.Article && allArticles.length>0 && roles.length>0) &&
            <ArticlesAndPermissions1 adminTypeId={systemTypes?.Article?.id} allArticles={allArticles} roles={roles}  />
        }
    </>)
}
function ArticlesAndPermissions1(props:any) {
    const {allArticles = [],roles=[],adminTypeId=0} = props;
    const dispatch = useDispatch();
    const [loader,setLoader] = useState<boolean>(false);
    const [rolesVal,setRolesVal] = useState<any>(null);
    const [selectedArr,setSelectedArr] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const [rolePermissions, setRolePermissions] = useState<any>({
        article : [],
        otherRoles : [],
    });
    const [roleRes, setRoleRes] = useState<any>(null); 

    /* ===== configuring list values  ===== */
   
   
    

    


    /* ===== TABLE CALLBACK ===== */
    const ArticlesTableCB = (arr:any=[]) =>{
        setSelectedArr(arr)
    }
    


    /* =======  ONCHANGE ROLE HANDLERS ======= */
    const getRoleById = (id:number|string=0) =>{
        setList([]);
        setLoader(true)
        handleTime.cancel();
        if(id===0) {
            setLoader(false)
            return;
        };
        handleTime.setup(()=>{
            dispatch(singleRole(id,getRoleByIdCB))
        });
    }

    const getRoleByIdCB = (resStatus:string,res:any) =>{
        setLoader(false)
        if(resStatus === "0"){
            setRoleRes(res);
            if(res?.agentPermissionsList){
                // list
                // tableData : {id: index, checked: true}
                const recordById:any={}
                const tempObj:any = {
                    article : [],
                    otherRoles : [],
                } 
                res?.agentPermissionsList.map((i:any)=>{
                    if(i.adminTypeId === adminTypeId && (i.isAgentAdmin || i.isContact)){
                        tempObj.article.push(i);
                        recordById[i.adminChildIdLeavel1] = i;
                    }else {
                        tempObj.otherRoles.push(i);
                    }
                })
                setRolePermissions(tempObj)

                const modifiedData:any = allArticles.map((i:any,index:number)=>{
                    i.recordId = recordById?.[i.id]?.id || 0;
                    i.isAgentAdmin = res.isSSPRole ? false : true; // FROM AGENT PT ADMIN
                    i.isContact = res.isSSPRole || false; // FROM admin PT ADMIN
                    i.tableData = {id: index, checked: recordById?.[i.id] ? true : false};
                    return i;
                })
                setList(modifiedData)
                console.log('getRoleByIdCB_res',allArticles,recordById,modifiedData)
                
            }
        }
    }


    /* =======  on page submit Handlers ======= */
    const onFormSubmit = (event:any) =>{
        event?.preventDefault();      
        handleTime.cancel();
        handleTime.setup(()=>{
            const arr:any =[]
            selectedArr.map((x:any)=>{
                arr.push({
                    ...x,
                    id : x.recordId
                });
            })
            dispatch(updateRole({
                ...roleRes,
                agentPermissionsList : [...rolePermissions.otherRoles, ...arr]
            },rolesVal?.id,onFormSubmitCB))
        },100);
        
    }
    const onFormSubmitCB = (resStatus:string,res:any) =>{
        if(resStatus === "0"){
            setList([])
            setSelectedArr([]);
            handleTime.cancel();
            handleTime.setup(()=>{
                getRoleById(res.id)
            },100);
        }
    }
    return (
        <Box className="p-1">
            <Breadcrumbs
                data={[
                    {
                        title: 'Knowledge Base ',
                        path: '/KnowledgeBase'
                    },
                    {
                        title: 'Articles & Permissions',
                        path: '/ArticlesAndPermissions'
                    }
                ]
                }
            />
            <Box className="pt-1"></Box>
            <Box className="white-bg p-2 Articles-permission">
                <form onSubmit={onFormSubmit}>
                    <Typography variant="h5">
                        Articles & Permissions
                    </Typography>
                    <hr />
                    <Grid container className='pt-1' spacing={2}>
                        <Grid item md={3} xs={12}>
                            <aside className="aside">
                                <Box>
                                    <CustomFormLabel
                                        labelName={"Select Role"}
                                        isMandotary={true}
                                        classNames='sml-txt'
                                    />
                                    <CustomSelect
                                        isOpen={false}
                                        placeholder="Select Role"
                                        width={"100%"}
                                        name="roleId"
                                        isColor={false}
                                        isDisabled={false}
                                        isError={false}
                                        helperText={``}
                                        defaultValue={rolesVal}
                                        options={roles}
                                        handleChange={(e: any) => {
                                            setRolesVal(e)
                                            getRoleById(e?.id)
                                        }}
                                        isMulti={false}
                                            customClassNames="sml-txt-dropdown new-service"
                                        />
                                </Box>
                                <Box className="pt-1">
                                    <Button className='txtCapitalize' type='submit' variant='contained' size='small'>
                                        Update Role
                                    </Button>
                                </Box>
                            </aside>
                        </Grid>
                        <Grid item md={9} xs={12}>
                            {loader && <>Loading...!</>}
                            {list.length > 0 && 
                                <ArticlesTable list={list} callback={ArticlesTableCB} />
                            }  
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}





const mapStateToProps = (state:IState) => {
    return ({
        systemTypes : state?.systemTypeReducer?.systemTpesByName,
        rolesList : state?.roleReducer?.Data?.data,
        permissions : state?.permissionReducer?.Data?.data
    })
};
export default connect(mapStateToProps)(ArticlesAndPermissions);