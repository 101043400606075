const initialState = {
  Data: [],
  showLoading: false,
  LOGGED_URS_ROLE:{},
  EditRole:{},
  agentCheckBoxStatus:{},
  checkedStatus:{},
  roleInfo:{},
  rankingObj:{
    Priorities : {},
    TicketStatus : {}
  }
};
const returnArr = (arr:any=[],val:number,method:string)=>{
  
  if(method==="add"){
    return arr.concat(val)
  }else{
    return arr.filter((i:number)=>i!==val)
  }
} 
const returnAgentObj = (reducerValue:any,payload:any) =>{
  const {keyLabel,value,method,fieldId} = payload;
  const tempObj = {...reducerValue}
  if(!tempObj[keyLabel]) {
    tempObj[keyLabel] = {};
  }
  if(!tempObj[keyLabel][fieldId]){
    tempObj[keyLabel][fieldId] = [];
  }
  if(method==="add"){
    tempObj[keyLabel][fieldId].push(value);
  }else{
    tempObj[keyLabel][fieldId] = tempObj[keyLabel][fieldId].filter((i:number)=>i!==value);
  }
  console.log('returnAgentObj',tempObj);
  return tempObj;
}
const roleReducer = (state:any = initialState, action: any) => {
  if(!action?.payload) return state;
  console.log('action?.payload;',action?.payload)
  const {keyLabel,value,method,fieldId} = action?.payload;
  switch (action.type) {
    case "UNSET_ROLE_CHECKBOX_VALS":
      return {
        ...state,
        EditRole:{},
        agentCheckBoxStatus:{},
        checkedStatus:{},
        // roleInfo:{},
        rankingObj:{
          Priorities : {},
          TicketStatus : {}
        }
    };
    case "getRole":
      return {
        ...state,
        Data: action.payload,
      };
    case "LOGGEDIN_USR_ROLE":
      return {
        ...state,
        LOGGED_URS_ROLE: action.payload,
      };
    case "AGENT_CHECKBOX_STATUSES":
      //  {
      //   ...state,
      //   agentCheckBoxStatus: {
      //     //...state.agentCheckBoxStatus,
      //     ...returnAgentObj(state.agentCheckBoxStatus,action?.payload)
      //   },
      // };
    const tempObj = JSON.parse(JSON.stringify(state))
    tempObj.agentCheckBoxStatus  = {
      ...returnAgentObj(tempObj.agentCheckBoxStatus,action?.payload)
    }
    return tempObj;
    case "SELECT_ALL_AGENT_OPTION":
      return {
        ...state,
        agentCheckBoxStatus: {
          ...state?.agentCheckBoxStatus,
          [action?.payload?.key] : action?.payload?.value?.[action?.payload?.key]
        }
      };
    case "STORE_ROLE_CATEGORIES_OPTION":
        return {
          ...state,
          agentCheckBoxStatus: {
            ...state?.agentCheckBoxStatus,
            ["Categories"] : action?.payload
          }
      };
    case "CHECKBOX_STATUSES":
      return {
        ...state,
        checkedStatus: {
          ...state.checkedStatus,
          [keyLabel] : returnArr(state.checkedStatus[keyLabel],value,method)
        },
      };
    case "STORE_EDIT_ROLE_VALUE":
      return {
        ...state,
        agentCheckBoxStatus: action?.payload?.agentCheckBoxStatus,
        checkedStatus: action?.payload?.checkedStatus,
        rankingObj: action?.payload?.rankingObj
      };
    case "ROLE_INFO":
      return {
        ...state,
        roleInfo : action.payload,
      };
    case "SELECT_ALL_OPTION":
      return {
        ...state,
        checkedStatus: {
          ...state.checkedStatus,
          ...action.payload
        }
      };
    case "addRole":
      return {
        ...state,
        Data: action.payload,
      };
    case "singleRole":
      return {
        ...state,
        EditRole: action.payload,
      };
    case "UNSET_CHECKED_ITEMS" : 
      return {
        ...state,
        agentCheckBoxStatus:{},
        checkedStatus:{},
      }  
    case "UNSET_AGENT_ROLE_ITEMS" : 
      return {
        ...state,
        agentCheckBoxStatus:{}
      }
    case "UNSET_SYSTEM_ROLE_ITEMS" : 
      return {
        ...state,
        checkedStatus:{}
      }    
    case "updateRole":
    case "multipalUpdateRole":
    case "softDeleteRole":
      return {
        ...state,
      };
    case "deleteRole":
      return {
        ...state,
      };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
};

export default roleReducer;
