import React from "react";
import { IconButton } from "@mui/material";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import logoImg from "../../../assest/icons/logo.png";
import CommonStyles from "./../../common/CommonStyles";
import { Box } from "@mui/material";

const useStyles = CommonStyles;
const MobileMenu: React.FC = () => {
  const classes = useStyles();
  const handleMenu = () => {
    const bodyTag = document.getElementsByTagName("body");
    bodyTag[0].classList.add("menuOpened");
  };
  return (
    <>
      <Box className={`${classes.mobileMenu} mobileMenu-logo`}>
        <div >
          <img src={logoImg} alt="" />
        </div>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <DensityMediumIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default MobileMenu;
