import React, { useEffect, useRef, useState } from "react";
import CommonStyles from "../common/CommonStyles";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./ReportChart";
import {
  lineChartOptionsData,
  barChartOptionsData,
  columnChartOptionsData,
} from "./ReportOptions";
import Breadcrumbs from "./../common2/Breadcrumbs";
import { exportMultipleChartsToPdf } from "./ReoprtUtils";
import CommonAppFilter from "../navbar/sideNavbar/CommonAppFilterCopy";
import { IState } from "../../redux/reducers/rootReducers";
import InputBox from "../common2/InputBox";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import AppLoader from "../common/AppLoader";
import LogoImg from "./../../assest/logo.png";
import { printColumns } from "./ReportCSV";
import {
  ColumnBarIcon,
  PieChartIcon,
  DonutBarIcon,
} from "../common/svgIcons/chartIcon";
import { url as AssetUrl } from "../../redux/actions/AssetsManagement/Assets";
import {
  formInfoData,
  getAllDAshboardData,
  multiApiCalls,
} from "../../redux/actions/TicketsManagement/TicketsManagement";
import { getTicketsUrl } from "../../../src/services/dashboard/dashboard.service";
import { getAllContactsInfo } from "../../services/cmdb/Organization";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import getReportsAction, {
  getReportById,
  addEditReportAction,
} from "../../redux/actions/Reports/reports";
import { useNavigate, useParams } from "react-router-dom";
import { storeFiltersValue } from "../../redux/actions/TicketsManagement/GetTickets";
import CustomModalDialog from "../common/customDialog";
import PivotTableModal from "./PivotTableModal";
import moment from "moment";
import { getProfileImage } from "../../redux/actions/userManagement/agents";
import { categorizeDateRange, getDateRange } from "../helpers/helperFunctions";
import { addDays, subYears } from "date-fns";
import { getOrganisationWithToken } from "../../redux/actions/FreeTrial/FreeTrial";

const generateTimeRange = (FiltersVal: any) => {
  if (FiltersVal?.rangeVal) {
    return (
      <p
        style={{
          color: "black",
          textDecoration: "underline",
          marginTop: "10px",
          fontSize: "14px",
        }}
      >
        {moment(FiltersVal?.rangeVal?.startDate).format("L")} -{" "}
        {moment(FiltersVal?.rangeVal?.endDate).format("L")}
      </p>
    );
  }
};

let timeout: any = null;
function PivotTableReportView() {
  const param: any = useParams();
  const classes = CommonStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    id: 0,
    name: "",
    chartType: "",
    isActive: true,
    filter: "",
  });
  const [showAddNew, setShowAddNew] = useState(false);
  const [chartEditIndex, setChartEditIndex] = useState(-1);
  const [chartTitle, setChartTitle] = useState("");
  const [reportTitle, setReportTitle] = useState("");
  const [dataModal, setDataModal] = useState<any>({});
  const [ContactsInfo, setContactsInfo] = useState({} as any);
  const [isOpen, setIsOpen] = useState(false);
  const [chartsList, setChartsList] = useState<any>([]);
  const [imgList, setImgList] = useState<any>([]);
  const [allTickets, setallTickets] = useState([]);
  const [radioVal, setRadioVal] = useState("ticketType");
  const profileImg = useSelector(
    (state: IState) => state?.agentReducer?.saveOrgProfile
  );
  const [state, setState] = useState<any>({});

  const AllInfo: any = useSelector(
    (state: IState) => state?.TicketsReducer?.alldataStructure
  );
  const AllReports = useSelector(
    (state: IState) => state?.ReportsReducer?.AllReports
  );
  const [loader, setloader] = useState(false);
  const AllTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.otherTickets
  );
  const FiltersVal: any = useSelector(
    (state: IState) => state?.filtersTicketManagementReducer
  );
  const currOrganisation = useSelector(
    (state: IState) => state?.FreeTrialReducer?.getLoggedOrganisation
  );

  const loaderSf: any = useSelector(
    (state: IState) => state?.LayoutTicketManagementReducer?.showLoader
  );

  const [logoSrc, setLogoSrc] = useState(profileImg);

  useEffect(() => {
    let currAgentInfo = { ...currOrganisation };
    let docId = currAgentInfo.organisationAvatarId;
    dispatch(getProfileImage("orgProfile", docId));
  }, [currOrganisation]);

  useEffect(() => {
    dispatch(getOrganisationWithToken());
  }, []);

  useEffect(() => {
    console.log("render render", 1);
    if (param.id) {
      getReportById(param.id, getByIdCB);
    } else {
      setChartsList([]);
      const currentDate = new Date();
      const yesterday = new Date();

      yesterday.setDate(currentDate.getDate() - 1);

      let rangeState = {
        startDate: subYears(new Date(), 1),
        endDate: addDays(new Date(), 0),
        key: "selection",
      };

      dispatch(
        storeFiltersValue(
          { node: "openDateTime", ...rangeState },
          "TCKT_RANGE_VALUE"
        )
      );
    }
    console.log("profileImg", profileImg);
  }, [param.id]);

  useEffect(() => {
    console.log("getbyID", FiltersVal);
  }, [FiltersVal]);

  const getByIdCB = (resStatus: string, res: any) => {
    console.log("checvk check error report black screen", res);

    if (res) {
      const tempCharts: any = JSON.parse(res?.value);
      setChartsList(tempCharts?.values);
      setData({ ...res });
      setReportTitle(res?.name);

      dispatch(
        storeFiltersValue(
          JSON.parse(res?.filterValue)?.agents,
          "TCKT_AGENTS_VALUE"
        )
      );

      let temp: any = JSON.parse(res?.filterValue);
      console.log("temp daterange", temp);
      if (temp.rangeVal.key !== "selection") {
        const dateRange = getDateRange(temp.rangeVal.key);

        if (dateRange) {
          console.log("date check", new Date(dateRange.endDate).toISOString());

          let rangeState = {
            startDate: new Date(dateRange.startDate),
            endDate: new Date(dateRange.endDate),
            key: temp.rangeVal.key,
            node: temp.rangeVal.node,
          };

          console.log("date check", rangeState, temp);

          dispatch(storeFiltersValue(rangeState, "TCKT_RANGE_VALUE"));
        }
      } else {
        dispatch(storeFiltersValue(temp.rangeVal, "TCKT_RANGE_VALUE"));
      }
    }
  };

  const stopLoader = () => {
    setloader(false);
  };
  const exportPDF = () => {
    window.print();
  };
  const fileChangeHandler = (e: any) => {
    console.log(e.target.value);
    var reader: any = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      console.log(reader.result);
      setLogoSrc(reader.result);
    };
    reader.onerror = function (error: any) {
      console.log("Error: ", error);
    };
  };

  const onChangeInputHandler = (e: any) => {
    console.log("e.target.value", e.target.value);
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const PageButtons = (props: any) => {
    const { onClickHandler, title } = props;
    return (
      <Button className={classes.ReportBtns} onClick={onClickHandler}>
        {title}
      </Button>
    );
  };

  const deleteReport = (index: number) => {
    console.log(chartsList[index]);
    const tempRecords = [...chartsList];
    tempRecords.splice(index, 1);
    setChartsList(tempRecords);
  };
  const editReport = (index: number) => {
    console.log("filter value", chartsList[index]);
    setIsOpen(true);
    const tempRec = chartsList[index];
    setChartEditIndex(index);
    setShowAddNew(true);
    setChartTitle(tempRec.title);
    setState(chartsList[index].value);
    const tempObj = {
      title: chartTitle,
      value: {
        cols: state.cols,
        rows: state.rows,
        rendererName: state.rendererName,
        aggregatorName: state.aggregatorName,
        vals: state.vals,
      },
      type: "column",
      default: true,
    };
    const tempArr = [...chartsList];
    tempArr[chartEditIndex] = tempObj;
    setChartsList(tempArr);
  };
  const addRecord = () => {
    console.log("addRecord", chartsList, state);
    const tempObj = {
      title: chartTitle,
      value: {
        cols: state.cols,
        rows: state.rows,
        rendererName: state.rendererName,
        aggregatorName: state.aggregatorName,
        vals: state.vals,
      },
      type: "column",
      default: true,
    };
    if (chartEditIndex === -1) {
      setChartsList([...chartsList, tempObj]);
    } else {
      const tempArr = [...chartsList];
      tempArr[chartEditIndex] = tempObj;
      setChartsList(tempArr);
      setChartEditIndex(-1);
    }
    clearFields();
  };
  const clearFields = () => {
    setRadioVal("ticketType");
    setChartTitle("");
    setShowAddNew(false);
  };
  const saveCallback = (resStatus: string, response: any) => {
    if (resStatus !== "0") return;
    console.log("callback", resStatus, response);
    navigate("/All-Reports");
  };
  const saveReport = () => {
    if (data?.name?.length) {
      console.log("date check --- , ", FiltersVal);
      let temp = categorizeDateRange(
        FiltersVal.rangeVal.startDate,
        FiltersVal.rangeVal.endDate
      );
      let newRangeValue = FiltersVal.rangeVal;
      if (temp) {
        newRangeValue = {
          node: FiltersVal.rangeVal.node,
          startDate: "2022-1_0-17T05:58:29.248Z",
          endDate: "2023-10-17T05:58:29.248Z",
          key: temp,
        };
      }
      const tempReq = {
        ...data,
        value: JSON.stringify({ values: chartsList }),
        filterValue: JSON.stringify({
          agents: FiltersVal.agents,
          rangeVal: newRangeValue,
          statuses: FiltersVal.statuses,
          OrgList: FiltersVal.OrgList,
          ticketTypes: FiltersVal.ticketTypes,
          priorities: FiltersVal.priorities,
        }),
      };
      dispatch(addEditReportAction(tempReq, saveCallback, param.id));
      console.log("chartsList", tempReq);
    }
  };
  const handleExportPDF = async () => {
    addRecord();
    setIsOpen(false);
  };
  return (
    <div>
      <AppLoader enabled={loader} />
      <div className="report-hide">
        <Breadcrumbs
          data={[
            {
              title: "Reports Management ",
              path: "/ReportsManagement",
            },
            {
              title: "Custom Reports ",
              path: "/All-Reports",
            },
            {
              title: `${param.id ? "Edit" : "Add New"} Report`,
              path: "/CreateReport",
            },
          ]}
        />
      </div>
      <br className="report-hide" />
      {isOpen && (
        <>
          <CustomModalDialog
            fullScreen={true}
            title=""
            id="upgradeModal"
            isOpen={isOpen}
            isF
            onClose={() => {
              setIsOpen(false);
            }}
            headerContent={<>Pivot table</>}
            bodyContent={
              <>
                {isOpen && (
                  <>
                    <Typography variant="body1" className={classes.title}>
                      Add New Chart
                    </Typography>
                    <InputBox
                      title="Chart Title"
                      classname="h-inputs p-0 reportInput"
                      type="text"
                      placeholder="Please Enter"
                      styleProp={classes.BoxTitle}
                      inputStyle={classes.inputStyle}
                      name="lastName"
                      onChange={(e: any) => setChartTitle(e.target.value)}
                      value={chartTitle}
                    />
                  </>
                )}
                <div className="svg-container">
                  {chartsList.length > 0 && (
                    <PivotTableModal
                      data={AllTickets}
                      onClick={handleExportPDF}
                      state={state}
                      setState={setState}
                    />
                  )}
                </div>
              </>
            }
          />
        </>
      )}
      <Divider style={{ marginBottom: "10px" }} className="report-hide" />
      <div className="report-hide">
        {" "}
        <CommonAppFilter
          report={true}
          hideAddBtn={true}
          hideViewMode={true}
          hideSort={true}
          hideAgent={false}
          hideSearch={true}
          hideFilter={false}
          hideExtraIcons={true}
          customIconFunctions={
            [
              // {
              //   title: "Refresh Tickets",
              //   icon: <ViewCarouselOutlinedIcon />,//ViewCompactOutlinedIcon,ViewCarouselOutlinedIcon
              //   focused: isCSVExport,
              //   focusedIcon: <ViewCompactOutlinedIcon />,
              //   functionHandler: layoutShift,
              // },
              // {
              //   title: "Power BI",
              //   icon: <ViewCarouselOutlinedIcon />,//ViewCompactOutlinedIcon,ViewCarouselOutlinedIcon
              //   focused: isCSVExport,
              //   focusedIcon: <ViewCompactOutlinedIcon />,
              //   functionHandler: layoutShift,
              // }
            ]
          }
        />
        <Divider style={{ marginTop: "10px" }} />
      </div>
      {loaderSf && (
        <Box className={classes.loaderBox}>
          <CircularProgress />
        </Box>
      )}

      <span
        className="report-inside-title-id edit-report-title"
        style={{
          color: "gray",
          textDecoration: "underline",
          marginBottom: "50px",
        }}
      >
        {param.id && `Report id - ${param.id}`}
      </span>
      <Grid
        item
        md={5}
        xs={12}
        style={{ width: "500px" }}
        className="report-aside-toolbar"
      >
        <Grid item md={6} xs={12} className="h-100 create-report-title">
          <Box
            className={`p-1 ${classes.bgwhiteSolid}`}
            style={{ marginTop: "10px" }}
          >
            <InputBox
              title="Report Title"
              classname="h-inputs p-0 reportInput"
              type="text"
              placeholder="Please Enter"
              styleProp={classes.BoxTitle}
              inputStyle={classes.inputStyle}
              name="name"
              onChange={onChangeInputHandler}
              value={data.name}
            />
            <Typography
              variant="body2"
              className={`${classes.title} ${classes.row} ${classes.justifyBetween} ${classes.alignItemsCenter}`}
            >
              <span>Charts List</span>
              <span>
                <Tooltip title="Add New Chart">
                  <AddOutlinedIcon
                    onClick={() => {
                      setIsOpen(true);
                      setState({});
                    }}
                  />
                </Tooltip>
              </span>
            </Typography>
            <Box>
              {chartsList.map((i: any, index: any) => {
                return (
                  <Box
                    className={`${classes.ChartListItem} edit-report-ChartListItem`}
                  >
                    <Typography variant="body2" className="li-title">
                      {i.title}
                    </Typography>
                    <Box>
                      <EditOutlinedIcon onClick={() => editReport(index)} />
                      <CloseOutlinedIcon onClick={() => deleteReport(index)} />
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box style={{ textAlign: "center" }}>
              <br />
              {/* <button className="button" onClick={exportPDF}>Export to PDF</button> */}
              <PageButtons title="Export Report" onClickHandler={exportPDF} />
              <PageButtons
                title={`${param.id ? "Update" : "Save"}`}
                onClickHandler={saveReport}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container className="pt-1 pb-5 pl-1 report-hide-margin" spacing={1}>
        <Box style={{ width: "100%" }}>
          <Box className={`${classes.row} ${classes.justifyCenter} px-2`}>
            <div className="custom-chart custom-Report-chart">
              <input
                type="file"
                name=""
                id="reportLogo"
                className="d-none"
                onChange={fileChangeHandler}
              />
              <label htmlFor="reportLogo">
                <img
                  src={logoSrc === "" ? logoSrc : logoSrc}
                  alt=""
                  style={{ width: "150px" }}
                />
              </label>
              <p
                style={{
                  fontSize: "25px",
                  textAlign: "left",
                  marginBottom: "25px",
                }}
              >
                {data.name}
              </p>
              {generateTimeRange(FiltersVal)}
              {chartsList?.map((item: any) => {
                return (
                  <div
                    className="hide-pivot-container"
                    style={{ width: "100%" }}
                  >
                    {item?.title && (
                      <Typography
                        variant="body2"
                        className="li-title"
                        style={{
                          color: "black",
                          marginTop: "15px",
                          fontSize: "20px",
                          textDecoration: "underline",
                        }}
                      >
                        {item?.title}
                      </Typography>
                    )}
                    <PivotTableModal
                      data={AllTickets}
                      onClick={handleExportPDF}
                      state={item.value}
                      setState={setState}
                    />
                  </div>
                );
              })}
            </div>
          </Box>
          {/* <Chart chartOptions={lineChartOptions} /> 
                    <Chart chartOptions={lineChartOptions} /> */}
          {/* <Chart chartOptions={columnChartOptions} />
                    <Chart chartOptions={barChartOptions} /> */}
          {/* {chartsList.map((i:any)=>{
                        return(
                            <>
                                <Chart chartOptions={returnOptions(i)} />
                            </>
                        )
                    })} */}
        </Box>
      </Grid>
    </div>
  );
}
export default PivotTableReportView;
