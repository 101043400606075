import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import intl from "react-intl-universal";
import { resolve } from "dns";
import { formCategoryData } from "../../../components/userManagement/Role/formAgentData";
import { Language } from "@mui/icons-material";
import LanguageData from "../../../configs/LanguageData.json"

export const url = `${configs.BASE_URL}/Category`;
const deleteUrl = `${configs.BASE_URL}/Category/permanent/delete/`;
const changeStatus = `${configs.BASE_URL}/Category/status/change?ids=`;
const bulkDelete = `${configs.BASE_URL}/Category/permanent/delete?ids=`;
const unassignedTicketurl = `${configs.BASE_URL}/Category/unassigned/tickettypes`;
const catgoryById =`${configs.BASE_URL}/user/Category`

export const storeCategoryList = (val: any) => ({
  type: "getCategory",
  payload: val,
});
export const storeCategoryTree = (val: any) => ({
  type: "storeCategoryTree",
  payload: val,
});
const addCategory = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: "addCategory",
        payload: response,
      });
      // dispatch(getCategory());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_CATEGORY}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error: any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data,
            severity: "error",
            open: true,
          },
        });
        console.log("error=>", error.response.data);
      }
    }
  };
};

export const storeCategoryObject = (val: any) => ({
  type: "getCategoryObject",
  payload: val,
});

const formCategory = (arr: any) => {

  const obj: any = {};
  const objById: any = {};
  arr.map((i: any) => {
      obj[i?.id] = i?.name;
      objById[i?.id] = {
          ...i,
          "adminChildIdLeavel1": i.id,
          "adminChildIdLeavel2": 0,
          "adminChildIdLeavel3": 0,
          "adminChildIdLeavel4": 0,
        };
      i.subCategoryList.map((j: any) => {
          obj[j?.id] = j?.name;
          objById[j?.id] = {
              ...j,
              "adminChildIdLeavel1": i.id,
              "adminChildIdLeavel2": j.id,
              "adminChildIdLeavel3": 0,
              "adminChildIdLeavel4": 0,
            };
          j.itemList.map((k: any) => {
              obj[k?.id] = k?.name;
              objById[k?.id] = {
                  ...k,
                  "adminChildIdLeavel1": i.id,
                  "adminChildIdLeavel2": j.id,
                  "adminChildIdLeavel3": k.id,
                  "adminChildIdLeavel4": 0,
                };
              k.childrenItemList.map((l: any) => {
                obj[l?.id] = l?.name;
                objById[l?.id] = {
                    ...l,
                    "adminChildIdLeavel1": i.id,
                    "adminChildIdLeavel2": j.id,
                    "adminChildIdLeavel3": k.id,
                    "adminChildIdLeavel4": l.id,
                  };
              });
          });
      });
  });

  return {obj,objById};
};

export const getCategory = (callback?: any, altUrl?: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(
        altUrl ? `${configs.BASE_URL}/${altUrl}` : url
      );
      dispatch(loading(false));
      dispatch(storeCategoryList(response));
      dispatch(storeCategoryObject(formCategory(response.data)));
      const treeFormatted:any = [];
      response.data.map((i:any)=>{
        if(!i?.isActive) return;
        const {id,name,isActive} = i 
        treeFormatted.push({
            id,name,
            isActive,
            ticketTypeId : i?.categoryTicketTypeList?.[0]?.ticketType ? i?.categoryTicketTypeList?.[0]?.ticketType?.id : 0,
            categoryList : formCategoryData(i)
        });
      })
      console.log('====================================');
      console.log("dispatch(storeCategoryTree(treeFormatted));",treeFormatted);
      console.log('====================================');
      dispatch(storeCategoryTree(treeFormatted));
      if (callback) callback("0", response.data);
    } catch (error: any) {
      if (callback) callback("1", error.response);
      return console.log(error);
    }
  };
};

export const unassignedTicket = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(unassignedTicketurl);
      dispatch(loading(false));
      dispatch({
        type: "unassignedTicket",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const singleCategory = (id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      dispatch({
        type: "singleCategory",
        payload: response,
      });
      if (callback) callback("0", response.data);
    } catch (error: any) {
      if (callback) callback("1", error.response);
      return console.log(error);
    }
  };
};
export const sspSingleCategory = (id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${catgoryById}/${id}`);
      if (callback) callback("0", response.data);
    } catch (error: any) {
      if (callback) callback("1", error.response);
      return console.log(error);
    }
  };
};

export const updateCategory = (data: any, id: any) => {
  return function (dispatch: any) {
    axios.put(`${url}/${id}`, data).then((resp) => {
      dispatch({
        type: "updateCategory",
      });
      // dispatch(getCategory());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
    });
  };
};

export const bulkDeleteItem = (ids: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkDelete}${ids}`);
      dispatch({
        type: "bulkDeleteItem",
        payload: response,
      });
      dispatch(getCategory());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Category Deleted Successfully",
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

// export const bulkDeleteItem = (ids: any) => {
//   return function (dispatch: any) {
//     axios.put(`${bulkDelete}${ids}`).then((resp) => {
//       dispatch({
//         type: "bulkDeleteItem",
//       });
//       dispatch(getCategory());
//       dispatch({
//         type: ActionType.SNACKBAR,
//         payload: {
//           message: "all Category deleted Successfully",
//           severity: "success",
//           open: true,
//         },
//       });
//     });
//   };
// };

export const multipalUpdateCategory = (data: any, ids: any, status: any) => {
  const idMsg =
    ids.length === undefined ? `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;
  const statusMsg = status ? `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;


  return function (dispatch: any) {
    axios
      .delete(`${changeStatus}${ids}&status=${status}`, data)
      .then((resp) => {
        dispatch({
          type: "multipalUpdateCategory",
        });
        dispatch(getCategory());
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: ids.length === undefined ? idMsg : statusMsg,
            severity: "success",
            open: true,
          },
        });
      });
  };
};

export const softDeleteCategory = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/${id}`);
      dispatch({
        type: "softDeleteCategory",
        payload: response,
      });
      dispatch(getCategory());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED} ${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const deleteCategory = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}`);
      dispatch({
        type: "deleteCategory",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Category Deleted Successfully",
          severity: "success",
          open: true,
        },
      });
      dispatch(getCategory());
    } catch (error) {
      return console.log(error);
    }
  };
};

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default addCategory;
