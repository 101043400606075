import { connect, useDispatch } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import CommonStyles from "../../../common/CommonStyles";
import { Box,FormControlLabel,Checkbox } from "@mui/material";
import { selectAllPermissions } from "../../../../redux/actions/userManagement/role";
import { useEffect, useState } from "react";
interface IValueType {
    check : any;
    uncheck : any;
    totalPermissionCount : number;
}
const SelectAllCategoriesOption = ({selectedValue=[],categories=[],callback}:any) =>{
    console.log('SelectAllCategoriesOption',selectedValue,categories)
    const classes = CommonStyles();
    const dispatch = useDispatch();
    const [valueObj,setValueObj] = useState<IValueType>({
        check : [],
        uncheck : [],
        totalPermissionCount : 0
    });
    const [checked, setChecked] = useState(false);
    const [intermediate, setIntermediate] = useState(false);
    const toggleCheckAll = (e:any) =>{
        const {checked} = e.target;
        // dispatch(selectAllPermissions(
        //     checked ? valueObj?.check : valueObj?.uncheck
        // ))
        callback(checked ? valueObj?.check : valueObj?.uncheck)
    }
    useEffect(()=>{
        // console.log('SelectAllCategoriesOptio',selectedValue,categoriesList)
        // setValueObj(createDataSet(categoriesList))
        const outputArr:any = [];
        const fetchCategoryIds = (arr = []) => {
            arr.map((i:any) => {
                outputArr.push(i.id)
                if (i?.categoryList?.children) {
                    fetchCategoryIds(i?.categoryList?.children)
                }
                if (i.children) {
                    fetchCategoryIds(i.children)
                }
            })
        }
        fetchCategoryIds(categories)
        console.log('outputArroutputArr',categories,"======",outputArr)
        setValueObj({
            check : outputArr,
            uncheck : [],
            totalPermissionCount : outputArr?.length
        })
    },[selectedValue?.length,categories?.length])
    useEffect(()=>{
        console.log('selectedValueselectedValue',selectedValue)
        const checkedCount =selectedValue?.length;// 0;//getCheckedCount(info,selectedValue);
        setChecked(checkedCount > 0 && (valueObj?.totalPermissionCount <= checkedCount) ? true : false);
        setIntermediate(checkedCount > 0 && valueObj?.totalPermissionCount > checkedCount ? true : false);
    },[valueObj?.totalPermissionCount,selectedValue?.length])
    return(<>
        <Box className={classes.roleAllowAccessBtn}>
            <FormControlLabel
                value=''
                checked={checked}
                control={
                    <Checkbox size="small"
                        indeterminate={intermediate} 
                        checked={checked}
                    />
                }
                onChange={toggleCheckAll}
                label='Allow access'
                labelPlacement="end"
                className="sml-txt"
            />
        </Box>  
    </>)
}
const mapStateToProps = (state:IState) => {
    return ({
        checkedItems : state?.roleReducer?.checkedStatus,
    })
};
export default connect(mapStateToProps)(SelectAllCategoriesOption);