import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { createRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import { getTicketByID, quickUpdateTicket } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { iFormData } from '../../../pages/new-ticket/LatestTicket/Type';
import { returnFormValues } from '../../helpers/helperFunctions';
import AsyncDropDown from '../../../pages/new-ticket/LatestTicket/AsyncDrodown/AsyncDropDown';
import { getContactsForDropdown } from '../../../redux/actions/ContactManagement/Contact';
import { debounce } from 'lodash';
import { LabelFieldBox } from '../../postLogin/MyLicenses';
import BasicButton from '../../common/button/BasicButton';
import moment from 'moment';
import timeSpanToReadbleFormat from '../../../util/timeSpanToReadbleFormat';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import { getOrganisationsForDropdown } from '../../../redux/actions/CMDB/Organisation';



interface ResolvePopupProps {
    ticketIdWithStatusID : any;
    AllTicketsById : any;
    statusObj:any;
}
const ResolvePopup = memo((props:ResolvePopupProps) => {
    const { ticketIdWithStatusID, AllTicketsById, statusObj } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [errorObj,setErrorObj] = useState({});
    useEffect(() => {
        if (ticketIdWithStatusID?.ticketId  && !AllTicketsById?.[ticketIdWithStatusID?.ticketId]) {
            dispatch(getTicketByID(ticketIdWithStatusID.ticketId))
        }
        console.log("ticketIdWithStatusID",ticketIdWithStatusID, "Resolved","ThirdParty")
    }, [ticketIdWithStatusID])
    const onCloseHandler = useCallback(() =>{
        setErrorObj({});
        dispatch({
            type:"RESOLVE_POPUP_INFO",
            payload : {}
        })
    },[])

    
    const quickUpdateTicketCB = (res:any) =>{
        console.log("quickUpdateTicketCB",res)
        onCloseHandler();
    }
    const viewContent = useMemo(()=>{
        const curentTicket = AllTicketsById?.[ticketIdWithStatusID?.ticketId] || {};
        const {contacts,title,description,agent,ticketStatus,group,openDateTime,slaRemaining,dueDateTime,slaRemainingInTicks}:any = curentTicket?.ticketLogs || {};
        const onSubmit = (event: any) => {
            event.preventDefault();
            const data:iFormData = returnFormValues(event.currentTarget)
            const validation = validationHelper(data,statusObj?.[ticketIdWithStatusID?.statusId]?.name)
            if(!validation.isValid){
                setErrorObj(validation.errors);
                return;
            }
            console.log("onSubmitpreventDefault",data);
            // return;
            dispatch(quickUpdateTicket(
                {...curentTicket, ticketLogs:{ 
                    ...curentTicket?.ticketLogs, 
                    note : data?.note || "",
                    statusId : ticketIdWithStatusID?.statusId,
                    ticketStatus : statusObj?.[ticketIdWithStatusID?.statusId],
                    contactOrganisationId : +data?.contactOrganisationId || 0,
                    thirdPartyRefNo : data?.thirdPartyRefNo || ""
                } },
                quickUpdateTicketCB
            ));
        }
        return(
            <>
                {ticketIdWithStatusID?.ticketId > 0  && 
                <Box className={`${classes.popupHolder} ${classes.textWithSpanTitle}`}>
                    <Box className="modal-dialog">
                    <Box className="modal-content">
                        <Box className="modal-header">
                         <Box className="row m-0 rsolveTicketTitle txt-ellipsis align-items-center gap15">
                            <Avatar
                                className="sml-avatar"
                                sx={{ height: 25, width: 25 }}
                                alt={contacts?.knownAs || "PP"}
                                src={`${contacts?.profilePicturePath || ""}`}
                            />
                            <Typography className="txtCapitalize ">
                                <span><span className="txt-primary">#{ticketIdWithStatusID?.ticketId} </span> {title} </span>
                            </Typography>
                        </Box>
                        <Typography className="cursor-pointer" onClick={onCloseHandler}>
                            <CloseIcon className="cursor-pointer" />
                        </Typography>
                        </Box>
                        <Box className="modal-body resolve-popup">
                            <Box className="p-1 pr-0">
                                <Grid container  spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <LabelFieldBox label={"Title"} value={title} />
                                        <LabelFieldBox label={"Description"} value={description} />
                                        <LabelFieldBox label={"Primary Contact"} value={contacts?.knownAs} />
                                        <LabelFieldBox label={"Group"} value={group?.name} />
                                        <LabelFieldBox label={"Agent"} value={`${agent?.firstName || ""} ${agent?.lastName || ""}`} />
                                        <LabelFieldBox label={"Open Date"} value={moment(openDateTime).format("L LT")} />
                                        <LabelFieldBox label={"Due Date"} value={moment(dueDateTime).format("L LT")} />
                                        
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {slaRemaining && <LabelFieldBox className={`sla ${slaRemainingInTicks <= 0 ? "txt-danger" : "txt-success" }`} label={"SLA"} value={ticketStatus?.name === "OnHold" ? "On-Hold" : timeSpanToReadbleFormat(slaRemaining, "ss")} />}
                                        <form onSubmit={onSubmit}>
                                            {curentTicket?.ticketLogs?.id && <>
                                                <ThirdPartyForm curentTicket={curentTicket} statusName={statusObj?.[ticketIdWithStatusID?.statusId]?.name} errorObj={errorObj} unsetError={setErrorObj}/>
                                                <Box className="pt-2 text-center">
                                                    <BasicButton
                                                        type={"button"}
                                                        label={"Close"}
                                                        size="small"
                                                        variant={"outlined"}
                                                        onClick={onCloseHandler}
                                                    /> &nbsp; &nbsp;
                                                    <BasicButton
                                                        type={"submit"}
                                                        label={"Submit"}
                                                        size="small"
                                                        variant={"contained"}
                                                    />
                                                </Box>
                                                </>
                                            }
                                        </form>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    </Box>
                </Box>
                }
            </>);
    },[ticketIdWithStatusID?.ticketId, errorObj, AllTicketsById])
    return (<>
      {viewContent}
    </>)
  })

const ThirdPartyForm = (props:any) =>{
    const {errorObj,unsetError,statusName,curentTicket} = props;
    const noteRef:any = createRef();
    const thirdPartyNumRef:any = createRef();
    const dispatch = useDispatch();
    const promiseOptionsForOrg = debounce((inputValue: any, callback: any) => {
        if (inputValue.length > 2) {
            dispatch(getOrganisationsForDropdown(inputValue,callback,true))
        } else {
          callback([]); // Pass options to the callback
        }
    },300);
    const [contactOrgVal, setContactOrgVal] = useState(null)
    const resetErrors = debounce((key:string)=>{
        unsetError({
            ...errorObj,
            [key] : false
        });
    },300)
    useEffect(()=>{
        console.log("curentTicketpoiuyt",curentTicket)
        if(thirdPartyNumRef?.current){
            thirdPartyNumRef.current.value = curentTicket?.ticketLogs?.thirdPartyRefNo || "";
        }   
        if(curentTicket?.ticketLogs?.contactOrganisation?.name){
            setContactOrgVal({
                ...curentTicket?.ticketLogs?.contactOrganisation,
                label : curentTicket?.ticketLogs?.contactOrganisation?.name || "",
                value : curentTicket?.ticketLogs?.contactOrganisation?.name || "",
            })
        }
    },[curentTicket])
    return (<>
            {statusName === "ThirdParty" &&   
            <>          
                <CustomFormLabel classNames='sml-txt' labelName={"Choose Third Party"} isMandotary={true} />
                <AsyncDropDown
                    promiseOptions={promiseOptionsForOrg}
                    name="contactOrganisationId"
                    placeHolder="Search and select a Third Party"
                    handleChange={(e: any) => {
                        resetErrors("contactOrganisationId")
                        setContactOrgVal(e)
                    }}
                    isError={errorObj?.contactOrganisationId ? true : false}
                    defaultValue={contactOrgVal}
                    customClassNames = "sml-txt-dropdown"
                />
                <p style={{fontSize:"12px",margin:0, color:"#c8c8c8"}}>* You need at least 3 characters to search</p>
                {errorObj?.contactOrganisationId && <CustomFormLabel classNames='sml-txt txt-danger' labelName={errorObj?.contactOrganisationId} isMandotary={false} />}

                <TextBoxLatest
                    placeholder="Third Party Ref Number"
                    ref={thirdPartyNumRef}
                    name='thirdPartyRefNo'
                    parentClassNames=' pt-1'
                    title='Please Enter Third Party Ref Number'
                    isError={errorObj?.thirdPartyRefNo ? true : false}
                    errorMsg={errorObj?.thirdPartyRefNo || "Please Enter"}
                    onchangeHandler={()=>{resetErrors("thirdPartyRefNo")}}
                    isMandotary={true}
                />
            </>
            }


            <TextBoxLatest
                placeholder="Please Enter"
                ref={noteRef}
                name='note'
                title={statusName !== "ThirdParty" ? 'Please Enter Resolve Notes' : 'Notes'}
                multiline={true}
                parentClassNames=' pt-1'
                isError={errorObj?.note ? true : false}
                errorMsg={errorObj?.note || "Please Enter"}
                onchangeHandler={()=>{resetErrors("note")}}
                isMandotary={statusName === "Resolved"}
            />
    </>)
}


const validationHelper = (payload:iFormData,status:string)=>{
    console.log("validationHelper",payload,status)
    let temp:any = {
        isValid : true,
        errors:{}
    }
    if(status !== "Resolved"){
        if(!payload?.thirdPartyRefNo?.trim || payload?.thirdPartyRefNo?.trim() === ""){
            temp.isValid = false;
            temp.errors.thirdPartyRefNo = "Please Enter Third Party Ref Number";
        }
        if(!payload?.contactOrganisationId?.trim || payload?.contactOrganisationId?.trim() === ""){
            temp.isValid = false;
            temp.errors.contactOrganisationId = "Please Choose Third Party Organisation";
        }
    }else{
        if(!payload?.note?.trim || payload?.note?.trim() === ""){
            temp.isValid = false;
            temp.errors.note = "Please Enter Resolve Notes";
        }
    }

    return temp;
}
const useStyles = makeStyles(() =>
  createStyles({
    popupHolder: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "#0005",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: 1399,
    "& .modal-dialog": {
        display: "flex",
        height: '100%',
        justifyContent: "center",
        alignItems: "center",
    },
    "& .modal-header": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        justifyContent: "space-between",
        borderBottom: "1px solid #ddd",
        paddingBottom: "10px"
    },
    "& .modal-body": {
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        padding: "0px"
    },
    "& .modal-body > div": {
        margin: "0 !important",
        maxWidth: "calc(100% - 15px)"
    },
    "& .modal-content": {
        background: "#fff",
        width: "100%",
        maxWidth: "700px",
        borderRadius: "5px",
        //padding: "20px",
        "& img.Contactprofile": {
        width: "calc(100% - 10px)",
        },
        "& img": {
        objectFit: "cover"
        }
    }
    },
    textWithSpanTitle: {
        "& .title-txts":{
            lineHeight: '1.5',
            marginBottom: 10,
            fontSize: '13px',
            "& > span": {
            display: 'block',
            fontSize: '11px',
            marginBottom: '2px',
            lineHeight: '1',
            color: "#5559"//mode.link,
            }
        }
      },
      txtWithLink: {
        fontSize: ".7em",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        "& span": {
          wordBreak: "break-all"
        },
        "& a": {
          fontSize: ".7em",
          textDecoration: "none",
          display: "inline-block",
          lineHeight: 1,
          "& svg": {
            fontSize: "12px"
          }
        }
      }
}))


const mapStateToProps = (state:IState) => {
    return ({
        ticketIdWithStatusID : state?.TicketViewReducer?.resolvePopupId,
        AllTicketsById : state?.TicketViewReducer?.allTicketsById,
        statusObj : state?.TicketsReducer?.alldataStructure?.statusTypesById
    })
  };
  
export default connect(mapStateToProps)(ResolvePopup);