export const contactsModules = ['Contacts','LocationType','Location','Organisation','OrganisationType','Division','Department'];
export const knowledgeBaseModules = ['Article','ArticleType'];
export const SystemAdminModules = ['TicketTypes','Categories','Priorities','WorkingTimePlan','SLA','TicketStatus','EmailTemplates','Workflow'];
export const UserAdminModules = ['Groups','Roles','Agent'];//AgentGroups,License
export const AssetsModules = ['AssetStatus','AssetType','CMDB','AdditionalField'];//,'TicketSource'
export const InterfaceModules = ['OrganisationAzureActiveDirectiory', 'MailService'];
export const ApiModules = ['APISystemManagement', 'APITicketManagement' ,'APIFilesManagement'];
export const TicketManagementModules = ['TicketDashboard' ,'NewTickets'];
export const ReportsManagementModules = ['CustomReports' ,'CsvReports','PowerBiReport'];//,''
export const SurveyModules = ['Survey' ,'SurveyTemplates',"SurveyResults"];
export const AdminAccess = ["Create","Edit","View","DeActivate","ReActivate","Delete"];
export const AllowAccessName = "AllowAccess";

const onlyAllowAccessModules:any = {
    SurveyResults : true,
    PowerBiReport : true
}
const PermissionData:any = {
    "SystemPermissions":{
        title: 'System Management'
    },
    "UserAdminPermissions":{
        title: 'User Management'
    },
    "ReportsPermissions" : {
        title : "Reports Management"
    },
    "SurveyPermissions" : {
        title : "Survey Management"
    },
    "TicketPermissions":{
        title: 'Ticket Management',
        onlyAllowAccess:true
    },
    "ContactsPermissions":{
        title: 'Contact Management'
    },
    "AssetsPermissions":{
        title: 'Asset Management'
    },
    "KnowledgeBase":{
        title: 'Knowledge Base'
    },
    "Interfaces":{
        title: 'Interfaces'
    },
    "APIs":{
        title: 'APIs',
        onlyAllowAccess:true
    },
};

SystemAdminModules.forEach((i:any)=>{
    PermissionData.SystemPermissions[i] = {};
    PermissionData.SystemPermissions[i]['isAccess'] = false;
});

TicketManagementModules.forEach((i:any)=>{
    PermissionData.TicketPermissions[i] = {};
    PermissionData.TicketPermissions[i]['isAccess'] = false;
});

UserAdminModules.forEach((i:any)=>{
    PermissionData.UserAdminPermissions[i] = {};
    PermissionData.UserAdminPermissions[i]['isAccess'] = false;
});

ReportsManagementModules.forEach((i:any)=>{
    PermissionData.ReportsPermissions[i] = {};
    PermissionData.ReportsPermissions[i]['isAccess'] = false;
});

SurveyModules.forEach((i:any)=>{
    PermissionData.SurveyPermissions[i] = {};
    PermissionData.SurveyPermissions[i]['isAccess'] = false;
});

//PermissionData.TicketPermissions.tcktPermissions = {};
AssetsModules.forEach((i:any)=>{
    PermissionData.AssetsPermissions[i] = {};
    PermissionData.AssetsPermissions[i]['isAccess'] = false;
})
contactsModules.forEach((i:any)=>{
    PermissionData.ContactsPermissions[i] = {};
    PermissionData.ContactsPermissions[i]['isAccess'] = false;
})
InterfaceModules.forEach((i:any)=>{
    PermissionData.Interfaces[i] = {};
    PermissionData.Interfaces[i]['isAccess'] = false;
})
knowledgeBaseModules.forEach((i:any)=>{
    PermissionData.KnowledgeBase[i] = {};
    PermissionData.KnowledgeBase[i]['isAccess'] = false;
})
ApiModules.forEach((i:any)=>{
    PermissionData.APIs[i] = {};
    PermissionData.APIs[i]['isAccess'] = false;
})

export const dataStructure = () =>{
    let DataFormat:any = {}
    Object.keys(PermissionData).forEach((i:any)=>{
        let tempKeys = Object.keys(PermissionData[i]).filter((j:any)=>j !== 'title' && j!=="onlyAllowAccess") 
        tempKeys.forEach((k:any)=>{
            DataFormat[k] = i; 
        })
    })
    return DataFormat;
}

export const dataModalStructure = () =>{
    return PermissionData;
}

const sysData = (systemType:any,permission:any) =>{
    // get permissions
    //console.log('systemType====',systemType)
    const AdminPermission:any = [];
    const AllowAccessPermission:any = [];
    const permissionList:any = [];
    // const allowAccess = permission.filter((i:any)=>i.name === 'AllowAccess');
    if(permission && permission.length>0){
        let modifiedData = permission.map((i:any)=>{
            i.checked = false;
            if(AdminAccess.includes(i.name)){
                AdminPermission.push(i)
            }
            if(AllowAccessName === i.name){
                AllowAccessPermission.push(i)
            }
            return i;
        })
        permissionList.push(...modifiedData)
    }

    //adding permissions to all modules
    const permissionKeys = Object.keys(PermissionData);
    permissionKeys.forEach((i:any)=>{
        let tempData = Object.keys(PermissionData[i]);
        tempData.forEach((j:any)=>{
            if(j !== 'title' && j !== "onlyAllowAccess"){
                if(!PermissionData?.[i]?.[j]?.['permissions']){
                    PermissionData[i][j]['permissions'] = []
                }

                if(i.onlyAllowAccess || onlyAllowAccessModules?.[j]){
                    PermissionData[i][j]['permissions'] = AllowAccessPermission;
                    return;
                }
                PermissionData[i][j]['permissions'] =  AdminPermission;
            }
        })
    })
    return PermissionData;
}

export const formEditFlowData = (defaultData:any, roleData:any) =>{//updating system admin values on edit
    let isSysAdmin = false;
    const dataStruct = dataStructure();
    const allData = JSON.parse(JSON.stringify(defaultData));
    roleData.agentPermissionsList.map((i:any)=>{
        const moduleName = i.adminTypes.name;
        const SectionName = dataStruct[moduleName];
        if(allData[SectionName]){
            allData[SectionName][moduleName].permissions.map((k:any,index:number)=>{
                if(k.id === i.permissionId && i.isActive && i.isSystemAdmin){
                    isSysAdmin = true;
                    allData[SectionName][moduleName].permissions[index].checked = true;
                }
            })
        }
    })

    return {isSysAdmin : isSysAdmin, data : allData};
}

export default sysData;
