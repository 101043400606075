import { useEffect, useMemo, useState } from "react";
import CustomDialog from "../../components/common/dialog/CustomDialog";
import BasicButton from "../../components/common/button/BasicButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import CustomInput from "../../components/common/textField/CustomInput";
import CustomSelect from "../../components/common/customSelect/CustomSelect";
import PhoneInput from "react-phone-input-2";
import {
  ValidationResultS,
  iFormData,
  iMemoizedComponentForUserContactFormData,
} from "./LatestTicket/Type";
import { generateErrorList, generateIdFromObject } from "./LatestTicket/Form";
import { isValidate } from "./LatestTicket/Validation";
import addContactAction from "../../redux/actions/ContactManagement/Contact";
import { useDispatch } from "react-redux";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import { schemaForQuickContactCreationFormData } from "./LatestTicket/Schema";
import CustomFormLabel from "../../components/common/form-label/CustomFormLabel ";
import AsyncDropDown from "./LatestTicket/AsyncDrodown/AsyncDropDown";
import axios from "axios";
import configs from "../../configs/config";
import { ActionType } from "../../redux/actions/dashboard";
import { useNavigate } from "react-router-dom";

const MemoizedComponent: React.FC<iMemoizedComponentForUserContactFormData> = ({
  formContactData,
  type,
  children,
  others = null,
}) => {
  return useMemo(() => {
    return children;
  }, [formContactData[type], others]);
};

const AddContactCopy = ({
  isOpenAddContact,
  setIsOpenAddContact,
  onContactSubmit,
  orgList,
  style
}: {
  isOpenAddContact: boolean;
  setIsOpenAddContact: any;
  onContactSubmit?: any;
  orgList: any;
  style: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formContactData, setFormCOntactData] = useState({
    name: "",
    knownAs: "",
    org: null,
    loc: null,
    phoneNo: null,
    email: "",
  });
  const [locList, setLocList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ValidationResultS>({
    isValid: false,
    errors: {},
  });

  const promiseOptions = (inputValue: any, callback: any) => {
    if (inputValue.length > 2) {
      axios
        .get(
          `${configs.BASE_URL}/ContactOrganisation?search=${inputValue}&page=1&count=50&isActive=true`
        )
        .then((response: any) => {
          const data = response.data;
          console.error("options", data);
          const options = data?.map((item: any) => ({
            id: item?.id,
            label: `${item.name},(${item.email})`,
            value: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            divisionList: item.divisionList,
            locationList: item.locationList,
            departmentList: item.departmentList,
          }));
          callback(options); // Pass options to the callback
        })
        .catch((error: any) => {
          console.error("Error fetching data:", error);
          callback([]); // Pass options to the callback
          dispatch({
            type: ActionType.SNACKBAR,
            payload: {
              message: `Server Error! Log out and try again`,
              severity: "error",
              open: true,
            },
          });
        });
    } else {
      callback([]); // Pass options to the callback
    }
  };

  const handleSelectChange = (e: unknown, type: string) => {
    setFormCOntactData((prevUserFormData) => {
      return {
        ...prevUserFormData,
        [type]: e,
      };
    });
  };

  useEffect(() => {
    console.log("orgList", orgList);
  }, [orgList]);

  const resultOnSumbmit = (res: any) => {
    if (res.status == 200 || res.status == 201) {
      let modifiedResponse: any = {
        label: `${res.data.name}, ${res.data.userName}(${res.data.email})`,
        name: res.data.name,
        value: res.data.id,
        id: res.data.id,
        color: "red",
        knownAs: res.data.knownAs,
        userName: res.data.userName,
        jobTitle: res.data.jobTitle,
        icon: <AccountCircleOutlinedIcon sx={{ color: "rgb(10, 147, 252)" }} />,
        iconRight: res.data.isVIP ? (
          <DiamondOutlinedIcon fontSize="small" color="primary" width="15px" />
        ) : (
          false
        ),
        mail: res.data.email,
        whatsAppNumber: res.data.whatsAppNumber,
        company: res.data.companyName,
        place: res.data.officeLocation,
        no: res.data.mobilePhone,
        isVIP: res.data.isVIP,
        alert: res.data.alert,
      };

      onContactSubmit(modifiedResponse);
      setIsOpenAddContact(false);
    } else {
      setLoading(false);
    }
  };

  const onSubmit = (event: any) => {
    event.preventDefault();

    setLoading(true);

    // const formData = new FormData(event.currentTarget);

    // const data: iFormData = {};

    // formData.forEach((value, key) => {
    //   data[key] = value.toString();
    // });

    let validationData = {
      name: formContactData.name,
      knownAs: formContactData.knownAs,
      contactOrganisationId: generateIdFromObject(formContactData.org),
      baseLocationId: generateIdFromObject(formContactData.loc),
      mobilePhone: formContactData.phoneNo,
      email: formContactData.email,
    };

    const validation = isValidate(
      validationData,
      schemaForQuickContactCreationFormData
    ); // Validate the form data

    console.log("validation", validation);

    if (validation.isValid) {
      // Form is valid, proceed with submission
      dispatch(addContactAction(validationData, resultOnSumbmit));

      setError({ isValid: false, errors: {} });
    } else {
      // Form is invalid, update the errors state
      setLoading(false);

      setError(validation);
    }
  };

  const populateDivAndLocAndDept = (item1: any) => {
    let baseLocTemp: any = [];
    item1?.locationList?.filter((item: any) => {
      let x =
        item.location.addressLine1 == null
          ? ""
          : `,${item.location.addressLine1}`;
      let y =
        item.location.addressLine2 == null
          ? ""
          : `,${item.location.addressLine2}`;
      let z =
        item.location.city == null
          ? ""
          : `, ${item.location.city.toUpperCase()}`;
      let tempPostcode =
        item.location.postcode == null ? "" : `,${item.location.postcode}`;
      let tempName =
        item.location.name == null
          ? ""
          : `,${item.location.name.toUpperCase()}`;
      let temp: any = {
        label: `${item.location.name}-${z}${x}${y} - ${tempPostcode} (${tempName})`,
        value: item.locationId,
        id: item.locationId,
        color: "red",
        name: item.location.name,
        addressLine1: item.location.addressLine1,
        addressLine2: item.location.addressLine2,
        postcode: item.location.postcode,
      };
      if (item.location.name !== null) {
        baseLocTemp.push(temp);
      }
    });
    setLocList(baseLocTemp);
  };

  return (
    <CustomDialog
      heading="Add contact"
      body={
        <>
          {" "}
          <form>
            <Grid
              container
              columnSpacing={5}
              rowSpacing={2}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={1} key={5}>
                <MemoizedComponent formContactData={formContactData} type="name">
                  <CustomFormLabel labelName={"Name"} isMandotary={true} />
                  <CustomInput
                    value={formContactData.name}
                    onChange={(e: any) => {
                      handleSelectChange(e.target.value, "name");
                    }}
                    isDisabled={false}
                    readOnly={false}
                    onClick={null}
                    iconPosition={"start"}
                    icon={false}
                    isError={false}
                    label={"Enter the Name"}
                    name="name"
                    helperText={""}
                    placeholder={"Enter the Name"}
                  />{" "}
                </MemoizedComponent>
                {generateErrorList(error.isValid, error.errors, "name")}
              </Grid>

              <Grid item xs={1} sm={1} md={1} key={1}>
                <MemoizedComponent
                  formContactData={formContactData}
                  type="knownAs"
                >
                  <CustomFormLabel
                    labelName={"Known name"}
                    isMandotary={true}
                  />
                  <CustomInput
                    value={formContactData.knownAs}
                    onChange={(e: any) => {
                      handleSelectChange(e.target.value, "knownAs");
                    }}
                    isDisabled={false}
                    readOnly={false}
                    onClick={null}
                    iconPosition={"start"}
                    icon={false}
                    isError={false}
                    label={"Enter the Known name"}
                    name="knownAs"
                    helperText={""}
                    placeholder={"Enter the Known name"}
                  />{" "}
                </MemoizedComponent>
                {generateErrorList(error.isValid, error.errors, "knownAs")}
              </Grid>

              <Grid item xs={1} sm={1} md={1} key={2}>
                <MemoizedComponent formContactData={formContactData} type="org">
                  <CustomFormLabel
                    labelName={"Organisation"}
                    isMandotary={false}
                  />
                  <AsyncDropDown
                  promiseOptions={promiseOptions}
                  name="org"
                  placeHolder="Search and Select an Organisation"
                  handleChange={(e: any) => {
                    console.log("org selected", e);
                      handleSelectChange(e, "org");
                      populateDivAndLocAndDept(e);
                   
                  }}
                  defaultValue={formContactData.org}
                  />
                </MemoizedComponent>
                <div className={style.AddContactLinkBtnContainer}>
                Organisation not found?{" "}
                <span
                className={`${style.AddContactLinkBtn} addcontactLinkbtn`}
                onClick={() => {
                  navigate("/Organization");
                }}
              >
                {" "}Create it here
              </span>
                </div>
              
                {generateErrorList(error.isValid, error.errors, "org")}
              </Grid>

              <Grid item xs={1} sm={1} md={1} key={3}>
                <MemoizedComponent
                  formContactData={formContactData}
                  type="loc"
                  others={locList}
                >
                  <CustomFormLabel labelName={"Location"} isMandotary={false} />
                  <CustomSelect
                    isOpen={false}
                    placeholder="Select a location"
                    name="loc"
                    width={"100%"}
                    isColor={false}
                    isDisabled={false}
                    isError={false}
                    defaultValue={formContactData.loc}
                    options={locList}
                    handleChange={(e: any) => {
                      handleSelectChange(e, "loc");
                    }}
                    isMulti={false}
                  />
                  <span style={{ fontSize: "14px", color: "#a2a2a2" }}>
                    * To select the location, make sure you have selected the
                    organisation first
                  </span>
                </MemoizedComponent>
                {generateErrorList(error.isValid, error.errors, "loc")}
              </Grid>

              <Grid item xs={1} sm={1} md={1} key={5}>
                <MemoizedComponent
                  formContactData={formContactData}
                  type="phoneNo"
                >
                  <CustomFormLabel labelName={"Phone no"} isMandotary={false} />
                  <PhoneInput
                    autocompleteSearch={true}
                    enableSearch={true}
                    country={"gb"}
                    value={formContactData.phoneNo}
                    defaultErrorMessage="It doesn't works, why?"
                    inputProps={{
                      name: "phoneNo",
                      required: false,
                    }}
                    isValid={true}
                    onChange={(e: any) => {
                      handleSelectChange(e, "loc");
                    }}
                    inputStyle={{ height: "52px", width: "100%" }}
                  />
                </MemoizedComponent>
                {generateErrorList(error.isValid, error.errors, "phoneNo")}
              </Grid>

              <Grid item xs={1} sm={1} md={1} key={6}>
                <CustomFormLabel labelName={"Email"} isMandotary={false} />
                <MemoizedComponent
                  formContactData={formContactData}
                  type="email"
                >
                  <CustomInput
                    value={formContactData.email}
                    onChange={(e: any) => {
                      handleSelectChange(e.target.value, "email");
                    }}
                    isDisabled={false}
                    readOnly={false}
                    onClick={null}
                    iconPosition={"start"}
                    icon={false}
                    isError={false}
                    label={"Enter the Email"}
                    name="email"
                    helperText={""}
                    placeholder={"Enter the Email"}
                  />{" "}
                </MemoizedComponent>
                {generateErrorList(error.isValid, error.errors, "email")}
              </Grid>
            </Grid>
          </form>
        </>
      }
      open={isOpenAddContact}
      handleClose={() => {
        setIsOpenAddContact(false);
      }}
      footer={
        <>
          <div style={{ display: "flex", gap: 10 }}>
            <BasicButton
              onClick={() => {
                setIsOpenAddContact(false);
              }}
              type={"cancel"}
              isLoading={false}
              isDisabled={false}
              color="primary"
              size="large"
              label={"Cancel"}
              variant={"outlined"}
              endIcon={<CloseIcon sx={{ width: "20px" }} />}
            />
            <BasicButton
              onClick={onSubmit}
              type="submit"
              label={"Add contact"}
              isLoading={loading}
              isDisabled={false}
              color="primary"
              size="large"
              variant={"contained"}
              endIcon={<AddRoundedIcon sx={{ width: "20px" }} />}
            />
          </div>
        </>
      }
    />
  );
};

export default AddContactCopy;
