import { Button, Grid } from "@mui/material";
import CustomSelect from "../../common/customSelect/CustomSelect";
import { useEffect, useMemo, useRef, useState } from "react";
import { IPieData } from "./InterfaceWallBoard";
import VerticalChart from "../../charts/horizontal-chart/VerticalChart";
import HorizontalChart from "../../charts/horizontal-chart/HorizontalChart";
import LineChart from "../../charts/line/LineChart";
import DoughnutChart from "../../charts/dougnut/DoughnutChart";
import PieChart from "../../charts/pie/PieChart";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import { useDispatch } from "react-redux";
import CustomModalDialog from "../../common/customDialog";
import CommonStyles from "../../common/CommonStyles";
const priorityName: any = {
    P1: 409,
    P2: 88,
    P3: 60,
    P4: 9,
    Low: 23,
    Medium: 16,
    Urgent: 20,
    Default: 2,
    Emergency: 1,
    P: 0,
    highest: 0,
    demo: 0,
    sd: 0,
    "middle range": 0,
    Powerful: 0,
    "Medium range": 0,
    low: 0,
    high: 0,
    p5: 0,
    p2: 0,
    "Urgent Priority": 0,
    w: 0,
    "new ": 0,
    testing: 0,
    first: 0,
    "testing Foreign key": 9,
    toast: 0,
    test: 0,
    rubesh: 0,
};

const ticketStatus: any = {
    "New": 36,
    "New to Me": 159,
    "In Progress": 312,
    "On Hold": 12,
    "Pending": 256,
    "Resolved": 16,
    "Closed": 51,
    "Rejected": 1,
    "Delete": 2,
    "Pending Authorisation": 31,
    "Approved": 0,
    "Not applicable": 0,
    "TT": 0,
    "Open": 1,
    "Pending Close": 0,
    "custom status": 0,
    "waiting": 2,
    "cancel": 0,
    "Third Party dummy": 10,
    "Third Party": 14,
    "pending treat": 1,
    "Merge": 0,
    "ashwini": 0,
    "toast": 0,
    "test toast": 0,
    "kumar": 0,
    "aaaaaaa": 0,
};
const AgentData :any ={
    "Rubesh Kumar": 0,
    "Akshay Kulkarni": 3,
    "Krishna Kulkarni": 0,
    "Shamanth S": 1,
    "Amith b Patil": 351,
    "Girish giri Kulkarni": 14,
    "Augustine  Kudiyirippil": 69,
    "Girish Kulkarnii": 2,
    "Jennifer Aniston": 4,
    "Peter Lench": 64,
    "ramesh Hill": 0,
    "Jon Peterson": 0,
    "Girish Kulkarni": 0,
    "Rahul patil": 2,
    "Harsha Patel": 0,
    "Agent ash": 6,
    "Shamanth JM": 10,
    "Varun Kulkarni": 0,
    "testing test": 1,
    "Structure PvtLtd": 0,
    "Ashwini Ash": 28,
    "Ragul Lingan": 1,
    "Pramod Kulakarni": 0,
    "aaaaaa ": 0,
    "Asss Asss": 0,
    "ygyugyghgh hhh": 0,
    "ascs ": 0,
    "Amresh patil": 0,
    "ashwini ": 0,
    "Gaurav Kumar": 1,
    "Harish Kulkarni": 0,
    "Harshi Patel": 0,
    "new agent asd": 0,
    "tiger ssss": 0,
    "Girish Patel": 0,
    "Pramod Kulkarni": 0,
    "shams ssss": 0,
    "ASWWdd2d4455 aAAA": 0,
    "aSSa A": 0,
    "Shamsudheen Asdf": 0,
    "Ashwini Backend": 0,
    "Shamanth Frontend rest": 0,
    "ASS ASS": 0,
    "Harsha  p": 0,
    "Rishi Patel": 0,
    "Soundarya Patil": 0
}
const groupData: any = {
    "1st Line": 1,
    "2nd Line": 2,
    "3rd Line": 3,
    "Senior Team": 4,
    "Test Line": 5,
    "New_Gr": 6,
    "SAR": 5,
    "New": 4,
    "Data Breeches": 9,
    "Management": 10,
    "Office": 12,
    "tets": 5,
    "aaaaaaaaaa": 7,
    "test": 3,
    "testing ": 1,
    "15 th line": 0,
    "Management > Office": 0,
    "Management - Store": 0,
    "New group - 1st line": 0,
    "test one": 0,
    "Groups Foreign key ": 0,
    "Toast": 0,
    "Seniors Batch": 0,
    "An error occurred whil": 12,
    "An error occurred while ": 0,
    "Proceed for Disabled": 0
}
const ticketTypes: any ={
    "Problem": 36,
    "Event": 81,
    "Project": 65,
    "Case": 80,
    "Incident": 66,
    "Service Request": 199,
    "Changes": 23,
    "Ac milan": 0,
    "New ticket type": 0,
    "new ticket type": 0,
    "Deleting Issue": 0,
    "ticket type updated": 0,
    "Ticket type q": 0,
    "ticket type new": 0,
    "rubesh": 0,
    "name": 0,
    "test type": 0,
    "ticket type": 0,
    "h": 0,
    "testing t type": 0,
    "type of ticket ": 0,
    "Agustine f": 0,
    "testing one": 9,
    "Toast message toast": 0,
    "Toast message": 0,
    "testing three": 0,
    "testing four": 0,
    "testing two": 0,
    "test toast": 0,
    "dd Ticket Type": 0,
    "test toast one": 0,
    "New ContactType": 0,
    "aaaaaaa": 0,
    "qqqqqq": 0,
    "rrrrrrrr": 0,
    "new": 0,
    "old ": 0,
    "kumars": 0,
    "kumar": 0
}
const renderChart = (type: any, data: any, property: string = "") => {
    const labels = Object.keys(data);
    const values = Object.values(data);
    const chartData: IPieData = {
        labels: labels,
        chartType: type,
        datasets: [
            {
                label: property,
                data: values,
                backgroundColor: [
                    "rgba(75,192,192,0.2)",
                    "rgba(153,102,255,0.2)",
                    "rgba(255,159,64,0.2)",
                    "rgba(255,99,132,0.2)",
                    "rgba(54,162,235,0.2)",
                    "rgba(255,206,86,0.2)",
                ],
                borderColor: [
                    "rgba(75,192,192,1)",
                    "rgba(153,102,255,1)",
                    "rgba(255,159,64,1)",
                    "rgba(255,99,132,1)",
                    "rgba(54,162,235,1)",
                    "rgba(255,206,86,1)",
                ],
                borderWidth: 1,
            },
        ],
    };
    return chartData;
}
export const generateChartObj = (chartVal: string, propertyVal: string) => {
    if (chartVal && propertyVal) {
        let newData;
        switch (propertyVal) {
            case "Group":
                newData = groupData;
                break;
            case "Priority":
                newData = priorityName;
                break;
            case "TicketStatus":
                newData = ticketStatus;
                break;
            case "Agent":
                newData = AgentData;
                break;
            case "TicketType":
                newData = ticketTypes;
                break;
            default:
                newData = {};
        }

        const chart: any = renderChart(chartVal, newData, propertyVal);
        return { chartType: chartVal, data: chart, isActive: true }
        //chart;
    }
}
const ChatItem = (props: any) => {
    const { editWallboradList = [] } = props
    const dispatch = useDispatch()
    const classes = CommonStyles()
    const [chartList, setChartList] = useState([
        {
            id: 1,
            name: "DoughnutGraph",
            label: "DoughnutGraph",
            value: "DoughnutGraph",
        },
        {
            id: 2,
            name: "VerticalBarGraph",
            label: "VerticalBarGraph",
            value: "VerticalBarGraph",
        },
        {
            id: 3,
            name: "HorizontalBarGraph",
            label: "HorizontalBarGraph",
            value: "HorizontalBarGraph",
        },
        {
            id: 4,
            name: "PieGraph",
            label: "PieGraph",
            value: "PieGraph",
        },
        {
            id: 5,
            name: "LineGraph",
            label: "LineGraph",
            value: "LineGraph",
        },
    ]);

    const [propertyList, setPropertyList] = useState([
        {
            id: 1,
            name: "Priority",
            label: "Priority",
            value: "Priority",
        },
        {
            id: 2,
            name: "TicketStatus",
            label: "TicketStatus",
            value: "TicketStatus",
        },
        {
            id: 3,
            name: "Group",
            label: "Group",
            value: "Group",
        },
        {
            id: 4,
            name: "TicketType",
            label: "TicketType",
            value: "TicketType",
        },
        {
            id: 5,
            name: "Agent",
            label: "Agent",
            value: "Agent",
        },
    ]);
    const [chartVal, setChartVal] = useState<any>(null);
    const [propertyVal, setPropertyVal] = useState<any>(null)
    const [wallBoardTypeList, setWallBoardTypeList] = useState<any[]>(editWallboradList)
    const [modelOpen, setModelOpen] = useState(false)
    const [editvalueObj, setEditvalueObj] = useState<any>({})
    const [editvalueId, setEditvalueId] = useState<any>({})
    const [chartValEdit, setChartValEdit] = useState<any>({});
    const [propertyValEdit, setPropertyValEdit] = useState<any>({})

    useEffect(() => {
        setChartValEdit(chartList.find((ele: any) => ele.name == editvalueObj?.chartType))
        setPropertyValEdit(propertyList.find((ele: any) => ele.name == editvalueObj?.data?.datasets[0]?.label))
        setEditvalueId(editvalueObj.id)
    }, [editvalueObj])

    const getChartHandler = () => {
        const newVal = generateChartObj(chartVal.name, propertyVal.name)
        setWallBoardTypeList((prev) => [
            ...prev,
            newVal
        ])
    };
    const getChartHandlerForEdit = () => {
        if (chartValEdit.name && propertyValEdit.name) {
            const newVal = generateChartObj(chartValEdit.name, propertyValEdit.name)
            setEditvalueObj({ ...newVal, id: editvalueId });
        }
    };

    const handleRemove = (index: any) => {
        setWallBoardTypeList((prev) => prev.filter((item, i) => i !== index));
    };

    const handleEdit = (index: any, chartComponent: any) => {
        setModelOpen(true)
        setEditvalueObj(chartComponent)
    };

    const handleClickClose = () => {
        setModelOpen(false)
    };
    const pushResponse = (obj: any) => {
        console.log("objobjobjobj", obj, wallBoardTypeList);
    
        setWallBoardTypeList((prev: any[]) => {
            const elementExists = prev.some((ele: any) => ele.id === obj.id)
            if (elementExists) {
                return prev.map((ele: any) => 
                    ele.id === obj.id ? obj : ele
                );
            }
            return [...prev, obj];
        });
        setModelOpen(false)
    };
    

    const ChartItem = useMemo(() => {
        return wallBoardTypeList.map((chartComponent, index) => {
            switch (chartComponent?.chartType) {
                case "VerticalBarGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <VerticalChart label={chartComponent?.data?.labels} dataSets={chartComponent?.data?.datasets || []} />
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                case "HorizontalBarGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <HorizontalChart label={chartComponent?.data?.labels} dataSets={chartComponent?.data?.datasets || []} />
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                case "LineGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <LineChart datasets={chartComponent?.data?.datasets || []} labels={chartComponent?.data?.labels} />
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                case "PieGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <PieChart labels={chartComponent?.data?.labels} datasets={chartComponent?.data?.datasets || []} />
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                case "DoughnutGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <DoughnutChart
                                labels={chartComponent?.data?.labels}
                                datasets={chartComponent?.data?.datasets || []}
                            />
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                default:
                    return (
                        <Grid item md={4} xs={12} key={index}>
                            <>Choose dropdowns to render chart!</>
                        </Grid>
                    );
            }
        });
    }, [wallBoardTypeList]);

    const ChartItemForEdit = useMemo(() => {
        const chartComponent = editvalueObj
        switch (chartComponent?.chartType) {
            case "VerticalBarGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <VerticalChart label={chartComponent?.data?.labels} dataSets={chartComponent?.data?.datasets || []} />
                    </Grid>
                );
            case "HorizontalBarGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <HorizontalChart label={chartComponent?.data?.labels} dataSets={chartComponent?.data?.datasets || []} />
                    </Grid>
                );
            case "LineGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <LineChart datasets={chartComponent?.data?.datasets || []} labels={chartComponent?.data?.labels} />
                    </Grid>
                );
            case "PieGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <PieChart labels={chartComponent?.data?.labels} datasets={chartComponent?.data?.datasets || []} />
                    </Grid>
                );
            case "DoughnutGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <DoughnutChart labels={chartComponent?.data?.labels} datasets={chartComponent?.data?.datasets || []} />
                    </Grid>
                );
            default:
                return (
                    <Grid item md={4} xs={12} >
                        <>Choose dropdowns to render chart!</>
                    </Grid>
                );
        }
    }, [editvalueObj]);

    useEffect(() => {
        dispatch({ type: "WALL_BOARD_TYPE_LIST", payload: wallBoardTypeList })
    }, [wallBoardTypeList])

    return (
        <>
            <Grid container spacing={1} alignItems={"end"}>
                {wallBoardTypeList.length !== 9 &&
                    <>
                        <Grid item md={1.5} xs={6}>
                            <CustomFormLabel labelName={"Chart Type"} isMandotary={true} classNames="sml-txt" />
                            <CustomSelect
                                isOpen={false}
                                placeholder="Select Type"
                                width={"100%"}
                                name="ChartTypeId"
                                isColor={false}
                                isDisabled={false}
                                isError={false}
                                helperText={`Property is required.`}
                                defaultValue={chartVal ? chartVal : null}
                                options={chartList}
                                handleChange={(e: any) => {
                                    setChartVal(e);
                                }}
                                isMulti={false}
                                customClassNames="sml-txt-dropdown new-service"
                            />
                        </Grid>
                        <Grid item md={1.5} xs={6}>
                            <CustomFormLabel labelName={"Property"} isMandotary={true} classNames="sml-txt" />
                            <CustomSelect
                                isOpen={false}
                                placeholder="Select Type"
                                width={"100%"}
                                name="propertyTypeId"
                                isColor={false}
                                isDisabled={false}
                                isError={false}
                                helperText={`Property is required.`}
                                defaultValue={propertyVal ? propertyVal : null}
                                options={propertyList}
                                handleChange={(e: any) => {
                                    setPropertyVal(e);
                                }}
                                isMulti={false}
                                customClassNames="sml-txt-dropdown new-service"
                            />
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Button variant="contained" onClick={getChartHandler} disabled={!chartVal || !propertyVal}>
                                {"Get Chart"}
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid container spacing={1} alignItems={"end"} style={{ marginTop: "25px" }}>
                {ChartItem}
            </Grid>
            {modelOpen &&
                <CustomModalDialog
                    title=""
                    type=""
                    id="upgradeModal"
                    isOpen={modelOpen}
                    onClose={handleClickClose}
                    headerContent={
                        <>
                            Edit Chart
                        </>
                    }
                    bodyContent={
                        <>
                            <Grid container spacing={1} alignItems={"end"}>
                                <Grid item md={3} xs={6}>
                                    <CustomFormLabel labelName={"Chart Type"} isMandotary={true} classNames="sml-txt" />
                                    <CustomSelect
                                        isOpen={false}
                                        placeholder="Select Type"
                                        width={"100%"}
                                        name="ChartTypeId"
                                        isColor={false}
                                        isDisabled={false}
                                        isError={false}
                                        helperText={`Property is required`}
                                        defaultValue={chartValEdit ? chartValEdit : null}
                                        options={chartList}
                                        handleChange={(e: any) => {
                                            setChartValEdit(e);
                                        }}
                                        isMulti={false}
                                        customClassNames="sml-txt-dropdown new-service"
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <CustomFormLabel labelName={"Property"} isMandotary={true} classNames="sml-txt" />
                                    <CustomSelect
                                        isOpen={false}
                                        placeholder="Select Type"
                                        width={"100%"}
                                        name="propertyTypeId"
                                        isColor={false}
                                        isDisabled={false}
                                        isError={false}
                                        helperText={`Property is required.`}
                                        defaultValue={propertyValEdit ? propertyValEdit : null}
                                        options={propertyList}
                                        handleChange={(e: any) => {
                                            setPropertyValEdit(e);
                                        }}
                                        isMulti={false}
                                        customClassNames="sml-txt-dropdown new-service"
                                    />
                                </Grid>
                                <Grid item md={2} xs={6}>
                                    <Button variant="contained" onClick={getChartHandlerForEdit} >
                                        {"Get Chart"}
                                    </Button>
                                </Grid>
                                <Grid container spacing={1} alignItems={"end"} style={{ marginTop: "25px" }}>
                                    {ChartItemForEdit}
                                </Grid>
                            </Grid>
                        </>
                    }
                    footerContent={
                        <>
                            <Button onClick={handleClickClose}>Close</Button>
                            <Button
                                onClick={()=>{pushResponse(editvalueObj)}}
                                className={`${classes.btn} ${classes.btnSubmit}`}
                                variant="contained"
                            >
                                Update
                            </Button>
                        </>
                    }
                />
            }
        </>
    );
};
export default ChatItem;
