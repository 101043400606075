import { makeStyles } from "@mui/styles";
import CommonStyles from "../CommonStyles";


const useStyles = makeStyles(() => ({
  wrapper:{
    fontWeight:400,
    fontFamily: "'DM Sans', sans-serif !important"
  }
  }))



const CustomFormLabel = ({labelName,isMandotary, classNames}:{labelName:any, isMandotary:boolean, classNames?:string})=>{
  const classes = useStyles();
  const classesCommon = CommonStyles();

return<>
<span style={{ fontSize: "14px" }} className={`${classNames} theme-form-label ${classesCommon.themeColorTxt} ${classes.wrapper}`}>
                      {labelName} {isMandotary && <span style={{ color: "red" }}>*</span>}
                    </span></>
}
export default CustomFormLabel ;