import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import BasicButton from "../button/BasicButton";
import SendIcon from "@mui/icons-material/Send";
import CustomInput from "../textField/CustomInput";
import { Grid } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({


  "& .MuiPaper-root":{
    maxWidth: "1200px",
    minWidth:"300px"

  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    "& *": {
      fontFamily: "'DM Sans', sans-serif !important"
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: 2,  color: "#273A6B", }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomDialog({ heading, body, footer, open, handleClose }: { heading: any, body: any, footer?: any, open: boolean, handleClose: any }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <BootstrapDialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {heading}
        </BootstrapDialogTitle>
        <DialogContent
        className="Dialogcontent-dividers"
          dividers
        >
         
            {body}
        </DialogContent>

        {footer && <DialogActions>{footer }</DialogActions>}
      </BootstrapDialog>
    </div>
  );
}
