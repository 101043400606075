import axios from "axios"; 
import configs from "../../../configs/config";
const getTicketStatusUrl = `${configs.BASE_URL}/Dashboard`;
const getTicketTrendsUrl= `${configs.BASE_URL}/Dashboard/tickettrends`;
const getResponseOrResolutionBySlaUrl= `${configs.BASE_URL}/Dashboard/ticket/response-resolution`;
export const getDashboardDataUrl = `${getTicketStatusUrl}?ticketDashboardFilter=0`

  export const getTicketStatus = async (ticketDashboardFilter: number) => {
    try {
        const response = await axios.get(`${getTicketStatusUrl}?ticketDashboardFilter=${ticketDashboardFilter}`);
        return response
      } catch (error) {
        return false
      }
  };

  export const getTicketTrends = async () => {
    try {
        const response = await axios.get(`${getTicketTrendsUrl}`);
        return response
      } catch (error) {
        return false
      }
  };
  export const getResponseOrResolutionBySla = async () => {
    try {
        const response = await axios.get(getResponseOrResolutionBySlaUrl);
        return response
      } catch (error) {
        return false
      }
  };

  export default getTicketStatus