import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Home from "./../../../../assest/icons/menu/home.svg";
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon } from "@mui/material";
import logoImg from "./../../../../assest/icons/logo.png";
import ConfirmationNumberOutlined from '@mui/icons-material/ConfirmationNumberOutlined';
import { DoLogOutAction } from "../../../../redux/actions/Login/Login";
import { storeAllResponses } from "../../../../redux/actions/TicketsManagement/TicketsManagement";
import { theme } from "../../../../redux/actions/Theme/Theme";
import CommonStyles from "../../../common/CommonStyles";
import { IState } from "../../../../redux/reducers/rootReducers";
import HomeIcon from '@mui/icons-material/Home';
import darkmodeImg from "./../../../../assest/icons/menu/darkmode.svg";
import SellIcon from '@mui/icons-material/Sell';
import MailLockOutlinedIcon from '@mui/icons-material/MailLockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useMsal } from "@azure/msal-react";
const drawerWidth = 200;

const SideNavbarForAdmin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = CommonStyles();
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const toggleDarkTheme = () => {
    dispatch(theme(!toggleTheme));
  };
  const { instance } = useMsal();
  function LogOut() {
    if(instance.logout) instance.logout();
    dispatch(theme(false));
    localStorage.clear()
    dispatch(DoLogOutAction());
    dispatch(storeAllResponses({}));
    navigate("/");
  }
  const closeMenu = () => {
    const bodyTag = document.getElementsByTagName("body");
    bodyTag[0].classList.remove("menuOpened");
  };
  return (
    <Drawer
      variant="permanent"
      className={`${classes.mainSideHolder} sidebarHolder`}
    >
      <div className={classes.mainSideBar}>
        <div >
          <Link to="/" className="sidebarLogo">
            <img src={logoImg} alt="" />
            <span></span>
          </Link>
        </div>
        <Divider />
     

        <Link to="/admin-portal" >
            <ListItem style={{marginTop:"20px"}} >
              <ListItemIcon id="homeIcon">
              <HomeIcon />
                <span >Home</span>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to="/edit-pricing" >
            <ListItem>
              <ListItemIcon id="homeIcon">
              <SellIcon />
                <span >Pricing</span>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to="/EmailTempleteSsp" >
            <ListItem>
              <ListItemIcon id="homeIcon">
              <EmailOutlinedIcon />
                <span >Email <br/> Template</span>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to="/EmailSettingsSsp" >
            <ListItem>
              <ListItemIcon id="homeIcon">
              <MailLockOutlinedIcon />
                <span >Email <br/> Settings</span>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to="/admin-portal/ErrorLog" >
            <ListItem>
              <ListItemIcon id="homeIcon">
              <PriorityHighIcon />
                <span >Error <br/> Log</span>
              </ListItemIcon>
            </ListItem>
          </Link>

       
        {/* <Box className={`${classes.sideLogoutBtn}`}>
        <HomeIcon/>
          <Link to="/admin-portal" className={[isActive("/") ? "active" : ""].join(" ")}>
                <span>Home</span>
          </Link>
        </Box> */}
        <Divider />
      
        <Box className={`md-none ${classes.sideLogoutBtn}`} onClick={toggleDarkTheme}>
        <img src={darkmodeImg} alt="" />
          <span style={{color: "b"}}>Dark mode</span>
        </Box>
            <Divider />
        <Box className={`md-none ${classes.sideLogoutBtn}`} onClick={LogOut}>
          <LogoutOutlinedIcon />
          <span>logout</span>
        </Box>
        <Divider />

      </div>
      <span onClick={closeMenu} className={classes.menuClose}></span>
    </Drawer>
  );
};

export default SideNavbarForAdmin;