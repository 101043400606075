import { Box } from "@mui/material"
import { memo } from "react"
import { useDispatch } from "react-redux";
import { bulkFileUploadService } from "../../services/fileUpload/FileUpload";
export interface IDashboardSecondaryNavbar {
    callback?: (e: any[]) => void;
}
export const CommonAddAttachment = memo((props:IDashboardSecondaryNavbar) => {
    const {callback} = props;
    const dispatch = useDispatch()
    const imgCallBack = async (file: any) => {
        const filesList:any = [];
        for(const item in file){
          if(item !== "length" && item !== "item" ){
            let fileObject = {
              description: file[`${item}`].name,
              filetypeid: 1,
              formFile: file[`${item}`],
            };
            filesList.push(fileObject)
          }
        }
        dispatch(bulkFileUploadService(
          filesList,
          fileUploadCallback
        ));
      };
      const fileUploadCallback = (resStatus:string,res:any) => {
        console.log('bulkUploadCB',resStatus,res)
        if(resStatus === "0"){
          if(callback) callback(res.map((i:any)=>{return i.data}));               
        }
      };
    return (
        <>
            <Box>
                <input
                    id="AssetFileUpload"
                    accept=".jpeg, .img, .png, .pdf"
                    type="file"
                    multiple={true}
                    onChange={(e: any) => {
                        imgCallBack(e.target.files)
                    }}
                    //value={FileUploadVal}
                    className="d-none"
                />
                <label
                className="Add-Attachment"
                    htmlFor="AssetFileUpload"
                    style={{
                        fontSize: "16px",
                        cursor: "pointer",
                        color: "#0A93FC",
                        display:"inline-block",
                        padding: "10px 0",
                    }}
                >
                    + Add attachment
                </label>
            </Box>
        </>
    )
})
export const CommonAddAttachmentForBlob = memo((props:IDashboardSecondaryNavbar) => {
  const {callback} = props;
  const dispatch = useDispatch()
  const imgCallBack = async (file: any) => {
      const filesList:any = [];
      for(const item in file){
        if(item !== "length" && item !== "item" ){
          let fileObject = {
            id: item,
            name: file[`${item}`].name,
            formFile: file[`${item}`],
          };
          filesList.push(fileObject)
        }
      }
      if(callback) {
        callback(filesList)
      }
    };
   
  return (
      <>
          <Box>
              <input
                  id="AssetFileUpload"
                  accept=".jpeg, .img, .png, .pdf"
                  type="file"
                  multiple={true}
                  onChange={(e: any) => {
                      imgCallBack(e.target.files)
                  }}
                  //value={FileUploadVal}
                  className="d-none"
              />
              <label
              className="Add-Attachment"
                  htmlFor="AssetFileUpload"
                  style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      color: "#0A93FC",
                      display:"inline-block",
                      padding: "10px 0",
                  }}
              >
                  + Add attachment
              </label>
          </Box>
      </>
  )
})
export default CommonAddAttachment;