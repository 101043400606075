import Joi from "joi";

// VALIDATION FOR TICKET CREATION FORM DATA

const primaryContactSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Primary contact is required.",
    "any.required": "Primary contact is required.",
    "number.notZero": "Primary contact is required.",
  });

const ticketTypeSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Ticket type is required.",
    "any.required": "Ticket type is required.",
    "number.notZero": "Ticket type is required.",
  });

const prioritySchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Priority contact is required.",
    "any.required": "Priority contact is required.",
    "number.notZero": "Priority is required.",
  });

const secondaryContactSchema = Joi.any().messages({
  "number.base": "Secondary contact is required.",
  "any.required": "Secondary contact is required.",
});

const subjectSchema = Joi.string()
  .trim()
  .max(250)
  // .custom((value, helpers) => {
  //   // custom validation logic for the 'subject' field here
  //   // You can access the field value as 'value' and use 'helpers' for custom validation
  //   const nextActionDesc = helpers.state.ancestors[0].nextActionDesc;

  //   console.log("helpers subjectSchema", nextActionDesc, value);
  //   return value;
  // })
  .messages({
    "string.empty": "Subject is required.",
    "any.required": "Subject is required.",
    "string.max":
      "Subject length must be less than or equal to 250 characters.",
  });

const sourceSchema = Joi.string()
  .required()
  .messages({
    "string.base": "Source is required.",
    "any.required": "Source is required.",
    "number.notZero": "Source is required.",
  });

const descSchema = Joi.string().trim().required().messages({
  "string.empty": "Description is required.",
  "any.required": "Description is required.",
  // "string.max":
  //   "Description length must be less than or equal to 100 characters.",
});

const dueDateSchema = Joi.string().required().messages({
  "string.empty": "Due date is required.",
  "any.required": "Due date is required.",
  "string.base": "Due date is required.",
});
const dueDateSchemaForOtherSchema = Joi.any().allow(null).messages({
  "string.empty": "Due date is required.",
  "any.required": "Due date is required.",
  "string.base": "Due date is required.",
});

const openDateSchema = Joi.string().required().messages({
  "string.empty": "Open date is required.",
  "any.required": "Open date is required.",
});

const nextActionDateSchema = Joi.string().allow(null).messages({
  "string.empty": "First action date must be a valid date.",
});

const assetSchema = Joi.any().allow(null).messages({
  "string.empty": "First action date must be a valid date.",
});

const catSchema = Joi.number().required().messages({
  "number.empty": "Category is required.",
  "number.base": "Category is required.",
  "any.required": "Category is required.",
});

const tagSchema = Joi.any().messages({
  "number.empty": "Category is required.",
});

const nextActionDescSchema = Joi.string()
  .min(0)
  .custom((value, helpers) => {
    // custom validation logic for the 'subject' field here
    // You can access the field value as 'value' and use 'helpers' for custom validation
    // const nextActionDate = helpers.state.ancestors[0].subject;
    // if (nextActionDate) {
    //   if (!value) {
    //     return helpers.error("nextActionDescIsRequired");
    //   }
    // }
    // console.log("helpers nextActionDescSchema", nextActionDate, typeof value);

    return value;
  })
  .messages({
    nextActionDescIsRequired:
      "You have entered a 'subject', so the 'First Action Description' is required",
    // "string.max": "Input length should not exceed 250 characters.",
  });

export const schemaForTicketCreationFormData = Joi.object({
  contactId: primaryContactSchema,
  ticketTypeId: ticketTypeSchema,
  secondaryContactId: secondaryContactSchema,
  priorityId: prioritySchema,
  title: subjectSchema,
  sourceCode: sourceSchema,
  description: descSchema,
  DueDateTime: dueDateSchema,
  nextActionDescription: nextActionDescSchema,
  firstResponseDueDateTime: nextActionDateSchema,
  openDate: openDateSchema,
  // ticketLogAssetList: assetSchema,
  categoryId: catSchema,
});
export const schemaForTicketCreationFormDataWithoutDueDate = Joi.object({
  contactId: primaryContactSchema,
  ticketTypeId: ticketTypeSchema,
  secondaryContactId: secondaryContactSchema,
  priorityId: prioritySchema,
  title: subjectSchema,
  sourceCode: sourceSchema,
  description: descSchema,
  DueDateTime: dueDateSchemaForOtherSchema,
  nextActionDescription: nextActionDescSchema,
  firstResponseDueDateTime: nextActionDateSchema,
  openDate: openDateSchema,
  // ticketLogAssetList: assetSchema,
  categoryId: catSchema,
});

// VALIDATION FOR QUICK CONTACT CREATION FORM DATA

const nameSchema = Joi.string().trim().min(3).max(50).required().messages({
  "string.empty": "Name is required.",
  "any.required": "Name is required.",
  "string.max": "Name length must be less than or equal to 50 characters.",
  "string.min": "Min 3 character required.",
});
const knownAsSchema = Joi.string().trim().min(2).max(50).required().messages({
  "string.empty": "knownAs is required.",
  "any.required": "knownAs is required.",
  "string.max": "knownAs length must be less than or equal to 50 characters.",
  "string.min": "Min 2 character required.",
});
const orgSchema = Joi.number().allow(null).messages({
  "number.base": "Org is required.",
  "any.required": "Org is required.",
});
const locSchema = Joi.number().allow(null).messages({
  "number.base": "Location is required.",
  "any.required": "Location is required.",
});
const phoneNoSchema = Joi.number().allow(null).messages({
  "number.base": "Phone number is required.",
});
const emailSchema = Joi.string()
  .allow("")
  .email({ tlds: { allow: false } }) //  It also allows only the "com" and "net" top-level domains (TLDs) for the email address
  .required()
  .messages({
    "string.email": "Invalid email format.",
    "any.required": "Email is required.",
  });

export const schemaForQuickContactCreationFormData = Joi.object({
  name: nameSchema,
  knownAs: knownAsSchema,
  contactOrganisationId: orgSchema,
  baseLocationId: locSchema,
  mobilePhone: phoneNoSchema,
  email: emailSchema,
});

const groupSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Group is required.",
    "any.required": "Group contact is required.",
    "number.notZero": "Group is required.",
  });

const agentSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Agent is required.",
    "any.required": "Agent contact is required.",
    "number.notZero": "Agent is required.",
  });

const statusSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Status is required.",
    "any.required": "Status contact is required.",
    "number.notZero": "Status is required.",
  });
  const templateSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Template is required.",
    "any.required": "Template contact is required.",
    "number.notZero": "Template is required.",
  });
  

const noteSchema = Joi.string().allow('').messages({
  // "string.max": "Note length should not exceed 5000 characters.",
});

export const schemaForUpdateFormData = Joi.object({
  groupId: groupSchema,
  agentId: agentSchema,
  statusId: statusSchema,
  note: noteSchema,
});
export const schemaForUpdateFormDataEmail = Joi.object({
  groupId: groupSchema,
  agentId: agentSchema,
  statusId: statusSchema,
  note: noteSchema,
  template: templateSchema
});
