import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../common2/Breadcrumbs"
import { Box, Button, Grid, Typography } from "@mui/material"
import CommonStyles from "../../common/CommonStyles"
import CustomFormLabel from "../../common/form-label/CustomFormLabel "
import CustomInput from "../../common/textField/CustomInput"
import { useEffect, useState } from "react"
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux"
import { addSSOConfig, editSSOConfig, getSSOConfigByID } from "../../../redux/actions/adminInterfaces/SSOConfig"

export default function AddSSOConfig() {

    const param = useParams()
    const navigate = useNavigate()
    const classes = CommonStyles()
    const dispatch = useDispatch()
    const [data, setData] = useState({
        name: "",
        clientId: "",
        tenantId: "",
        clientSecret:""
    })
    const onchangeHandler = (e: any) => {
        const val = e.target.value;
        const name = e.target.name;
        //;
        setData({
            ...data,
            [name]: val,
        });
    };
    console.log(data);
    const submitForm = () => {
        if (param.id) {
            console.log("its Update", data);
            dispatch(editSSOConfig(param.id, data, backHandler))
        } else {
            dispatch(addSSOConfig(data, backHandler))
        }
    }
    const getSSOConfigByIDCB = (val: any) => {
        setData({ ...val })
    }
    useEffect(() => {
        if (param?.id)
            dispatch(getSSOConfigByID(param.id, getSSOConfigByIDCB));
    }, [])
    const backHandler = () => {
        navigate('/SSOConfig')
    }

    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title: 'Interfaces',
                        path: '/adminPanel'
                    },
                    {
                        title: 'SSO Config',
                        path: '/SSOConfig'
                    },
                    {
                        title: `${param.id ? "Edit" : "Add"}-SSO Config`,
                        path: `${param.id ? `/AddSSOConfig/${param.id}` : '/AddSSOConfig'}`,
                    }
                ]
                }
            />
            <br />
            <Box className={`pageHolder ${classes.px1}`}>
                <Box>
                    <Box className="mb2 pb2">
                        <Typography variant="h6" className={`${classes.themeColorTxt}`}>
                            {param.id ? 'Edit SSO Config' : "Add SSO Config"}
                        </Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item md={4} xs={12} className="Add-SSO-Config">
                            <CustomFormLabel
                                classNames={`${classes.themeColorTxt}`}
                                labelName={"Name"}
                                isMandotary={true}
                            />
                            <CustomInput
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={false}
                                name="name"
                                value={data.name}
                                label={"Enter the name"}
                                onChange={onchangeHandler}
                                helperText={false}
                                placeholder={"Please Enter"}
                            />{" "}

                            <CustomFormLabel
                                classNames={`${classes.themeColorTxt}`}
                                labelName={"Client Id"}
                                isMandotary={true}
                            />
                            <CustomInput
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={false}
                                name="clientId"
                                value={data.clientId}
                                label={"Enter the clientId"}
                                onChange={onchangeHandler}
                                helperText={false}
                                placeholder={"Please Enter"}
                            />{" "}
                            <CustomFormLabel
                                classNames={`${classes.themeColorTxt}`}
                                labelName={"Tenent Id"}
                                isMandotary={true}
                            />
                            <CustomInput
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={false}
                                name="tenantId"
                                value={data.tenantId}
                                label={"Enter the tenantId"}
                                onChange={onchangeHandler}
                                helperText={false}
                                placeholder={"Please Enter"}
                            />{" "}
                            <CustomFormLabel
                                classNames={`${classes.themeColorTxt}`}
                                labelName={"Client Secret"}
                                isMandotary={true}
                            />
                            <CustomInput
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={false}
                                name="clientSecret"
                                value={data.clientSecret}
                                label={"Enter the Client Secret"}
                                onChange={onchangeHandler}
                                helperText={false}
                                placeholder={"Please Enter"}
                            />{" "}
                            <Box className="text-center">
                                <Button
                                    className={`${classes.btn} ${classes.btnCancel}`}
                                    variant="outlined"
                                    onClick={backHandler}
                                >
                                    <CancelIcon style={{ marginRight: "5px" }} />
                                    Cancel

                                </Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button
                                    className={`${classes.btn} ${classes.btnSubmit}`}
                                    variant="contained"
                                    onClick={submitForm}
                                >
                                    {!param?.id ? "Save" : "Update"}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}