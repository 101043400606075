import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getEmailSettings,
  addEmailSettingsAction,
  editEmailSettings,
} from "../../../redux/actions/adminInterfaces/EmailSettings";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import CommonStyles from "../../../components/common/CommonStyles";
import AppLoader from "../../../components/common/AppLoader";
import CustomInput from "../../../components/common/textField/CustomInput";
import CustomFormLabel from "../../../components/common/form-label/CustomFormLabel ";
import { addEmailSettingsSspAction, editEmailSettingsSsp, getEmailSettingsSsp } from "../../../redux/actions/SelfServicePortal/ContactAid/EmailSettingsSsp/EmailSettingsSsp";

const getValidationSchema = () => {
  const schemaObject: any = {};
  schemaObject["name"] = Yup.string().test("name", function (value = "") {
    if (value.trim() === "") {
      return this.createError({
        path: this.path,
        message: "Please Enter Title",
      });
    } else if (!/^[A-Za-z\s]+$/.test(value)) {
      return this.createError({
        path: this.path,
        message: "Numbers & Special characters are not allowed.",
      });
    } else {
      return true;
    }
  });
  schemaObject['mailId'] = Yup.string().email().test('email',
    function (value = "") {
      if (value.trim() === '') {
        return this.createError({ path: this.path, message: "Please Enter Email" });
      } else { return true; }
    }
  );
  schemaObject["clientSecret"] = Yup.string().trim().required("Please Enter a password");
  return Yup.object().shape(schemaObject);
};

function GmailConfigSsp() {
  const param: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = CommonStyles();
  const [isUpdate, setisUpdate] = useState(false);
  const [loader, setloader] = useState(false);

  const isSettingsAdded = useSelector(
    (state: IState) => state?.EmailTemplatesReducer?.isTemplateAdded
  );
  const emailSettingsList = useSelector(
    (state: IState) => state?.EmailSettingsSspReducer?.AllEmailSettingsSsp
  );

  const [Data, setData] = useState({
    name: "",
    mailId: "",
    clientSecret: "",
    isOutgoingMail: false,
    isIncomingMail: false,
    outgoingServerName: "",
    outgoingPort: 0,
    type: "Gmail",
    clientId: "",
    tenantId: "",
    incomingServerName: "",
    incomingPort: 0
  });

  const backHandler = () => {
    navigate("/EmailSettingsSsp");
  };
  const onchangeHandler = (e: any) => {
    let checkBoxes = ["isIncomingMail", "isOutgoingMail"];
    let val = e.target.value;
    const name = e.target.name;
    val = checkBoxes.includes(name) ? e.target.checked : val;
    setData({
      ...Data,
      [name]: val,
    });
  };
  useEffect(() => {
    dispatch(getEmailSettingsSsp());
  }, []);



  useEffect(() => {
    console.log(isSettingsAdded);
    if (isSettingsAdded === "0") {
      backHandler();
    }
  }, [isSettingsAdded]);
  useEffect(() => {
    console.log("emailSettingsList", emailSettingsList);
    if (param?.id && emailSettingsList && emailSettingsList.length > 0) {
      let editItem = emailSettingsList.filter((x: any) => x.id === +param.id);
      if (editItem.length == 0) return;
      editItem = editItem[0];
      editItem.groupId = editItem?.groupId;
      setisUpdate(true);
      console.log("edit data group", editItem);
      setData(editItem);
    }
  }, [param, emailSettingsList]);
  return (
    <>
      <AppLoader enabled={loader} />
      {(!param.id || Data?.name !== "") && (
        <Formik
          initialValues={{ ...Data }}
          validationSchema={getValidationSchema()}
          onSubmit={(values, { resetForm }) => {
            console.log("Success! Data submitted.", values);
            const request: any = { ...values };
            debugger
            if (param.id) {
              dispatch(editEmailSettingsSsp(param.id, request, backHandler));
            } else {
              dispatch(addEmailSettingsSspAction(request, backHandler));
            }
            //resetForm();
          }}
        >
          {(props) => (
            <AddForm
              backHandler={backHandler}
              onchangeHandler={onchangeHandler}
              {...props}
            />
          )}
        </Formik>
      )}
    </>
  );
}


export const AddForm = (props: any) => {
  const {
    errors,
    touched,
    backHandler,
    submitForm,
    values,
    setFieldValue,
  } = props;
  const param: any = useParams();
  const classes = CommonStyles();

  useEffect(() => {
    console.log("props123", props);
  }, [props]);
  const handleChange = (content: any) => {
    setFieldValue("body", content);
  };

  return (
    <>
      <Box className={`pageHolder ${classes.px1}`}>
        <Box>
          <Grid container columnSpacing={5}
            rowSpacing={2}
            columns={{ xs: 1, sm: 1, md: 3 }}>
            <Grid item xs={1} sm={1} md={1} key={1}>
              <CustomFormLabel labelName={"Name"} isMandotary={true} />
              <CustomInput
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={touched.name && Boolean(errors.name)}
                label={"Enter the Name"}
                name="name"
                value={values.name}
                onChange={(e: any) => {
                  setFieldValue("name", e.target.value);
                }}
                helperText={touched.name && errors.name}
                placeholder={"Please Enter"}
              />{" "}
            </Grid>
            <Grid item xs={1} sm={1} md={1} key={2}>
              <CustomFormLabel labelName={"Mail Id"} isMandotary={true} />
              <CustomInput
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={touched.mailId && Boolean(errors.mailId)}
                label={"Please Enter"}
                name="mailId"
                value={values.mailId}
                onChange={(e: any) => {
                  setFieldValue("mailId", e.target.value);
                }}
                helperText={touched.mailId && errors.mailId}
                placeholder={"Please Enter"}
              />{" "}
            </Grid>
            <Grid item xs={1} sm={1} md={1} key={2}>
              <CustomFormLabel labelName={"Password"} isMandotary={true} />
              <CustomInput
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={touched.clientSecret && Boolean(errors.clientSecret)}
                label={"Please Enter"}
                name="clientSecret"
                value={values.clientSecret}
                onChange={(e: any) => {
                  setFieldValue("clientSecret", e.target.value);
                }}
                helperText={touched.clientSecret && errors.clientSecret}
                placeholder={"Please Enter"}
              />{" "}
            </Grid>
          </Grid>
          <hr />
          <Box className={`p-1 ${classes.bgwhiteSolid}`}>
            <Grid container spacing={1}>
              <Grid item md={1} >

              </Grid>
              <Grid item md={4} xs={12}>
                <Grid>
                  <CustomFormLabel labelName={"Outgoing Server Name"} isMandotary={false} />
                  <CustomInput
                    isDisabled={false}
                    readOnly={false}
                    onClick={null}
                    iconPosition={"start"}
                    icon={false}
                    isError={touched.outgoingServerName && Boolean(errors.outgoingServerName)}
                    label={"Please Enter"}
                    name="outgoingServerName"
                    value={values.outgoingServerName}
                    onChange={(e: any) => {
                      setFieldValue("outgoingServerName", e.target.value);
                    }}
                    helperText={touched.outgoingServerName && errors.outgoingServerName}
                    placeholder={"Please Enter"}
                  />{" "}
                </Grid>
              </Grid>
              <Grid item md={2} >

              </Grid>
              <Grid item md={4} xs={12}>
                <Grid>
                  <CustomFormLabel labelName={"Outgoing Port Number"} isMandotary={false} />
                  <CustomInput
                    isDisabled={false}
                    readOnly={false}
                    onClick={null}
                    iconPosition={"start"}
                    icon={false}
                    isError={touched.outgoingPort && Boolean(errors.outgoingPort)}
                    label={"Please Enter"}
                    name="outgoingPort"
                    value={values.outgoingPort}
                    onChange={(e: any) => {
                      setFieldValue("outgoingPort", e.target.value);
                    }}
                    helperText={touched.outgoingPort && errors.outgoingPort}
                    placeholder={"Please Enter"}
                  />{" "}
                </Grid>
                {/*  <FormControlLabel
                  onChange={(e: any) => {
                    setFieldValue("isIncomingMail", e.target.checked);
                  }}
                  name="isIncomingMail"
                  checked={values.isIncomingMail}
                  control={<Checkbox />}
                  label="Incoming Mail"
                />
                <Grid>
                  <CustomFormLabel labelName={"Incoming Server Name"} isMandotary={false} />
                  <CustomInput
                    isDisabled={false}
                    readOnly={false}
                    onClick={null}
                    iconPosition={"start"}
                    icon={false}
                    isError={touched.incomingServerName && Boolean(errors.incomingServerName)}
                    label={"Please Enter"}
                    name="incomingServerName"
                    value={values.incomingServerName}
                    onChange={(e: any) => {
                      setFieldValue("incomingServerName", e.target.value);
                    }}
                    helperText={touched.incomingServerName && errors.incomingServerName}
                    placeholder={"Please Enter"}
                  />{" "}
                </Grid>
                <Grid>
                  <CustomFormLabel labelName={"Incoming Port Number"} isMandotary={false} />
                  <CustomInput
                    isDisabled={false}
                    readOnly={false}
                    onClick={null}
                    iconPosition={"start"}
                    icon={false}
                    isError={touched.incomingPort && Boolean(errors.incomingPort)}
                    label={"Please Enter"}
                    name="incomingPort"
                    value={values.incomingPort}
                    onChange={(e: any) => {
                      setFieldValue("incomingPort", e.target.value);
                    }}
                    helperText={touched.incomingPort && errors.incomingPort}
                    placeholder={"Please Enter"}
                  />{" "}
                </Grid>*/}
              </Grid>
            </Grid>
            <Box className="text-center">
              <Button
                className={`${classes.btn} ${classes.btnCancel}`}
                variant="outlined"
                onClick={backHandler}
              >
                <CancelIcon style={{ marginRight: "5px" }} />
                Cancel
              </Button>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Button
                className={`${classes.btn} ${classes.btnSubmit}`}
                variant="contained"
                onClick={submitForm}
              >
                {!param?.id ? "Save" : "Update"}
              </Button>
            </Box>

          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GmailConfigSsp;
