import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import { useNavigate } from "react-router-dom";
import { getProfileImage, saveProfileImg } from "../userManagement/agents";
import { showErrMsg } from "../TicketsManagement/TicketsManagement";
const url = `${configs.BASE_URL}/organisation`;
const coreProduct = `${configs.BASE_URL}/master/CoreProduct`;
const locationType = `${configs.BASE_URL}/LocationType`;
const getOrganisationTokenUrlSSP = `${configs.BASE_URL}/user/contact/organisation/by/token`;
const getOrganisationTokenUrl = `${configs.BASE_URL}/organisation/by/token`;
const MFAEnableURL = `${configs.BASE_URL}/Agent/twofactor/setup`;
const MFADisableURL = `${configs.BASE_URL}/Agent/twofactor/disable`;
const checkUserName = `${configs.BASE_URL}/organisation/check/userName?userName=`;
const getOrganisation = `${configs.BASE_URL}/organisation/`;
const buyMoreLicense = `${url}/buy/license?totalNumberOfExtraSeats=`;
const mailURL = `${getOrganisation}send/mail/by/mailtemplates?templatename=1&OrganisationId=`; //templatename=1&OrganisationId=1
export const GET_COREPRODUCTS = "GET_COREPRODUCTS";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const GET_LOCATIONTYPES = "GET_LOCATIONTYPES";
export const GET_SUBMITTED = "GET_SUBMITTED";
export const GET_ORGANISATION = "GET_ORGANISATION";
export const GET_WITH_TOKEN = "GET_WITH_TOKEN";
const something_went_wrong = 'Something went wrong.!';

export const getCoreProds = (Id: any) => ({
  type: GET_COREPRODUCTS,
  payload: Id,
});
export const getStoreOrganisation = (Id: any) => ({
  type: GET_ORGANISATION,
  payload: Id,
});
export const getlocationType = (Id: any) => ({
  type: GET_LOCATIONTYPES,
  payload: Id,
});
export const AddOrganization = (Id: any) => ({
  type: ADD_ORGANIZATION,
  payload: Id,
});
export const isSubmitted = (Id: any) => ({
  type: GET_SUBMITTED,
  payload: Id,
});
export const GetOrganisationToken = (Id: any) => ({
  type: GET_WITH_TOKEN,
  payload: Id,
});
export const unsetisSubmittedAction = () => {
  return async (dispatch: any) => {
    dispatch(isSubmitted(""));
  };
};

export const checkSubdomainAvailability = async (subDomain:string) => {
  try {
      let result = await axios.get(`${configs.BASE_URL_Ticket}/master/Organisation/domain/check?organisationDomain=${subDomain}`);
      return result;
  } catch (e) {
      return e
  }
}

export const FreeTrialAction = (data: any, callBack: any) => {
  return async (dispatch: any) => {
    try {
      // setTimeout(() => {
      //   dispatch(isSubmitted("1"));
      // }, 2000);
      const userName = await axios.post(
        `${configs.BASE_URL}/master/Organisation`,
        data
      );
      console.log("signup data", userName);

      callBack(userName)
    } catch (e: any) {
      console.log("signup data catch", e.response);
      callBack(e?.response)
      if (e.response.status == 499) {
        dispatch(isSubmitted("1"));
       
      }
      else{
        dispatch(isSubmitted(`Something happened! Please check!`));
      }
    }
  };
};
export const buyMoreLicenseAction = (val: number) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${buyMoreLicense}${val}`);
      console.log(response);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "License updated successfully",
          severity: "success",
          open: true,
        },
      });
      dispatch(getOrganisationWithToken());
    } catch (e: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:
            e?.response?.data && typeof e.response.data === "string"
              ? e.response.data
              : "Something went wrong! Please Try again",
          severity: "error",
          open: true,
        },
      });
      console.log(e.response);
    }
  };
};
export const GetOrganisationAction = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${getOrganisation}${id}`);
      dispatch(getStoreOrganisation(response.data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const EditOrganisation: any = async (
  id: number,
  data: any,
  callback: any
) => {
  try {
      const response = await axios.put(`${getOrganisation}${id}`, data);
      callback("0", response.data);
  } catch (e) {
    callback("1");
    console.log(e);
  }
};
export const EditOrganisationAction = (
  id: number,
  data: any,
  callback: any
) => {
  return async () => {
    try {
      const response = await axios.put(`${getOrganisation}${id}`, data);
      callback("0", response.data);
    } catch (e) {
      callback("1");
      console.log(e);
    }
  };
};

export const getOrg = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(url);
      dispatch(getStoreOrganisation(response.data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const SendMail = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${mailURL}${id}`);
      console.log(response);
      //dispatch(getStoreOrganisation(response.data));
    } catch (e: any) {
      console.log(e.response);
    }
  };
};

export const getEmailPreview = (id: any) => {
  return axios
    .get(
      `${getOrganisation}send/mail/by/mailtemplates?templatename=2&OrganisationId=${id}`
    )
    .then((result) => {
      console.log("result  :>> ", result);
      return result;
    })
    .catch((err) => {
      console.log("error occured during getting order :>> ", err);
      return { success: false, msg: err };
    });
};

const objToReplace:any = { // its a quickfix - can be removed when display name is added to core products
  "ITSM" : "ITSM",
  "FacilityManagement" : "Facility Management",
  "HrCaseManagement" : "HR Case Management",
  "CustomerSupport" : "Customer Support",
  "QualityControl" : "Quality Control",
  "ComplaintManagement" : "Complaint Management",
  "EmptyDatabase" : "Empty Database",
}
export const GetCoreProductsAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(coreProduct);
      const modifiedArr = response.data.map((i:any)=>{
        i.displayName = objToReplace?.[i?.name] || i.name;
        return i;
      })
      dispatch(getCoreProds(modifiedArr));
    } catch (e) {
      console.log(e);
    }
  };
};
export const getOrganisationWithToken:any = (isSSP?:boolean) => {
  return async (dispatch: any) => {
    try {
      let userType = localStorage.getItem("userType");
      const response = await axios(userType === "Contact" ? getOrganisationTokenUrlSSP : getOrganisationTokenUrl);
     console.log("org details inside api", response.data)
     dispatch(saveProfileImg("orgProfile", response.data.organisationAvatarPath));

      dispatch(GetOrganisationToken(response.data));
      // if (
      //   response?.data?.organisationAvatarId &&
      //   response.data.organisationAvatarId !== 1
      // ) {
      //   dispatch(
      //     getProfileImage("orgProfile", response.data.organisationAvatarId)
      //   );
      // }
    } catch (e) {
      console.log(e);
    }
  };
};

export const postOrgSettings = async (id: any, payload: any) => {
  try {
    let res = await axios.put(
      `${configs.BASE_URL}/OrganisationSettings/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getOrgSeetings = async (id: any) => {
  try {
    let res = await axios.get(
      `${configs.BASE_URL}/OrganisationSettings/${id}`
    );
    return res;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getMFAEnable = (callback?: any, token?: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(MFAEnableURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (callback) callback("0", response.data);
    } catch (e) {
      console.log(e);
    }
  };
};
export const getMFADisable = (otp:any,callback?: any, token?: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${MFADisableURL}/${otp}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (callback) callback("0", response.data);
    } catch (e) {
      console.log(e);
    }
  };
};
export const GetLocationTypeAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(locationType);
      //  ;
      dispatch(getlocationType(response.data));
    } catch (e) {
      console.log(e);
    }
  };
};
export const CallRequestAdd = (data: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(`${configs.BASE_URL}/master/newAccountRequest`, data);
     dispatch(callback('0', response.data))
    } catch (e: any) {
      dispatch(callback('1', e.response));
      console.log(e.response)
      let errMsg = e?.response?.data && typeof e.response.data === 'string' ? e.response.data : something_went_wrong;
      dispatch(showErrMsg(errMsg, 'error'))
    }
  };
};
export default FreeTrialAction;
