import { BorderBottom } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
//import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import CustomTheme from "./CustomTheme";
const data = CustomTheme();
// const mode = data.theme;

function CommonStyles() {
  const [mode, setMode] = useState({} as any);
  const [modeType, setmodeType] = useState("");
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  useEffect(() => {
    // console.log('>>>>>>',toggleTheme,">>>>",data);
    //  console.log(currOrganisation);
    if (toggleTheme) {
      setMode(data.all.dark);
      setmodeType("dark");
    } else {
      setMode(data.all.light);
      setmodeType("light");
    }
  }, [toggleTheme]);
  const useStyles = makeStyles({
    '@keyframes bellSwing': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '25%': {
      transform: 'rotate(10deg)', // Pronounced tilt angle
    },
    '50%': {
      transform: 'rotate(0deg)',
    },
    '75%': {
      transform: 'rotate(-10deg)', // Pronounced tilt angle
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
    bgHeader: {
      backgroundColor: mode.cardHeader,
    },
    notifIcon: {
      animation: '$bellSwing 1s infinite', // Speed of the swing (1 second)
    },
    viewAllLink: {
      float: "right",
      padding: "10px",
      marginTop: "5px",
      textDecoration: "none",
      color: "black",
      fontSize: "16px",
      "&:hover": {
        color: "blue",
        textDecoration: "underline",
      },
    },
    bgSolids: {
      backgroundColor: mode.cardHeader,
    },
    bgwhiteSolid: {
      backgroundColor: mode.whiteSolid,
    },
    sideLogoutBtn: {
      padding: 15,
      display: "flex",
      alignItems: "center",
      gap: 15,
    },
    cursor: {
      cursor: "pointer",
    },
    row: {
      display: "flex",
    },
    rowWrap: {
      flexWrap: "wrap",
    },
    flxDirRow: {
      flexDirection: "row",
    },
    px3: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    px1: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    px2: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    flxWrap: {
      flexWrap: "wrap",
    },
    twoCols: {
      "& > *": {
        maxWidth: "50% !important",
        flex: "0 0 50%",
        padding: "2px 5px",
      },
    },
    w100: {
      width: "100% !important",
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    justifyEnd: {
      justifyContent: "flex-end !important",
    },
    justifyBetween: {
      justifyContent: "space-between !important",
    },
    justifyCenter: {
      justifyContent: "center !important",
    },
    themeColorTxt: {
      color: mode.text,
    },
    loaderBox: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "10px",
      padding: "15px",
      "& span": {
        width: "20px !important",
        height: "20px  !important",
      },
    },
    themeBgColor: {
      background: mode.solids,
    },
    solidWhiteToDark: {
      color: mode.solidToDark,
    },
    solidWhiteToDarkBG: {
      background: mode.solidToDark,
    },
    btn: {
      margin: "10px",
      lineHeight: "24px",
      fontSize: "13px",
      fontWeight: 400,
      color: "#ffffff",
      padding: "5px",
      width: "100px",
      "@media screen and (max-width:980px)": {
        padding: "5px 9px",
        fontSize: "9px",
        width: "auto",
        lineHeight: "18px",
        minWidth: "auto",
        "& svg": {
          height: ".6em",
        },
      },
    },
    btnSubmit: {
      color: "#ffffff !important",
      minWidth: "110px !important",
      width: "auto",
      whiteSpace: "nowrap",
      // backgroundColor: "#1976d2 !important",
    },
    btnGroup: {
      marginTop: "1rem",
      marginBottom: "1rem",
      textAlign: "center",
    },
    btnWrapperModal: {
      width: "100%",
      "& .MuiButtonBase-root": {
        width: "100% !important",
      },
    },
    btnCancel: {
      color: "#6989fa",
      width: "110px",
      backgroundColor: "white",
      border: "2px solid #6989fa",
      marginRight: "10px",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    descriptionTitle: {
      fontSize: "10px",
    },
    xsmlBtn: {
      padding: "6px 7px",
      width: "auto",
      minWidth: "auto !important",
      fontSize: "9px !important",
      textTransform: "capitalize",
      margin: "0 0 0 4px !important",
      lineHeight: "9px",
    },
    smlBtn: {
      padding: "5px 10px",
      width: "auto",
      minWidth: "auto !important",
      fontSize: "12px !important",
      textTransform: "capitalize",
      margin: "0 0 0 4px !important",
    },
    txtSuccess: {
      color: "#28a745 !important",
    },
    upgradePopup: {
      padding: "10px 50px",
    },
    upgradePopupHolder: {
      ".btn-prev": {
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
      },
      ".btn-nxt": {
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
      },
      "& .view-labels": {
        fontSize: 9,
        color: "#000",
        "& .link-txt": {
          color: mode.link,
        },
      },
      "& .view-values": {
        fontSize: 12,
        textAlign: "justify",
      },
    },
    customModalDialog: {
      "& > div > div": {
        minWidth: "1000px",
        "@media screen and (max-width:980px)": {
          minWidth: "98% !important",
        },
      },
      "& .closeIcon": {
        float: "right",
        padding: 0,
      },
      "&.transperantModal": {
        zIndex: "99999 !important",
        "& .closeIcon": {
          color: "#fff !important",
        },
        "& > div > div": {
          background: "#0000 !important",
          boxShadow: "none",
          color: "#fff",
          overflow: "unset !important",
          "& > div": {
            border: "none !important",
            overflow: "unset !important",
          },
        },
        "& .upgradeModal .btn": {
          color: "#fff !important",
          transform: "translateX(-200%)",
          "&.btn-nxt": {
            transform: "translateX(200%)",
          },
          "&.Mui-disabled": {
            opacity: "0.5",
            display: "none",
          },
        },
      },
      "&.sml-popup > div > div": {
        minWidth: "700px !important",
        "@media screen and (max-width:980px)": {
          minWidth: "98% !important",
        },
      },
    },
    upgradeModalTitle: {
      "& .txtRight": {
        textAlign: "right",
        "& button": {
          fontSize: "12px",
        },
      },
    },
    brdrLeft: {
      borderLeft: "1px solid #ddd",
    },
    smTxtCenter: {
      "@media screen and (max-width:980px)": {
        textAlign: "center !important",
      },
    },
    ticketGrupBox: {
      background: "#d4dcfe",
      flex: "0 0 150px",
      width: "150px",
      borderRadius: "5px",
      overflow: "scroll",
      scrollbarWidth: "none",
      scrollbarHeight: "none",
      border: "1px solid #5555",
      "&::-webkit-scrollbar": {
        width: "0px",
        height: "0px",
      },
      "& > div > div": {
        padding: "4px 5px",
        borderBottom: "1px solid #5552",
      },
      "& .agentsName": {
        background: "#ebeffc !important",
        padding: "4px 5px",
        borderBottom: "1px solid #5555",
      },
      "& *": {
        fontSize: "10px",
        lineHeight: 1,
      },
    },
    whiteBox: {
      padding: "20px",
      background: mode.whiteSolid,
      border: "1px solid #ddd",
      "&.emailTemplateMailBox": {
        "& .cmn-inputBox": {
          margin: 0,
          "& .sm-pd": {
            padding: 0,
            "& > div": {
              background: "none",
              "& fieldset": {
                border: "none",
                borderBottom: "1px solid #f1f1f1",
              },
            },
          },
        },
      },
    },
    lightBorder: {
      borderRadius: "5px",
    },
    txtRight: {
      textAlign: "right",
    },
    mobileMenu: {
      justifyContent: "space-between",
      padding: "10px",
      display: "none",
      background: "#c5d0f7",
      marginLeft: "-10px",
      marginRight: "-10px",
      position: "sticky",
      top: "0",
      zIndex: "12",
      "& button": {
        padding: "0px",
        color: "#ffffff",
      },
      "@media screen and (max-width:980px)": {
        // marginRight: "-15px",
        display: "flex",
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
    Breadcrumbs: {
      "& ol": {
        display: "inline-flex",
        padding: "10px",
        background: "#ddd5",
        borderRadius: "4px",
        boxShadow: "1px 1px 4px #ddd",
      },
      "& li": {
        fontSize: "12px",
      },
      "& li a": {
        textDecoration: "none",
        color: mode.link,
      },
      "& li svg": {
        height: "15px",
        width: "auto",
      },
      "& li p": {
        fontSize: "12px",
      },
      "@media screen and (max-width:980px)": {
        "& li p, & li": {
          fontSize: "9px",
        },
      },
    },
    gutter: {
      padding: "7px",
      "@media screen and (max-width:980px)": {
        padding: "0px",
      },
    },
    ptsm2: {
      paddingTop: "20px",
    },
    mx0: {
      marginLeft: "0 !important",
      marginRight: "0 !important",
    },
    cardTitle: {
      fontSize: "14px !important",
      fontWeight: "600 !important",
    },
    cardDisplay: {
      fontSize: "12px !important",
      fontWeight: "600 !important",
    },
    disabledtitle: {
      fontSize: "14px !important",
      fontWeight: "600 !important",
      textDecoration: "line-through",
    },
    SearchBox: {
      width: "100% !important",
      maxWidth: "170px",
      display: "flex",
      alignItems: "center",
      background: "#00000000 !important",
      border: "1px solid #ddd",
      boxShadow: "none !important",
      padding: "2px 5px",
      borderRadius: "0 !important",
      "& >div": {
        width: "100%",
      },
      "& input": {
        padding: "0",
        height: "23px",
        fontSize: "11px",
      },
      "& button": {
        padding: "0",
        "& svg": {
          height: "0.7em",
          width: "0.7em",
        },
      },
    },
    breakRow: {
      margin: "0 !important",
      width: "100% !important",
    },
    bgSuccess: {
      background: "#19B861 !important",
    },
    bgWarning: {
      background: "#F39732 !important",
    },
    bgDanger: {
      background: "#FF0000 !important",
    },
    linkTitle: {
      color: "#2196f3 !important",
      wordBreak: "break-word",
    },
    roleAllowAccessBtn: {
      background: " #ddd5 !important",
      padding: "0px 0px 0px 10px",
      borderRadius: "7px",
    },
    statusPendingBtn: {
      color: " black !important",
      background: "#f1f1f190 !important",
      padding: "0px 10px 0px 10px",
      borderRadius: "7px",
    },
    statusInProgressBtn: {
      color: " black !important",
      background: "#f1f1f190 !important",
      padding: "0px 10px 0px 10px",
      borderRadius: "7px",
    },
    statusDoneBtn: {
      color: " black !important",
      background: "#f1f1f190 !important",
      padding: "0px 10px 0px 10px",
      borderRadius: "7px",
    },
    p0: {
      padding: "0 !important",
      minHeight: "auto !important",
      "& p": {
        fontSize: "1.7em",
        fontWeight: "700",
        color: "#2a2765",
      },
    },
    errInlineMsg: {
      color: "red",
    },
    BodyRoot: {
      // paddingLeft: "53px ",
      //paddingRight:,
      background: mode.body,
      display: "block !important",
      minHeight: "100vh",
      "& .el-inpt-disabled": {
        pointerEvents: "none",
        "& input": {
          background: "#ddd",
        },
      },
      "& hr": {
        border: "none",
        borderTop: `1px solid ${mode.text}10`,
      },
      "& .inlineIcon": {
        display: "inline-flex",
        alignItems: "center",
        "& >svg": {
          marginRight: "5px",
        },
      },
      "@media screen and (max-width:980px)": {
        paddingLeft: "0px !important",
      },
      "& .view-popup-input": {
        margin: 0,
        "& > div": {
          background: "none",
          "& fieldset": {
            border: "none",
            borderBottom: "1px solid #fff0",
          },
        },
      },
      "& .view-popup-textarea": {
        width: "100%",
        overflow: "hidden",
        resize: "none",
        padding: "10px",
        border: "1px solid #ddd",
        minHeight: "50px",
        height: "70px !important",
        fontSize: "11px",
        outline: "none !important",
      },
      "& .AgentUsersCard": {
        padding: "0",
        background: `${mode.cardBody} !important`,
        boxShadow: "1px 2px 2px rgb(4 24 87 / 22%) !important",
        borderRadius: "4px !important",
        overflow: "hidden",
        marginBottom: "14px",
        "& p": {
          fontSize: "14px !important",
        },
        "& .statusBox": {
          height: "25px",
          width: "40px",
        },
        "& .cardHeader": {
          background: mode.cardHeader,
          padding: "7px 13px",
          color: mode.text,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& p": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
          "& .agentGrupCount": {
            "& span": {
              background: "#ffc107",
              display: "block",
              fontSize: "10px",
              lineHeight: "20px",
              width: "20px",
              textAlign: "center",
              borderRadius: "50%",
            },
          },
          "& .agentGrupLabel": {
            "& span": {
              background: "#fff",
              lineHeight: 1,
              fontSize: "10px",
              display: "block",
              padding: "2px 5px",
              borderRadius: "4px",
              color: "#2196f3",
              fontWeight: 600,
              letterSpacing: ".5px",
            },
          },
        },
        "& .userIcon": {
          padding: "0 0",
        },
        "& .cardContent": {
          padding: "14px 14px",
          paddingBottom: "4px",
          // minHeight:"50px",
          // borderBottom: '1px solid #ddd',
          "& td,th": {
            fontSize: "12px",
            padding: "4px",
            wordBreak: "break-all",
          },
          "& p": {
            fontSize: "10px !important",
          },
          "& .cardSubTitle": {
            fontSize: 13,
            margin: "0",
            minHeight: "40px",
          },
        },
      },
    },
    mainTableHolder: {
      width: "80%",
      "& .paper": {
        background: "#00000000",
        boxShadow: "none",
      },
      "& th,td": {
        padding: "10px",
        lineHeight: "1",
      },
    },
    contentHolder: {
      padding: "30px 20px",
      background: mode.body,
      boxShadow: "0 0 4px 0px #ddd5",
      borderRadius: "4px",
      "& .tableholder": {
        borderRadius: "4px",
      },
    },
    title: {
      flexGrow: 1,
    },
    topBar: {
      display: "block",
      borderBottom: "1px solid #0000001a",
      position: "sticky",
      paddingLeft: "15px",
      top: 0,
      zIndex: 999,
      background: mode.topBar,
      "&.noBG": {
        background: "#0000 !important",
        border: "none",
        position: "static !important",
      },
      "& .logoutBtn": {
        color: mode.text,
      },
      "& .d-flx": {
        justifyContent: "space-between",
        padding: 0,
        "&  img": {
          filter:
            modeType === "light" ? `brightness(1)` : "brightness(0) invert(1)",
        },
        "&  img.proImg , img.flagImg": {
          filter: "unset !important",
        },
      },
      "& .pageTitle": {
        display: "flex",
        alignItems: "center",
        color: mode.text,
      },
      "& p": {
        color: mode.text,
      },
      "& .notificationIcon > span.count": {
        position: "absolute",
        top: "1px",
        right: "4px",
        fontSize: "9px",
        background: "red",
        minWidth: "19px",
        lineHeight: "19px",
        borderRadius: "50%",
        color: "#fff",
      },
      "@media screen and (max-width : 980px)": {
        top: "0px",
        marginLeft: "-10px",
        marginRight: "-10px",
        padding: "0 10px",
        "& p": {
          fontSize: "13px",
        },
        "& .pageTitle": {
          fontSize: "13px",
          justifyContent: "space-between",
        },
        "& .sm-hide": {
          display: "none",
        },
        "& .btnGrp": {
          display: "flex",
        },
        "& .d-flx > div": {
          width: "100%",
        },
        "& .d-flx button": {
          padding: "0px",
          marginLeft: "10px !important",
          "& svg": {
            height: "18px",
            width: "18px",
          },
        },
      },
    },
    titleSupport: {
      flexGrow: 1,
      display: "inline-block",
    },
    header: {
      minHeight: 0,
      marginLeft: 50,
    },
    titleMain: {
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
      color: mode.text,
    },
    iconButton: {
      padding: 6,
      "& svg": {
        height: "0.8em",
        width: "0.8em",
      },
    },
    titleMainList: {
      marginLeft: 10,
    },
    appBar: {
      backgroundColor: mode.topBar,
      // background: theme.palette.background.default,
    },
    menus: {
      // color: 'black'
      color: "#000",
    },
    Box: {
      marginTop: "20px",
    },
    primaryHeader: {
      backgroundColor: "#006dcc",
      padding: "30px",
      textAlign: "center",
      color: "#fff",
      "& h4": {
        margin: "0 !important",
      },
    },
    dataBox: {
      backgroundColor: mode.body,
      padding: "15px",
      borderRadius: "8px",
      paddingLeft: "40px",
    },
    responseBoxData: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.5rem",
    },
    timeBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    timeBoxData: {
      paddingTop: "2rem",
    },
    styplePropTitle: {
      width: "115px",
    },
    stypleProp: {
      "& .MuiSelect-root": {
        height: "10px",
        width: "700px",
        "& option": {
          padding: "10px 4px",
          "&::before": {
            width: "1.4em",
            textAlign: "center",
            display: "inline-block",
            fontSize: 24,
          },
        },
      },
    },
    paper: {
      width: "735px",
      height: "auto",
    },
    rightBox: {
      display: "flex",
      marginTop: "1rem",
    },
    timePlanBox: {
      padding: "15px",
      display: "flex",
    },
    timedatabox: {
      border: "1px solid #cfd4f2",
      borderRadius: "5px",
      width: "250px",
      display: "flex",
      justifyContent: "space-around",
      padding: "10px",
    },
    dataTitle: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: mode.text,
    },
    inputStyle: {
      // width: "150px",
    },
    BoxTitle: {
      width: "115px",
    },
    ModelBox: {
      display: "flex",
    },
    addbtn: {
      cursor: "pointer",
    },
    mainSideHolder: {
      "&>div": {
        cursor: "pointer",
        background: mode.sidebar,
        scrollbarWidth: "none",
        "@media screen and (max-width : 980px)": {
          background: "#00000010",
          width: "100%",
          zIndex: "99999999999",
          transform: "translateX(-100%)",
        },
        "&::-webkit-scrollbar": {
          width: "0px",
        },
      },
    },
    secondaryNavbarPosition: {
      position: "sticky",
      top: "71px",
      zIndex: 99,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    arrowBtns: {
      "& svg": {
        cursor: "pointer",
        "&.disabled": {
          opacity: 0.2,
        },
      },
    },
    mainSideBar: {
      background: mode.sidebar,
      height: "100%",
      padding: "15px 7px",
      "@media screen and (max-width : 980px)": {
        background: "#ffffff",
        width: "80%",
        zIndex: 9,
      },
      "& a": {
        textDecoration: "none",
        "&.disabled": {
          //pointerEvents: 'none',
          cursor: "default",
          opacity: 0.2,
        },
        "& li": {
          justifyContent: "center",
          padding: "0 0",
          "@media screen and (max-width:980px)": {
            justifyContent: "flex-start",
            paddingLeft: "10px",
            alignItems: "center",
          },
        },
      },
      "& .MuiListItemIcon-root": {
        minWidth: "auto",
        display: "block",
        textAlign: "center",
        color: `${mode.text} !important`,
        "& *": {
          transition: ".5s ease-in-out",
        },
        "& i": {
          display: " block",
          width: " 24px",
          lineHeight: " 24px",
          borderRadius: " 4px",
          boxShadow: "1px 1px 2px 0px #00000000",
          "@media screen and (max-width:980px)": {
            width: "35px",
            lineHeight: "35px",
          },
        },
        "& img": {
          height: "27px",
          padding: "5px",
          width: "auto",
          borderRadius: "4px",
          boxShadow: "1px 1px 2px 0px #00000000",
          filter: mode.brightnessInvert,
        },
        "& span": {
          display: "block",
          fontSize: "7px",
          fontWeight: "700",
          opacity: "0",
          textAlign: "center",
          minHeight: "19px",
          marginTop: "2px",
        },
        "&:hover": {
          "& i": {
            boxShadow: "1px 1px 2px 0px #00000030",
          },
          "& img": {
            boxShadow: "1px 1px 2px 0px #00000030",
          },
          "& span": {
            opacity: "1",
          },
        },
        "@media screen and (max-width:980px)": {
          display: "inline-flex",
          paddingBottom: "10px",
          alignItems: "center",
          "& img": {
            width: "35px",
            height: "35px",
          },
          "& span": {
            fontSize: "14px",
            opacity: 1,
            whiteSpace: "nowrap",
            lineHeight: 1,
            paddingLeft: "25px",
            textAlign: "left",
            color: "#000000",
            fontWeight: "100",
          },
        },
      },
      "& .sidebarLogo": {
        display: "block",
        textAlign: "center",
        "@media screen and (max-width : 980px)": {
          width: "75px",
          "& img": {
            filter: "invert(1)",
          },
        },
        "& img": {
          width: "40px",
        },
        "& span": {
          display: "none",
          height: "6px",
          background: "#ea6628",
          width: "85%",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "12px",
        },
      },
    },
    menuClose: {
      display: "none",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#0002",
      "@media screen and (max-width:980px)": {
        display: "block",
      },
    },
    tdIcon: {
      "& .statusIconActive": {
        fill: "#28a745",
      },
    },
    pagination: {
      "& > div > div": {
        padding: "10px 0",
        "&> .MuiTablePagination-spacer": {
          display: "none",
        },
      },
    },
    ThemeCard: {
      padding: "0",
      background: `${mode.cardBody}`,
      boxShadow: "1px 2px 2px rgb(4 24 87 / 22%) ",
      borderRadius: "4px ",
      overflow: "hidden",
      marginBottom: "14px",
      "& .statusBox": {
        height: "25px",
        width: "40px",
      },
      "& .cardHeader": {
        background: mode.cardHeader,
        padding: "7px 13px",
        color: mode.text,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& p": {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
      "& .userIcon": {
        padding: "0 0",
      },
      "& .cardContent": {
        padding: "14px 14px",
        paddingBottom: "4px",
        // minHeight:"50px",
        // borderBottom: '1px solid #ddd',
        "& td,th": {
          fontSize: "12px",
          padding: "4px",
          wordBreak: "break-all",
        },
        "& p": {
          fontSize: "10px",
          "&.medium-title": {
            fontSize: "14px",
          },
        },
        "& .cardSubTitle": {
          fontSize: 13,
          margin: "0",
          minHeight: "40px",
        },
      },
      "& .cardFooter": {
        padding: "5px",
        "&  img": {
          cursor: "pointer",
        },
        "& >div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          "& >*": {
            marginLeft: "10px",
          },
        },
        "& p": {
          fontSize: "12px",
          color: mode.text,
        },
      },
      "@media screen and (max-width:980px)": {
        margin: 0,
      },
    },
    footerRow: {
      display: "flex",
      alignItems: "center",
      "& p": {
        fontSize: "11px",
        fontWeight: 400,
        lineHeight: "17px",
        color: mode.text,
        marginLeft: "15px",
      },
      "& h6": {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        color: mode.text,
        borderRight: `1px solid ${mode.solids}`,
        width: "100px",
        paddingRight: "10px",
        whiteSpace: "nowrap",
      },
      "@media screen and (max-width:980px)": {
        display: "block",
        "& h6": {
          width: "auto",
          border: "none",
        },
        "& p": {
          marginLeft: 0,
        },
      },
    },
    freeTrial: {
      "& .pageHolder": {
        padding: "20px",
      },
      "& .pt3": {
        paddingTop: "3rem",
        paddingLeft: "3rem",
        paddingRight: "3rem",
      },
      "& .trialActions": {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& button": {
          margin: "0 10px",
          cursor: "pointer",
        },
      },
    },
    preLoginControls: {
      "& li": {
        padding: 0,
        marginBottom: "10px",
      },
      "& li div": {
        display: "flex",
        width: "100%",
        backgroundColor: "#cce5ff00",
        color: mode.text,
        textDecoration: "none",
        padding: "5px 11px",
        borderRadius: "18px",
        alignItems: "center",
        transition: ".5s ease-in-out",
        cursor: "pointer",
        "&.active a": {
          backgroundColor: "#cce5ff",
        },
        "&:hover": {
          backgroundColor: "#cce5ff",
        },
        "& svg": {
          height: "20px",
        },
      },
      "& span": {
        border: "none",
        margin: "0",
        paddingLeft: "10px",
      },
      "@media screen and (max-width:980px)": {
        "& li": {
          marginBottom: "5px",
        },
        "& .configList": {
          display: "flex",
          flexWrap: "wrap",
          "& li": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
        },
      },
      "& .active .customBtnLink": {
        color: "#000000",
        "& span": {
          color: "#000000",
        },
      },
    },
    menuItem: {
      padding: "0 10px !important",
      minWidth: "100px",
      fontSize: "13px !important",
      lineHeight: "2.5 !important",
    },
    cardStyle: {
      boxShadow: "3px 3px 3px 0px #0e8efb66",
      height: "auto",
      backgroundColor: mode.body,
      "& .agentsHeader": {
        "& >div": {
          width: "25px",
          height: "25px",
          lineHeight: "25px !important",
          fontSize: "13px",
          marginRight: "10px",
        },
        "& > p": {
          fontSize: "13px",
        },
      },
    },
    cardStyleBg: {
      boxShadow: "3px 3px 3px 0px #0e8efb66",
      height: "auto",
      backgroundColor: "#ffe79c !important",
      "& .agentsHeader": {
        "& >div": {
          width: "25px",
          height: "25px",
          lineHeight: "25px !important",
          fontSize: "13px",
          marginRight: "10px",
        },
        "& > p": {
          fontSize: "13px",
        },
      },
    },
    licenseBodyContent: {
      display: "flex",
      gap: "7px",
      "& div": {
        flex: "0 0 50%",
        maxWidth: "50%",
      },
      "& p": {
        display: "flex",
        gap: "5px",
        fontSize: "12px !important",
        "& span": {
          fontWeight: "600",
          color: "#1567bb",
        },
      },
    },
    licenseFooterContent: {
      padding: "5px 10px",
    },
    prodsList: {
      "& th": {
        padding: "9px 16px",
        fontSize: "12px",
      },
      "& td": {
        padding: "0 16px",
        fontSize: "12px",
      },
    },
    prodActions: {
      fontSize: "12px !important",
      justifyContent: "flex-start !important",
      padding: "8px 15px !important",
    },
    SettingsTable: {
      display: "flex",
      justifyContent: "space-between",
      borderTop: "1px solid #ddd",
      padding: "10px 5px",
      fontSize: "13px",
      textTransform: "capitalize",
    },
    prodsShopMethod: {
      flexDirection: "row",
      padding: "13px",
    },
    CategoryBox: {
      "& .rst__lineHalfHorizontalRight::before": {
        background: mode.blckWhite,
      },
      "& .rst__moveHandle, .rst__loadingHandle": {
        backgroundColor: mode.gryToWhite,
      },
      "& .rst__rowContents": {
        background: mode.whiteSolid,
      },
    },
    newTcktsBox: {
      width: "100%",
      border: "1px solid #5555",
      // background: mode.text !== "#ffffff" ? "#C2D8FA" : mode.solids,
      // backgroundImage:
      //   mode.text !== "#ffffff"
      //     ? "linear-gradient(to bottom, #9fd9fc, #a9ddfc, #b3e1fc, #bde4fc, #c7e8fc)"
      //     : "unset",
      borderRadius: 10,
      "& > p": {
        fontSize: 12,
        textAlign: "center",
        paddingBottom: 5,
      },
      "& > div": {
        maxHeight: "150px",
        minHeight: "150px",
        // maxWidth: '700px',
        overflow: "scroll",
        width: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          width: "0px",
        },
      },
    },
    activeDirSection: {
      background: mode.whiteSolid,
      padding: "50px 20px",
    },
    contactSearchDropdown: {
      width: "100%",
      "& > div": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginTop: "0 !important",
        "& > p": {
          fontSize: "12px",
          maxWidth: "150px",
          flex: "0 0 150px",
        },
        "& > div": {
          "& input": {
            padding: "11px 15px !important",
            paddingRight: "50px !important",
          },
          "& button": {
            padding: "0",
            zIndex: "1",
          },
          "& > div": {
            padding: "0 0 !important",
            background: mode.solids,
          },
        },
      },
    },
    cardTcktCount: {
      padding: "5px 10px",
      background: "#ffa000",
      display: "inline",
      lineHeight: "1",
      fontSize: "12px",
      position: "relative",
      "& .dots": {
        position: "absolute",
        width: "8px",
        height: "8px",
        background: mode.cardHeader,
        left: "-5px",
        borderRadius: "50%",
        top: "50%",
        transform: "translateY(-50%)",
      },
      "& .dots.dots-right": {
        left: "unset",
        right: "-5px",
      },
      "& .dots.whiteBG": {
        background: mode.whiteSolid,
      },
    },
    AssetCount: {
      display: "flex",
    },
    uplodImgIcon: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "80px",
      textAlign: "center",
      border: "1px dashed #5558",
      fontSize: "10px",
      height: "80px",
      width: "80px",
      flex: "0 0 80px",
      justifyContent: "center",
      cursor: "pointer",
    },
    AssetGrid: {
      display: "flex",
      gap: "9px",
      flexWrap: "wrap",
      "& >div": {
        position: "relative",
        cursor: "pointer",
        "&>div": {
          position: "absolute",
          top: "50%",
          right: "50%",
          color: "#ffffff",
          transform: "translate(50%, -50%)",
          opacity: "0",
          transition: ".5s ease-in-out",
          cursor: "pointer",
        },
        "&.active": {
          "& > img": {
            filter: "brightness(0.5)",
          },
        },
        "&:hover > img": {
          filter: "brightness(0.5)",
        },
        "&:hover > div": {
          opacity: "1",
        },
      },
      "& img": {
        transition: ".5s ease-in-out",
        objectFit: "cover",
      },
    },
    galleryImgs: {
      "& img": {
        objectFit: "cover",
      },
    },
    resolveBtn: {
      fontSize: "12px",
      background: "#c5d0f7",
      padding: "0",
      textTransform: "capitalize",
    },
    popupFilter: {
      "& input": {
        padding: "7px 4px  !important",
        fontSize: "12px !important",
      },
    },
    popupAssetsFilterList: {
      display: "flex",
      alignItems: "center",
      background: `${mode.solids}30`,
      borderBottom: "1px solid #ddd",
      cursor: "pointer",
      "& > div": {
        padding: "5px 5px",
        fontSize: "12px",
        flex: "0 0 16.66%",
        textAlign: "center",
      },
      "&  *": {
        cursor: "pointer",
      },
    },
    popupAssetsHeader: {
      background: mode.solids,
    },
    assetTcktRpld: {
      fontSize: "10px",
      background: "#c5d0f7",
      width: "max-content",
      padding: "3px",
      borderRadius: "3px",
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      "& svg": {
        height: "15px",
        width: "auto",
      },
    },
    customMultiDropdown: {
      display: "flex",
      alignItems: "center",
      "& > label": {
        flex: "0 0 150px",
        fontSize: "12px",
      },
      "& legend": {
        display: "none",
      },
      "& .selectBox>div": {
        padding: "8px",
      },
      "& .selectBox>fieldset": {
        top: 0,
      },
    },
    posRel: {
      position: "relative",
    },
    textarea: {
      padding: "10px",
      borderRadius: "4px",
      width: "100%",
      height: "150px !important",
      resize: "none",
      outline: "none",
    },
    topbarSelect: {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    profileBox: {
      padding: "0 0px",
    },
    rowMargin: {
      marginLeft: "-15px",
      padding: "0",
      marginRight: "-15px",
    },
    profileImgHolder: {
      position: "relative",
      paddingBottom: "25px",
      "& .profileInfo": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        position: "absolute",
        bottom: "0px",
        padding: "10px 20px",
        width: "100%",
      },
      "& .profileImg": {
        display: "flex",
        "& img": {
          height: "80px",
          width: "80px",
          objectFit: "contain",
          background: "#fff",
          border: "5px solid #fff",
          borderRadius: "6px",
          "-webkit-box-shadow": "0 4px 24px 0 rgb(34 41 47 / 10%)",
          boxShadow: "0 4px 24px 0 rgb(34 41 47 / 10%)",
        },
        "& *": {
          color: "#fff",
          lineHeight: 1,
        },
        "& h6": {
          marginBottom: "10px",
          paddingTop: "10px",
        },
      },
      "& .profileTxt": {
        paddingLeft: "10px",
      },
      "& .controls": {
        paddingBottom: "20px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& p": {
          color: "#fff",
          cursor: "pointer",
        },
      },
      "& .profilePicHolder": {
        position: "relative",
        boxShadow: "3px 3px 6px 1px #0003",
        borderRadius: "5px",
        "&  label": {
          position: "absolute",
          left: 0,
          top: 0,
          fontSize: "10px",
          background: "#0005",
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px",
          textAlign: "center",
          borderRadius: "6px",
          cursor: "pointer",
          lineHeight: 1.3,
          opacity: 0,
          "&:hover": {
            opacity: 1,
          },
        },
      },
    },
    PrefsIconsHolder: {
      display: "flex",
      "& img": {
        height: "30px",
        cursor: "pointer",
      },
      "& > div": {
        display: "block",
        border: "1px solid #ddd",
        padding: "5px",
        width: "46px",
        textAlign: "center",
        marginRight: "10px",
        cursor: "pointer",
        position: "relative",
      },
      "& svg": {
        fontSize: "15px",
      },
      "& .checkIcon": {
        position: "absolute",
        color: "#584fbc",
        top: "-7px",
        left: "-7px",
      },
    },
    DateFrmtBtns: {
      "& button": {
        marginRight: "10px",
      },
    },
    labelTxt: {
      padding: "2px 5px",
      background: "#3949ab",
      display: "inline-block",
      lineHeight: 1,
      borderRadius: "15px",
      color: "#fff",
    },
    tcktPriorityTitle: {
      display: "block",
      margin: "auto",
      width: "max-content",
      padding: "7px 10px",
      fontSize: "12px",
      color: "#ffffff",
    },
    cardBadges: {
      background: modeType === "light" ? "#ddd" : mode.cardHeader,
      display: "block",
      lineHeight: 1,
      fontSize: "10px !important",
      borderRadius: "9px",
      padding: "2px 5px",
      fontWeight: "500 !important",
      fontFamily: "'DM Sans', sans-serif !important",
      cursor: "pointer",
    },
    primaryTxt: {
      color: modeType === "light" ? "#1261b4" : mode.text,
    },
    warningTxt: {
      color: "#F39732 !important",
    },
    successTxt: {
      color: "#00b315",
    },
    dangerTxt: {
      color: "red",
    },
    footerBg: {
      background: modeType === "light" ? `#eff0f5` : mode.solids,
    },
    ticketCardiconButton: {
      fontSize: "10px !important",
      padding: "0",
      marginLeft: "7px",
      "& svg": {
        height: ".8em",
        width: ".8em",
      },
    },
    ticketCardTags: {
      display: "block",
      fontSize: "9px",
      padding: "5px 8px",
      background: mode.solids,
      whiteSpace: "nowrap",
      margin: "0 4px",
      color: mode.text,
    },
    checkedFilterBtn: {
      background: mode.solids,
    },
    tagBlue: {
      background: "#1261b4 !important",
      color: "#ffffff !important",
    },
    tagWarn: {
      background: "#f39732 !important",
      color: "#ffffff !important",
    },
    TicketTag: {
      "&::after": {
        background: mode.cardHeader,
      },
      "&::before": {
        background: mode.cardHeader,
      },
    },
    customSmlCard: {
      overflow: "visible !important",
      "& p": {
        fontSize: 8,
      },
      "& .desc": {
        fontSize: "8px",
      },
      "& .cardHeader": {
        background: mode.cardHeader,
        fontSize: "8px",
        display: "flex",
        justifyContent: "space-between",
        padding: "3px 7px",
      },
      "& .cardFooter": {
        borderTop: `1px solid ${mode.solids}`,
        fontSize: "8px",
        padding: "3px 7px",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          display: "inline-block",
        },
      },
    },
    textWithSpanTitle: {
      lineHeight: "1.5",
      marginBottom: 10,
      fontSize: "13px",
      "& > span": {
        display: "block",
        fontSize: "11px",
        lineHeight: "1",
        color: mode.link,
      },
    },
    popoverContact: {
      display: "flex",
      "& p": {
        fontSize: 10,
        "& svg": {
          width: "0.60em",
          height: "0.60em",
        },
      },
      "&> p": {
        padding: "0 5px !important",
      },
    },
    filterBtn: {
      color: mode.text,
      fontSize: "10px",
      textTransform: "capitalize",
      padding: 0,
      minWidth: "auto",
      marginRight: "10px",
    },
    listViewTable: {
      "& th , td": {
        padding: "6px 10px",
        fontSize: "10px",
        border: "1px solid #ddd",
        lineHeight: 1,
        textAlign: "center",
      },
      "& th": {
        fontWeight: " 600",
        fontSize: "11px",
      },
      "& tr": {
        backgroundColor: "#EAF7FE",
        backgroundImage: "linear-gradient(-270deg, #F4F4F4 0%, #FFEEDB 100%)",
      },
      "& .colorBox": {
        padding: "14px 8px",
        transform: "translateX(-50%)",
      },
    },
    rolesBlueTitle: {
      fontSize: 12,
    },
    onDarkTitleBg: {
      alignItems: "center",
      padding: "10px",
      background: modeType === "light" ? `` : mode.cardHeader,
    },
    cardProfileImg: {
      height: 30,
      width: 30,
      objectFit: "cover",
    },
    viewPopupTitle: {
      fontSize: 15,
    },
    commonModalContent: {
      borderBottom: "1px solid #ddd",
      borderTop: "1px solid #ddd",
    },
    viewActionBtns: {
      "& button": {
        textTransform: "capitalize",
        background: "#ddd5",
        padding: "5px 10px",
        fontSize: "11px",
        color: "#555",
        marginRight: "10px",
        borderRadius: "4px",
        border: "1px solid #ddd !important",
        "& > svg": {
          height: 14,
          width: 14,
          marginRight: 3,
        },
      },
    },
    viewDropDown: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .viewDropDown": {
        fontSize: "11px",
        "&>div >div": {
          padding: 0,
          paddingRight: "0 !important",
          background: "#ddd0",
          border: "1px solid #ddd0 !important",
          minWidth: "165px",
          "& >div": {
            display: "none",
          },
          "& input": {
            padding: "5px 10px !important",
            fontSize: 11,
            width: "80px !important",
            "&.Mui-disabled": {
              background: "#ffffff00 !important",
              "-webkit-text-fill-color": "#000000",
            },
          },
          "& input:focus": {
            background: "#f1f1f1",
          },
          "& input:hover": {
            background: "#f1f1f1",
          },
          "& fieldset": {
            border: "none !important",
          },
        },
        "& button": {
          display: "none",
        },
        "& button svg": {
          height: ".8rem",
          width: ".8rem",
        },
      },
    },
    addTaskinView: {
      background: "red",
    },
    listBox: {
      border: "1px solid #ddd",
      borderRadius: 4,
      "& > div": {
        padding: 10,
        borderBottom: "1px solid #ddd",
      },
    },
    listActionBtns: {
      "& svg": {
        fontSize: 16,
        marginLeft: 5,
        cursor: "pointer",
      },
    },
    ChartIconBox: {
      border: "1px solid #ddd",
      height: "30px",
      width: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "10px",
      "&.active": {
        border: "1px solid #4169e1",
        background: "#4169e12b",
      },
    },
    ChartListItem: {
      border: "1px solid #ddd",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "6px 8px",
      "& .li-title": {
        fontSize: 9,
      },
      "& div": {
        display: "flex",
        borderLeft: "1px solid #555",
      },
      "& svg": {
        fontSize: 14,
        marginLeft: 5,
        cursor: "pointer",
      },
    },
    ReportBtns: {
      padding: "5px 10px",
      lineHeight: 1,
      textTransform: "capitalize",
      background: "#ddd8",
      borderRadius: 0,
      fontSize: "11px",
      color: "#000",
      margin: "0 5px",
    },
    shwErrGroup: {
      margintop: "0px",
      marginbottom: "0px",
      marginleft: "32px",
      fontsize: "0.8rem",
    },
    priorityDot: {
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      marginLeft: "10px",
    },
    txtWithLink: {
      fontSize: ".7em",
      display: "flex",
      alignItems: "center",
      gap: "5px",
      "& a": {
        fontSize: ".7em",
        textDecoration: "none",
        display: "inline-block",
        lineHeight: 1,
        "& svg": {
          fontSize: "12px",
        },
      },
    },
  });

  const classes = useStyles();
  return classes;
}

export default CommonStyles;
//#3f4044
