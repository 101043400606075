import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  FormGroup,
  Switch,
} from "@mui/material";
import CommonStyles from "../common/CommonStyles";
import SettingsControl from "../common2/SettingsControl";
import avatarImg from "../../assest/icons/menu/img-Placeholder.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { getPricingById } from "../../redux/actions/userManagement/pricing";
import { IState } from "../../redux/reducers/rootReducers";
import {
  EditOrganisationAction,
  getOrganisationWithToken,
} from "../../redux/actions/FreeTrial/FreeTrial";
import { FileUpload } from "../../redux/actions/FileUpload/FileUpload";
import { getProfileImage } from "../../redux/actions/userManagement/agents";
import { ActionType, snackbar } from "../../redux/actions/Snackbars";
import AppLoader from "../common/AppLoader";
const packageData = require('../../../package.json');

const About: React.FC = () => {
  const dispatch = useDispatch();
  const useStyles = CommonStyles;
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  useEffect(() => {
    console.log("currOrganisation");
  }, []);
  
  
 
  return (
    <>
      <AppLoader enabled={loader} />
      <Container className={`${classes.ptsm2} , ${classes.mx0}`}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} className={classes.preLoginControls}>
            <SettingsControl />
          </Grid>

          <Grid item md={8} className={classes.brdrLeft}>
            <Box className={classes.whiteBox}>
              <Box className={`${classes.row} ${classes.justifyBetween}`}>
                <Typography
                  className={classes.themeColorTxt}
                  variant="body2"
                  display="block"
                  gutterBottom
                >
                  About
                </Typography>
                {/* <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={MFA}
                          name="MFA"
                          onChange={() => setMFA(!MFA)}
                        />
                      }
                      label="Enable Two-factor Authentication"
                    />
                  </FormGroup>
                </FormControl> */}
              </Box>
              <Divider />
              <br />
              <Box>
                <Grid container className={classes.upgradePopup} spacing={3}>
                  <Grid item xs={12}>
                    
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className="" spacing={1}>
                      <Grid item md={6} xs={12}>
                        <Box className="pl-20p mb2">
                          <Typography
                            className={classes.themeColorTxt}
                            variant="body2"
                          >
                            Version : <b>{packageData.version}</b>
                          </Typography>
                        </Box>
                      </Grid>
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default About;
