import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store/store";
import Theme from "./components/Theme/Theme";
import initialstate from "./redux/store/initialstate.json";
import { StyledEngineProvider } from '@mui/material/styles';

import axios, { AxiosResponse } from "axios";
import { ActionType } from "./redux/actions/Snackbars";
import { DoLogOutAction } from "./redux/actions/Login/Login";

const store = configureStore(initialstate);

//axios.defaults.baseURL='https://localhost:7062/';
//axios.defaults.headers.common['Authorization']='Auth Token';
axios.interceptors.request.use((request:any) => {
  console.log("request", request);
  let token = localStorage.getItem('loginInfo');
  let tenantCode = localStorage.getItem('tenantCode');
  if(token !== null){
    token = JSON.parse(token).token;
    request.headers.Authorization = `Bearer ${token}`;
  }
  if(tenantCode !== null){
    request.headers['tenant-code'] = tenantCode 
  }
  if(!request.data) return request;



  request.data.profilePicturePath = request.data.profilePicturePath || "/";
  request.data.agentAvatarPath = request.data.agentAvatarPath || "/";
  request.data.attachmentPath = request.data.attachmentPath || "/";
  if(request?.data?.assetAttachmentsList){
    request.data.assetAttachmentsList = request?.data?.assetAttachmentsList.map((i:any)=>{
      return {...i,attachmentPath: i.attachmentPath || "/"}
    })
  }
  if(request?.data?.ticketLogs?.attachment){
    request.data.ticketLogs.attachment = request.data.ticketLogs.attachment.map((i:any)=>{
      return {attachmentId:i.id,attachmentPath: i.attachmentPath || "/"}
    })
    request.data.ticketLogs.ticketLogAttachmentsList = request.data.ticketLogs.attachment;
  }
  return request;
});

// axios.interceptors.response.use((response: AxiosResponse) => {
//   console.log("interceptors_response", response)
  
//   if (response.status == 200 || response.status == 201) {
//     return response;
//   } else if(response.status == 401) {
//     store.dispatch(DoLogOutAction());
//   }
// })

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      console.log('404', error.response , error.response.status)
      store.dispatch({
        type: "LOGIN_CALL",
        payload: false,
      });
      // window.location.href = location.origin;
      localStorage.clear();
      //.dispatch(DoLogOutAction());
      // store.dispatch({
      //   type: ActionType.SNACKBAR,
      //   payload: {
      //     message: "Something happened!!!.",
      //     severity: "error",
      //     open: true,
      //   },
      // });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <Theme>
          <StyledEngineProvider injectFirst>
            
            <App />
          </StyledEngineProvider>
        </Theme>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
