import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";
// import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

var getTotal = function (myDoughnutChart : any) {
  var sum = myDoughnutChart.config.data.datasets[0].data.reduce(
    ({a, b}: {a: any, b: any}) => a + b,
    0
  );
  return `${sum} QPs`;
};


const DoughnutChart = ({ labels, datasets, ref, id, showLegend = true }: { labels: any, datasets: any, ref?: any, id?: any, showLegend?: boolean }) => {
  const options: any = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio

    cutoutPercentage: 90,
    plugins: {
      legend: {
        display: showLegend,
        position: 'top' as const,
        labels: {
          usePointStyle: false,
          filter: (legendItem: any, data: any) => data.datasets[0].data[legendItem.index] != 0

        },
      },

      datalabels: {
        display: showLegend,
        labels: {
          name: {
            align: 'top',
            color: "black",
            font: { size: 9 },
            formatter: function (value: any, ctx: any) {
              return ctx.active
                ? ''
                : ctx.chart.data.datasets[0].data[ctx.dataIndex] <= 0 ? "" : ctx.chart.data.labels[ctx.dataIndex];

            }
          },
          value: {
            color: "white",
            formatter: function (value: any, ctx: any) {
              return ctx.active
                ? ''
                : ctx.chart.data.datasets[0].data[ctx.dataIndex] <= 0 ? "" : `${ctx.chart.data.datasets[0].data[ctx.dataIndex]}%`;
            }
          }
        }
      }


    }
  }
  const data = {
    labels: labels,
    datasets: datasets,

    plugins: {
      labels: {
        render: "percentage",
        fontColor: ["green", "white", "red"],
        precision: 2
      }
    },
  };

  return (
    <Doughnut
      ref={ref}
      data={data}
      options={options}
      id={id}
    />
  );
}
export default DoughnutChart;


