import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import { downloadMultiFilesById } from "../TicketsManagement/TicketsManagement";
export const url = `${configs.BASE_URL}/SystemIcons`;
export const STORE_ALL_SYSTEM_ICONS = 'STORE_ALL_SYSTEM_ICONS';
export interface IFileUpload {
  _FormFile:File;
  type:string;
  fileupload?: any;
}
export const storeAllSystemIcons = (val: any) => ({
    type: STORE_ALL_SYSTEM_ICONS,
    payload: val,
});
export const alertMessage = (msg:string='',severity:string='success') => {
    return async (dispatch: any) => {
      try {
        dispatch({
            type: ActionType.SNACKBAR,
            payload: {
              message: msg,
              severity: severity,
              open: true,
            },
          });
      } catch (error:any) {
        return console.log(error.response);
      }
    };
  };
export const addSystemIcons = (data: any, callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url,data);
      try{callback && dispatch(getSystemIcons(callback))}catch(e){}
    } catch (error:any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const getSystemIcons = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url); 
      // response.data = response.data.map((i:any)=>{
      //  i.filePath = i.filePath.replaceAll("https://simplisysstoragev1.blob.core.windows.net","");
      //   i.filePath = "https://simplisysstoragev1.blob.core.windows.net" + i.filePath
      //   return i;
      // });
      // dispatch(storeAllSystemIcons(response.data))
      if(callback) callback("0",response.data) 
      // const fileIds = response.data.reduce((a:any,b:any)=>{
      //   return a+","+b.fileId
      // },'').substring(1)
      // dispatch(downloadMultiFilesById(`${fileIds}`,callback))
      // try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export default addSystemIcons;
