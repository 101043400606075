import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json";

/* OTHER-API-PATHS  */
import {url as PermissionUrl,permissionListUrl,storePermissions} from '../SystemManagement/Permission';
import { url as TicketTypesUrl,storeTicketTypeList } from "../SystemManagement/ticket";
import { url as CategoryUrl,storeCategoryList, storeCategoryObject } from "../SystemManagement/AddCategory";
import { url as groupsUrl ,storeGroupsList } from "../userManagement/group";
import { url as priorityUrl,storePriorityList } from "../SystemManagement/AddPriority";
import { url as mailServiceUrl, storeAllEmailSettings } from "../../actions/adminInterfaces/EmailSettings";
import { url as TicketStatusUrl,storeAllTicketStatus} from "../SystemManagement/TicketStatus";
import { setTimeout } from "timers";
import {url as ContactAidUrl} from "../../actions/SelfServicePortal/ContactAid/ContactAid";
import getSideBarPermission, { getAgentPermission } from "../../../components/navbar/sideNavbar/SidebarPermissions";
import { StoreAccessData } from "../Login/Login";
import sysData from "../../../components/userManagement/Role/formSysAdminData";
import agentDataFormat, { formCategoryData } from "../../../components/userManagement/Role/formAgentData";
import sspDataFormat from "../../../components/userManagement/Role/formSSPData";

const url = `${configs.BASE_URL}/Role`;
const permisionUrl = `${configs.BASE_URL}/Permissions`;
const systemType = `${configs.BASE_URL}/admintypes`;//SystemTypes`;
const deleteUrl = `${configs.BASE_URL}/Role/permanent/delete/`;
const changeStatus = `${configs.BASE_URL}/Role/status/change?ids=`;
const bulkChangeStatus = `${url}/soft/bulk/delete?ids=`;//0&status=false

//const allRoleAPIS:any  = [PermissionUrl,TicketTypesUrl,CategoryUrl,groupsUrl,systemType,priorityUrl,mailServiceUrl,TicketStatusUrl,ContactAidUrl];
const allRoleAPIS:any  = [PermissionUrl,CategoryUrl,systemType,mailServiceUrl,ContactAidUrl];
export const alertSnackbar = (message: any, msgType?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: message,
          severity: msgType,
          open: true,
        },
      });
    } catch (error: any) {}
  };
};
export const unsetRoleCheckBoxes = () => ({
  type: 'UNSET_ROLE_CHECKBOX_VALS',
  payload: null,
});
export const storeCategoriesForRole = (val: any) => ({
  type: 'STORE_ROLE_CATEGORIES_OPTION',
  payload: val,
});
export const storeAllSystemTypes = (val: any) => ({
  type: 'getSystemType',
  payload: val,
});
export const getRole = () => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response = await axios(url);
      response.data = response.data.map((i:any)=>{
        const temp = {
          ...i,
          label : i.name,
          value : i.name
        }
        return temp;
      })
      dispatch(unsetRoleCheckBoxes());
      dispatch(unsetSelectedOptions())
      dispatch(loading(false));
      dispatch({
        type: "getRole",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
export const selectAllPermissions = (val:any=[]) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "SELECT_ALL_OPTION",
        payload: val,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
export const selectAllAgentPermissions = (val:any=[],objKey="") => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "SELECT_ALL_AGENT_OPTION",
        payload: {
          key : objKey,
          value : val
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
export const updateCheckBoxes = (key:any, val:any=[],method:string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "CHECKBOX_STATUSES",
        payload: {keyLabel : key,value : val,method:method},
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
export const updateAgentCheckBoxes = (key:any, val:any=[],fieldId:string|number,method:string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "AGENT_CHECKBOX_STATUSES",
        payload: {
                  keyLabel : key, //Groups|Status
                  fieldId:fieldId, //GroupID|StatusID
                  value : val,//Permission ID
                  method:method // add/remove
                }
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
const addRole = (data: any, postUpdate?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: "addRole",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_ROLES}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(loading(false));
      dispatch(getRole);
      postUpdate && postUpdate('0');
    } catch (error: any) {
      postUpdate && postUpdate('1');
      if (error.response) {
        const errMsg = error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.";
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: errMsg,
            severity: "error",
            open: true,
          },
        });
        console.log("error=>", error.response.data);
      }
    }
  };
};
export const allApiCallsForRole = (allInfo:any={},callback?:any) => {
  return async (dispatch: any) => {
    try{
      //const allRoleAPIS:any  = [
      // PermissionUrl, 0
      // CategoryUrl,1
      // systemType,2
      // mailServiceUrl,3
      // ContactAidUrl];4
      const {TicketTypes,groups,priorities,ticketStatus} = allInfo;
      Promise.all(allRoleAPIS.map((endpoint:string) => axios.get(endpoint))).then(
        (all)=> {
          // dispatch(asyncCallback(all,callback));
          const allresponses = all.map((i:any)=>i.data);
          const sysDataInfo = sysData(allresponses[2],allresponses[0]);
          const formAgentData = agentDataFormat(TicketTypes, groups, allresponses[1], priorities, allresponses[0], allresponses[3], ticketStatus);
          const sspUserData = sspDataFormat(TicketTypes,allresponses[1],allresponses[4],allresponses[0]);//TicketTypes:any,category:any,SSPservices:any,permission:any

          console.log("ROLE_INFO",sysDataInfo,formAgentData)
          // const sysDataInfo = sysData(systemTypes,permissions)
          // const formAgentData = agentDataFormat(TicketTypes,groups,category,priority,permissions,emailSettings,ticketStatusList);
          // dispatch({
          //   type: "getSystemType",
          //   payload: allresponses[2]?.data,
          // });
          // dispatch(storeCategoryObject(formCategoryData(allresponses[1])));
          dispatch({
            type:"ROLE_INFO",
            payload:{
              sysDataInfo,
              formAgentData,
              sspUserData,
              isloaded:true
            }
          })
        }
      ); 
      //customAPiCall(rolesAccess,currAgent,callback); 
    }catch(e){
      console.log('get all data', e)
      if(callback) callback();
    }
  }
};

export const getPermissions = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${permisionUrl}/${id}`);
      dispatch(loading(false));
      dispatch({
        type: "getPermissions",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
export const unsetSelectedOptions = (type:string="UNSET_CHECKED_ITEMS") => {
  return async (dispatch: any) => {
    try {
      dispatch({type,payload:{}})
    } catch (error) {
      return console.log(error);
    }
  };
};
export const getSystemTypes = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${systemType}`);
      dispatch(loading(false));
      dispatch(storeAllSystemTypes(response.data));
      if(callback) callback("0",response.data)
    } catch (error) {
      return console.log(error);
    }
  };
};

export const storeRoleValuesOnEdit = (arr:any[]=[]) => {
    const tempObj:any = {
      agentCheckBoxStatus:{
        "Categories" :[]
      },
      checkedStatus:{},
      isSystemAdmin : false,
      isAgentAdmin : false,
      rankingObj : {
        Priorities : {},
        TicketStatus : {}
      }
    }
    try {
      arr.map((i:any)=>{
        const {isSystemAdmin,isContact,isAgentAdmin,adminTypes,adminChildIdLeavel1,adminChildIdLeavel2,adminChildIdLeavel3,adminChildIdLeavel4,ranking} = i
        if(adminTypes?.name){
          if(isSystemAdmin){
            tempObj.isSystemAdmin = true;
            if(!tempObj?.checkedStatus?.[adminTypes.name]){
              tempObj.checkedStatus[adminTypes.name] = []
            }
            if(!tempObj.checkedStatus[adminTypes.name].includes(i.permissionId)) tempObj.checkedStatus[adminTypes.name].push(i.permissionId)
          } else if (isAgentAdmin || isContact){

            if(isAgentAdmin && ranking > 0){
              if(adminTypes.name === "TicketStatus" || adminTypes.name === "Priorities"){
                tempObj.rankingObj[adminTypes.name][adminChildIdLeavel1] = ranking;
              }
            }

            tempObj.isAgentAdmin = isAgentAdmin;
            if(!tempObj?.agentCheckBoxStatus?.[adminTypes.name]){
              tempObj.agentCheckBoxStatus[adminTypes.name] = {}
            }
            if(adminTypes.name === "Categories"){
              let val = 0;
              if(adminChildIdLeavel4 !== null){
                val = adminChildIdLeavel4;
              }else if(adminChildIdLeavel3 !== null){
                val = adminChildIdLeavel3;
              }else if(adminChildIdLeavel2 !== null){
                val = adminChildIdLeavel2;
              }else if(adminChildIdLeavel1 !== null){
                val = adminChildIdLeavel1;
              }
              if(!tempObj.agentCheckBoxStatus.Categories.includes(val)) tempObj.agentCheckBoxStatus.Categories.push(val);
              return;
            } 
            if(!tempObj.agentCheckBoxStatus[adminTypes.name]?.[adminChildIdLeavel1]){
              tempObj.agentCheckBoxStatus[adminTypes.name][adminChildIdLeavel1]=[]
            } 
            if(!tempObj.agentCheckBoxStatus[adminTypes.name][adminChildIdLeavel1].includes(i.permissionId)){
              tempObj.agentCheckBoxStatus[adminTypes.name][adminChildIdLeavel1].push(i.permissionId)
            }
            //tempObj.agentCheckBoxStatus[adminTypes.name].push(i.permissionId)
          }
        }
        
      })
      
      console.log('storeRoleValuesOnEdit',tempObj)
      return tempObj;
    } catch (error:any) {
      console.log("storeRoleValuesOnEditstoreRoleValuesOnEdit",error)
      return tempObj;
    }
};

export const singleRole = (id: any,callback?:any,...otherInfo:any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(unsetSelectedOptions())
      const response = await axios.get(`${url}/${id}`);
      // //dispatch({type: "singleRole",payload: response.data,});
      // console.log('otherInfo',otherInfo)
      const modifiedData = storeRoleValuesOnEdit(response?.data?.agentPermissionsList);
      dispatch({
        type:"STORE_EDIT_ROLE_VALUE",
        payload:modifiedData
      })
      callback('0',response.data,modifiedData)
    } catch (error:any) {
      callback('1',error.response)
      return console.log(error);
    }
  };
};

export const LoggedInUsrRole = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      dispatch({
        type: "LOGGEDIN_USR_ROLE",
        payload: response.data,
      });
      const permissionsList = await axios.get(permissionListUrl)
      const RoleAccess = getAgentPermission(response?.data?.agentPermissionsList, response?.data?.isSuperAdmin)
      dispatch(StoreAccessData(RoleAccess,"STORE_AGENT_ROLE_ACCESS"))


      let sysRoleAccess: any = getSideBarPermission(response?.data?.agentPermissionsList, permissionsList?.data, response?.data?.isSuperAdmin)
      dispatch(StoreAccessData(sysRoleAccess))
    } catch (error) {
      return console.log(error);
    }
  };
};

export const updateRole = (data: any, id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/${id}`, data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:`${LanguageData.SUCCESS_ROLES}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(unsetSelectedOptions())
      callback && callback('0',response.data);
    } catch (error:any) {
      callback && callback('1',error.response);
      return console.log(error);
    }
  };
};

export const multipalUpdateRole = (data: any, ids: any) => {
  return function (dispatch: any) {
    axios.put(`${changeStatus}${ids}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdateRole",
      });
      dispatch(getRole());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "all Priority Updated Successfully",
          severity: "success",
          open: true,
        },
      });
    });
  };
};

export const softDeleteRole = (ids: any,status:boolean) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkChangeStatus}${ids}&status=${status}`);
      dispatch(getRole());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_ROLES}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const deleteRole = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}`);
      dispatch({
        type: "deleteRole",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_ROLES}${LanguageData.TICKET_DELETED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getRole());
    } catch (error) {
      return console.log(error);
    }
  };
};

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default addRole;
