import axios from "axios";
import configs from "../../../configs/config";
import LanguageData from "../../../configs/LanguageData.json"
import { alertMessage } from "../SystemIcons/systemIcons";
import { generateChartObj } from "../../../components/adminInterfaces/WallBoard/ChartItem";
import { triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";
export const url = `${configs.BASE_URL}/wallboard`;
export const codeUrl = `${configs.BASE_URL}/common/wallboard/by`;

export const getAllWallBoard = (callback?: any, isSSP: boolean = false) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${url}`);
      response.data = response.data.map((i: any) => {
        return { ...i, rid: `#${i.id}`, value: i.name, label: i.name }
      })
      dispatch({
        type: "STORE_ALL_WALLBOARD",
        payload: response.data
      })
      try { callback && callback("0", response.data) } catch (e) { }
    } catch (error: any) {
      callback && callback("1", error.response)
      return console.log(error.response);
    }
  };
};

export const getWallBoardId = (id: string | number, callback?: any, isSSP?: boolean) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${url}/${id}`);
      if (response?.data?.webform) {
        response.data.webform = {
          ...response.data.webform,
          label: response.data.webform.name,
          value: response.data.webform.name
        }
      }
      try { callback && callback("0", response.data) } catch (e) { }
    } catch (error: any) {
      callback && callback("1", error.response)
      return console.log(error.response);
    }
  };
};

export const addWallboard = (data: any, callback: any = undefined) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data);
      dispatch(alertMessage(`${LanguageData.SUCCESS_NEW_WALLBOARD}${LanguageData.TICKET_CREATED}`,"success"))
      try { callback && callback("0", response.data) } catch (e) { }
    } catch (error: any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!" : "Something went wrong! Please try again.", "error"))
      callback && callback("1", error.response)
      return console.log(error.response);
    }
  };
};

export const getWallboardById = (id: string | number, callback?: any, isSSP?: boolean) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${url}/${id}`)
      response.data.wallBoardTypeList = response.data.wallBoardTypeList?.map((ele:any)=>{
      return  {id:ele.id,...generateChartObj(ele.chartType,ele.value)}
      })
      try { callback && callback("0", response.data) } catch (e) { }
    } catch (error: any) {
      callback && callback("1", error.response)
      return console.log(error.response);
    }
  };
}

export const editWallboard = (id:any,data: any, callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/${id}`,data);
      dispatch(alertMessage(`${LanguageData.SUCCESS_WALLBOARD}${LanguageData.TICKET_UPDATED}`,"success"))
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};

export const getWallboardByCode = (id: string | number, callback?: any, TenantCode?: any) => {
  console.log("TenantCode1", TenantCode);
  return async (dispatch: any) => {
    try {
      const response = await axios(`${codeUrl}/${id}`, {
        headers: {
          "tenant-code": TenantCode,
        },
      });
      console.log("TenantCode", TenantCode);
      try { callback && callback("0", response.data) } catch (e) { }
    } catch (error: any) {
      callback && callback("1", error.response)
      return console.log(error.response);
    }
  };
};

export const statusUpdateWallBoard = (id:any,status: any, callback?:any) => {
  const statusMsg = status ? `${LanguageData.SUCCESS_WALLBOARD}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_WALLBOARD}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/status/change?ids=${id}&status=${status}`);
      dispatch(getAllWallBoard())
      dispatch(triggerAppAlert(statusMsg, 'success'))
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};