const initialState = {
  Data: [],
  categoryObject: {},
  unAssignedData:[],
  showLoading: false,
  allCategoryTree:[]
};

const categoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "getCategory":
      return {
        ...state,
        Data: action.payload,
      };
      case "unassignedTicket":
      return {
        ...state,
        unAssignedData: action.payload,
      };
    case "singleCategory":
      return {
        ...state,
        Data: action.payload,
      };
    case "storeCategoryTree": 
      return {
        ...state,
        allCategoryTree : action.payload
      }
    case "updateCategory":
    case "multipalUpdateCategory":
    case "bulkDeleteItem":
    case "softDeleteCategory":
      return {
        ...state,
      };
    case "deleteCategory":
      return {
        ...state,
      };
      case "getCategoryObject":
        return {
          ...state,
          categoryObject: action.payload?.obj,
          categoryObjectById: action.payload?.objById,
        };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
