import { Box, Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import FormFields from "./SplittedModules/FormFields";
import PermissionsBox from "./SplittedModules/PermissionsBox";
import { connect, useDispatch, useSelector } from "react-redux";
import { allApiCallsForRole, singleRole, unsetRoleCheckBoxes, unsetSelectedOptions } from "../../../redux/actions/userManagement/role";
import { IState } from "../../../redux/reducers/rootReducers";
import Breadcrumbs from "./../../common2/Breadcrumbs";
// import sysData from "./formSysAdminData";
// import agentDataFormat from "./formAgentData";
import { getCategory } from "../../../redux/actions/SystemManagement/AddCategory";
import { useParams } from "react-router-dom";
import { handleTime } from "../../Dashboard/TicketManagement/TicketDashboardHolder";
export interface IRolesFormType {
  name: string;
  description: string;
  isSuperAdmin:boolean;
  isSysAdmin:boolean;
  isAgent:boolean;
  isSSPUser:boolean;
}
const initialValues = { name: "", description: "", isSuperAdmin: false, isSysAdmin: false, isAgent: false, isSSPUser: false }
const AddNewRole = memo(({allInfo,categoryObject,roleDataStruct}:any) => {
  const dispatch = useDispatch();
  const param = useParams();
  const [initialVal, setInitialVal] = useState(initialValues);
  useEffect(()=>{
    if(roleDataStruct && Object.keys(roleDataStruct).length > 0) return;
    dispatch(allApiCallsForRole(allInfo));
    
    if(!categoryObject){
      dispatch(getCategory());
    }
    return ()=>{
      dispatch(unsetRoleCheckBoxes());
    }
  },[])
  const callback = (resStatus:string,res:any,rest:any) =>{
    if(resStatus !== "0") return;
    debugger;
    setInitialVal({
      ...initialValues,
      name: res?.name, 
      description: res?.description, 
      isSuperAdmin:  res?.isSuperAdmin,
      isSSPUser : res?.isSSPRole,
      isSysAdmin: rest?.isSystemAdmin, 
      isAgent : rest?.isAgentAdmin,
    })
  }
  useEffect(()=>{
    if(param?.id){
      dispatch(singleRole(param?.id,callback));
    }
  },[param?.id]);
  useEffect(()=>{
    console.log("categoryObject__allInfo",roleDataStruct,categoryObject , allInfo)
  },[categoryObject , allInfo]);

  return(
    <>
        {(
          ((param?.id && initialVal.name !== "") || !param?.id) 
          && 
          (categoryObject && allInfo)
        ) ? 
          <>
            {(categoryObject && allInfo) &&  <AddNewRole1 initialVal={initialVal} /> }
          </>
        : 
          <>Loading...</>
      }
    </>
  )
})
const AddNewRole1 = memo((props:any) => {
  const param = useParams();
  const [values, setValues] = useState<IRolesFormType>(props?.initialVal || initialValues);
  const [initialVal, setInitialVal] = useState(props?.initialVal || initialValues);
  // ========= onchange Handlers ================
  const formChangeHandler = useCallback((updateVal: IRolesFormType) => {
    console.log('setValues(updateVal)',updateVal)
    setValues(updateVal)
  }, [])


  const form = useMemo(() => <>
      <FormFields
        initialVals={initialVal}
        callback={formChangeHandler}
      />
  </>, [])


  const PermissionsBoxLayout = useMemo(() => <><PermissionsBox
    initialVals={values}
  /></>, [values])


  const fullLayout = useMemo(() => <>
    <Breadcrumbs
        data={[
                {
                  title:'UserManagement ',
                  path:'/UserManagement' 
                },
                {
                  title:'Roles ',
                  path:'/Roles' 
                },
                {
                  title: `${!param.id ? "Add " : "Edit "} Role`,
                  path: `${param.id ? `/Roles/${param.id}` : "/AddRole"}`,
                }
          ]
        }
    />
    <Box className="p-1 roles-page">
      <Box className="p-1 white-bg">
        <Typography>{param?.id ? "Edit " : "Add New "} Role</Typography>
        <hr />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {form}
          {PermissionsBoxLayout}
        </Grid>
      </Box>
    </Box>
  </>, [values]);


  return (<>
        {fullLayout}
  </>)
})

// export default UserAdminAccess;
const mapStateToProps = (state:IState) => {
  return ({
    allInfo : state?.TicketsReducer?.allResponses,
    categoryObject : state?.categoryReducer?.categoryObjectById,
    roleDataStruct : state?.roleReducer?.roleInfo,
  })
};
export default connect(mapStateToProps)(AddNewRole);
//export default AddNewRole;