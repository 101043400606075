import {
  deleteFileService,
  fileUploadService,
  getAllFilesService,
  getTicketAttachmentService,
  uploadTicketAttachment,
} from "../../../services/fileUpload/FileUpload";
import LanguageData from "../../../configs/LanguageData.json"
import { triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";

export enum FileUploadEnum {
  FILE_UPLOAD = "FILE_UPLOAD",
  ALL_FILES = "ALL_FILES",
  TICKET_ATTACHMENT = "TICKET_ATTACHMENT",
}
export interface IFileUpload {
  description: string;
  filetypeid?: number;
  formFile: File;
  fileupload?: any;
  data?: any;
}

export interface IFileUploadType {
  fileupload: IFileUpload;
  allFiles: any;
  ticketAttachment: any;
}

export interface IFileUploadAction {
  type: FileUploadEnum;
  payload: IFileUploadType;
}

export const FileUpload = (
  fileObject: IFileUpload,
  updateFileUploadPercentage: any,
  ticketId: any,
  fileUploadCallback:any,
  callbackObj:any = {},
  moduleType: any = ""
) => {
  return async (dispatch: any) => {
    let fileUpload = await fileUploadService(
      fileObject,
      updateFileUploadPercentage,
      fileUploadCallback,
      callbackObj,
      moduleType
    );
    if (ticketId) {
      let attachment = {
        ticketId: ticketId,
        attachmentId: fileUpload?.data.id,
      };

      // let fileAttachment = await uploadTicketAttachment(attachment);
    }

    dispatch({
      type: FileUploadEnum.FILE_UPLOAD,
      payload: fileUpload,
    });
    dispatch(triggerAppAlert(`${LanguageData.SUCCESS_PROFILE_IMAGE}${LanguageData.TICKET_UPDATED}`,"success"))
  };
};

export const getAllFiles = () => {
  return async (dispatch: any) => {
    let allFiles = await getAllFilesService();
    dispatch({
      type: FileUploadEnum.ALL_FILES,
      payload: allFiles,
    });
  };
};

export const getTicketAttachment = () => {
  return async (dispatch: any) => {
    let allFiles = await getTicketAttachmentService();
    dispatch({
      type: FileUploadEnum.TICKET_ATTACHMENT,
      payload: allFiles,
    });
  };
};

export const deleteFile = (fileId: any, deleteFileResponse: any) => {
  return async (dispatch: any) => {
    let allFiles = await deleteFileService(fileId);

    deleteFileResponse && deleteFileResponse();
  };
};
