import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { FileUpload } from '../../../redux/actions/FileUpload/FileUpload';
import profileImg from "../../../assest/icons/menu/img-Placeholder.png";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
function ProfilePictureUploader(props: any) {
    const { callback,path="", classes="" } = props;
    const dispatch = useDispatch()
    
    const uploadCB = (resStatus: string, res: any) => {
        if (resStatus === "0") {
            if (callback) callback(res?.data);
        }
    };

    const uploadHandler = (file: any) => {
        let fileObject = {
          description: "First File",
          filetypeid: 1,
          ExtensionTypeId: 1,
          Name: "profile",
          formFile: file.target.files[0],
        };
        dispatch(
          FileUpload(fileObject, null, false, uploadCB)
        );
    };
    const inputFileHandler = (file: any) => {
      uploadHandler(file);
    };
    return (
        <Box className={`profileImgBox ${classes}`}>
            <Box>
                <input
                    id="profileImage"
                    accept=".jpeg, .img, .png"
                    type="file"
                    multiple={false}
                    value=""
                    onChange={inputFileHandler}
                    //value={FileUploadVal}
                    className="d-none"
                />
                <label htmlFor="profileImage">
                    <span>
                        <CloudUploadOutlinedIcon />
                        Choose Image
                    </span>
                </label>

                {path === "" ? 
                    <img src={profileImg} height="70px" width="70px" alt="" /> :
                    <img src={path} height="70px" width="70px" alt="" />
                }

            </Box>
        </Box>
    )
}

export default ProfilePictureUploader
