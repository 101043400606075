import { Box, Button, Grid } from '@mui/material'
import React, { createRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { TextBoxLatest } from '../common/TextBoxLatest/TextBoxLatest'
import CustomFormLabel from '../common/form-label/CustomFormLabel ';
import CustomSelect from '../common/customSelect/CustomSelect';
import AddCategoryCopy from '../../pages/new-ticket/AddCategoryPopup';
import { useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { IState } from '../../redux/reducers/rootReducers';
import { handleTime } from '../Dashboard/TicketManagement/TicketDashboardHolder';
import CommonStyles from '../common/CommonStyles';

const GeneralProperties = memo(({ submitHandler,initialVal, categoryList, ticketTypes,type }: any)=> {
    const titleRef: any = createRef();
    const descRef: any = createRef();
    const classes= CommonStyles();
    const navigate = useNavigate();
    const [errorObj,setErrorObj] = useState<any>({
        ticketTypeId: false,
        categoryId: false,
        title: false,
        description: false
    })


    const [addCategoryPopup, setAddCategoryPopup] = useState<boolean>(false);
    const [catTree, setCatTree] = React.useState<any>([]);
    const [ticketTypeVal, setTicketTypeVal] = useState<any>(null);
    const [categoryVal, setCategoryVal] = React.useState<any>(null);
    const onCategorySubmit = useCallback((res: any) => {
        console.log("onCategorySubmit", res, "category");
        setCategoryVal(res)
        unsetError("categoryId")
    }, []);
    const cancelHandler = () => {
        type === "Survey" ? navigate('/SurveyTemplates'):
        navigate('/webFormList')
    }
    useEffect(() => {
        console.log('____editValue',initialVal)
        if(!initialVal) return;
        // useEffect(() => {
        if(descRef?.current){
            descRef.current.value = initialVal.description || "";
        }
        if(titleRef?.current){
            titleRef.current.value = initialVal.name;
        }
        if(initialVal.ticketType){
            setTicketTypeVal({
                ...initialVal.ticketType,
                label: initialVal.ticketType.displayName,
                value: initialVal.ticketType.displayName,
            })
        }
        if(initialVal.category){
            setCategoryVal({
                ...initialVal.category,
                label: initialVal.category.name
            })
        }
    }, [initialVal])
    useEffect(() => {
        if (ticketTypeVal && ticketTypeVal?.id && categoryList?.filter) {
            const chosenCategory = categoryList.filter((x: any) => x?.categoryTicketTypeList?.[0]?.ticketTypeId === ticketTypeVal.id)[0];
            if(chosenCategory) setCatTree([chosenCategory]);
        } else {
            setCatTree([])
        }
    }, [ticketTypeVal])
    const onSubmit = () => {
        let valid = true;
        const tempObj = {
            ticketTypeId: false,
            categoryId: false,
            title: false,
            description : false,
        }
        if (!titleRef?.current?.value ) {
            tempObj.title = titleRef?.current?.value ? false : true;
            valid = false;
        }
        if (type === "Survey" && !descRef?.current?.value ) {
            tempObj.description = descRef?.current?.value ? false : true;
            valid = false;
        }
        if (type !== "Survey" && !ticketTypeVal) {
            tempObj.ticketTypeId = ticketTypeVal ? false : true;
            valid = false;
        }
        if (type !== "Survey" && !categoryVal) {
            tempObj.categoryId = categoryVal ? false : true;
            valid = false;
        }
        setErrorObj(tempObj)
        

        

        if(!valid) return;
        submitHandler(titleRef?.current?.value,descRef?.current?.value, ticketTypeVal?.id || null, categoryVal?.id || null)
    }
    const DescError = useMemo(() => <>
        {errorObj.description && <CustomFormLabel
            labelName={"Please Enter Description"}
            isMandotary={false}
            classNames='sml-txt txt-danger'
        />
    }</>, [errorObj.description]);
    const NameError = useMemo(() => <>
        {errorObj.title && <CustomFormLabel
            labelName={"Please Enter Name"}
            isMandotary={false}
            classNames='sml-txt txt-danger'
        />
    }</>, [errorObj.title]);
    const TicketTypeError = useMemo(() => <>
        {errorObj.ticketTypeId && <CustomFormLabel
            labelName={"Please Select Ticket Type"}
            isMandotary={false}
            classNames='sml-txt txt-danger'
        />
    }</>, [errorObj.ticketTypeId]);
    const CategoryError = useMemo(() => <>
        {errorObj.categoryId && <CustomFormLabel
            labelName={"Please Select Category"}
            isMandotary={false}
            classNames='sml-txt txt-danger'
        />
    }</>, [errorObj.categoryId]);

    const ChooseCategoryPopup = useMemo(() => <>
        {addCategoryPopup && <AddCategoryCopy
            setAddCategoryPopup={setAddCategoryPopup}
            addCategoryPopup={true}
            category={catTree}
            onCategorySubmit={onCategorySubmit}
        />
        }</>, [catTree, addCategoryPopup]);
    const unsetError = (key:string) =>{
        handleTime.cancel();
        handleTime.setup(()=>{
            setErrorObj({
                ...errorObj,
                [key] : false
            })
        });
    }
    return (<>
        <Grid container spacing={3}>
            {type === "Survey" &&
                <>
                    <Grid item md={4.5} xs={12}>
                        <TextBoxLatest
                            multiline={false}
                            placeholder="Enter Title"
                            title={"Name"}
                            ref={titleRef}
                            isMandotary={true}
                            onchangeHandler={() => { unsetError("title") }}
                        />
                        {NameError}
                    </Grid>
                    <Grid item md={4.5} xs={12} className='webforms-details'>
                        <TextBoxLatest
                            multiline={false}
                            placeholder="Enter Description"
                            title={"Description"}
                            ref={descRef}
                            isMandotary={true}
                            onchangeHandler={() => { unsetError("description") }}
                        />
                        {DescError}
                    </Grid>
                </>
            }
            {type === "Ticket" &&
                <>
                    <Grid item md={3} xs={12}>
                        <TextBoxLatest
                            multiline={false}
                            placeholder="Enter Title"
                            title={"Name"}
                            ref={titleRef}
                            isMandotary={true}
                            onchangeHandler={() => { unsetError("title") }}
                        />
                        {NameError}
                    </Grid>
                    <Grid item md={3} xs={12} className='webforms-details'>
                        <CustomFormLabel
                            labelName={"Select TicketType"}
                            isMandotary={true}
                        />
                        <CustomSelect
                            isOpen={false}
                            placeholder="Select TicketType"
                            width={"100%"}
                            name="workflowNodeAgentApprovalList"
                            isColor={false}
                            isDisabled={false}
                            isError={false}
                            defaultValue={ticketTypeVal}
                            options={ticketTypes || []}
                            handleChange={(e: any) => {
                                { console.log("event", e) }
                                unsetError("ticketTypeId")
                                setTicketTypeVal(e)
                                setCategoryVal(null)
                            }}
                            isMulti={false}
                            helperText={""}
                            customClassNames="sml-txt-dropdown Select-TicketType"
                        />
                        {TicketTypeError}
                    </Grid>
                    <Grid item md={3} xs={12} className='webforms-subcategory'>
                        <CustomFormLabel
                            labelName={"Select Sub Category"}
                            isMandotary={true}
                        />
                        <Button className="input-like-btn" onClick={() => { setAddCategoryPopup(true) }}>
                            {categoryVal?.label || ""}
                        </Button>
                        {CategoryError}
                    </Grid>
                </>
            }
            <Grid item md={3} xs={12} className="text-right">
                <Box className="text-center" style={{ padding: "10px" }}>
                    <Button className={`${classes.btn} ${classes.btnCancel}`} style={{ width: "100px" }} variant="contained" onClick={cancelHandler}>Cancel</Button> &nbsp; &nbsp;
                    <Button variant="contained" onClick={onSubmit}>Submit</Button>
                </Box>
            </Grid>
        </Grid>
        {ChooseCategoryPopup}
    </>
    )
})

const mapStateToProps = (state:IState) => {
    return ({
      //LoginReducer: state?.LoginReducer?.roleAccess,
        categoryList : state?.categoryReducer?.Data?.data,
        ticketTypes : state?.TicketsReducer?.allResponses?.TicketTypes
    })
  };
  
export default connect(mapStateToProps)(GeneralProperties);