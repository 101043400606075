import React from 'react'
import { TextField,Box } from '@mui/material';
import CustomFormLabel from '../form-label/CustomFormLabel ';
interface TextBoxLatestType {
    id?: number | string;
    multiline?: boolean;
    placeholder: string;
    isMandotary? : boolean;
    onchangeHandler?: (e:any) => void;
    onKeyDownHandler?: (e:any) => void;
    onBlur?: (e:any) => void;
    title?: string;
    ref:any;
    type?:string;
    name?:string;
    errorMsg?:string;
    isError?:boolean;
    disabled?:boolean;
    parentClassNames?:string;
    InputProps?:any;
}
export const TextBoxLatest = React.forwardRef((props:TextBoxLatestType, ref:any=null) => { //(props:TextBoxLatestType) => {
    const {parentClassNames="",onBlur, multiline=false,name="",InputProps,placeholder="",onchangeHandler,title="",isMandotary=false,type='text',errorMsg="Please Enter",isError=false,disabled=false,onKeyDownHandler} = props;
  return (
    <Box className={`${parentClassNames}`} >
        <CustomFormLabel
            labelName={title}
            isMandotary={isMandotary}
        />
        <TextField
          //id="standard-multiline-flexible"
          fullWidth
          label=""
          type={type}
          inputRef={ref}
          error={isError ? true : false}
          multiline={multiline}
          maxRows={3}
          rows={3}
          name={name}
          autoComplete='off'
          disabled={disabled}
          placeholder={placeholder}
          className={`common-new-input ${multiline?"multiline":""}`}
          onKeyDown={onKeyDownHandler}
          onChange={onchangeHandler}
          onBlur={onBlur}
          InputProps={InputProps||null}
        />
        {isError &&
          <CustomFormLabel
              labelName={errorMsg}
              isMandotary={false}
              classNames='txt-danger'
          />
        }
    </Box>
  )
})
