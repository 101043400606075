import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  makeStyles,
  ListItemText,
  Autocomplete,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircleOutlined";
import React, { useState, useEffect } from "react";
import AddAlertIcon from "@mui/icons-material/AddAlertOutlined";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import Brightness4Icon from "@mui/icons-material/Brightness4Outlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import intl from "react-intl-universal";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../../redux/actions/Theme/Theme";
import { IState } from "../../../redux/reducers/rootReducers";
import Avatar from "@mui/material/Avatar";
import CommonStyles from "./../../common/CommonStyles";
import darkmodeImg from "./../../../assest/icons/menu/darkmode.svg";
import printer from "./../../../assest/icons/menu/print.svg";
import cog from "./../../../assest/icons/menu/settings.svg";
import preferencesIcon from "./../../../assest/preferences.svg";
import helpIcon from "./../../../assest/icons/menu/how.svg";
import flagIcon from "./../../../assest/icons/menu/flag.svg";
import notificationIcon from "./../../../assest/icons/menu/notification.svg";
import {
  getOrgSeetings,
  getOrganisationWithToken,
} from "../../../redux/actions/FreeTrial/FreeTrial";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { DoLogOutAction } from "../../../redux/actions/Login/Login";
import profile from "./../../../assest/profile.png";
import * as locales from "@mui/material/locale";
import moment from "moment";
import { storeAllResponses } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { useMsal } from "@azure/msal-react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CommonPopover from "../../common/popover/CommonPopover";
import NotificationItem from "../../../pages/notification/NotificationItem";
import NotificationCircle from "../../../pages/notification/NotificationCircle";
import NotificationItemContainer from "../../../pages/notification/NotificationItemContainer";
import { getNotifications } from "../../../services/timeline/timeline.service";
import InfiniteScroll from "react-infinite-scroll-component";

console.log("localeslocales", locales);
type SupportedLocales = keyof typeof locales;

const getOrgSettingsFun = async (id: any) => {
  let res = await getOrgSeetings(id);
  return res;
};
export const getTimeLineById = async (pageCount: Number) => {
  let timelineLogs = await getNotifications(pageCount);
  return timelineLogs;
};
// import  {ThemeStatus}  from "../../common/CustomTheme";
const TopNavbar: React.FC = () => {
  //const currAgent = useSelector((state: IState) => state?.FreeTrialReducer?.loggedInAgent);

  // const currAgent = useSelector(
  //   (state: IState) => state?.agentReducer?.loggedInAgent
  // );
  // const currOrganisation = useSelector(
  //   (state: IState) => state?.FreeTrialReducer?.getLoggedOrganisation
  // );

  const isLoggedIn = useSelector(
    (state: IState) => state?.LoginReducer?.isSubmitted
  );
  // const AppTheme:any = useSelector((state: IState) => state?.LoginReducer?.theme);
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const currOrganisation = useSelector(
    (state: IState) => state?.FreeTrialReducer?.getLoggedOrganisation
  );
  const navigate = useNavigate();
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const useStyles = CommonStyles;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [OrgTitle, setOrgTitle] = useState<string | null>("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const [CurrUser, setCurrUser] = useState([] as any);
  const [loc, setLoc] = useState([] as any);
  const profileImg = useSelector(
    (state: IState) => state?.agentReducer?.saveProfile
  );
  const open = Boolean(anchorEl);

  const [proOveranchorEl, setproOverAnchorEl] = useState(null);
  const popoverOpen = Boolean(proOveranchorEl);
  const [notifItem, setNotifItem] = useState<any>([]);
  const count = useSelector((state: IState) => state.NotifReducer.count);
  const allNotif = useSelector((state: IState) => state.NotifReducer.allNotif);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const editClick = (event: any) => {
    setproOverAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const temp = Object.keys(locales);
    temp.unshift("enGb");
    setLoc(temp);
  }, [locales]);

  useEffect(() => {
    if(allNotif.length>0){
      setNotifItem(allNotif);
      setPage(1)
      setHasMore(true)
    }
  }, [allNotif]);

  function handleMenu(event: any) {
    setAnchorEl(event.currentTarget);
  }
  const { instance, accounts } = useMsal();
  function LogOut() {
    console.log("accountsaccounts", accounts);
    instance.logout();
    dispatch(theme(false));
    localStorage.clear();
    dispatch(DoLogOutAction());
    dispatch(storeAllResponses({}));

    navigate("/");
  }
  function handleClose() {
    setAnchorEl(null);
  }

  const handleClosePopover = () => {
    setproOverAnchorEl(null);
  };
  const toggleDarkTheme = () => {
    dispatch(theme(!toggleTheme));
  };
  useEffect(() => {
    dispatch(getOrganisationWithToken());
    const orgName = localStorage.getItem("tenantCode");
    setOrgTitle(orgName ? orgName : "");
    getTimeLineById(page).then((res: any) => {
      if (res?.data?.length > 0) {
        console.log("all notif", res?.data);
        setNotifItem(res?.data);
        dispatch({
          type: "STORE_COUNT",
          payload: res?.unreadCount,
        });
      } else {
      }
    });
  }, []);
  useEffect(() => {
    //console.log('>>>>>>',AppTheme);
    console.log("currOrganisation topnavbar", currOrganisation);
    if (currOrganisation.id) {
      getOrgSettingsFun(currOrganisation.id).then((res: any) => {
        console.log("getOrgSettingsFun res", res);
        if (res?.data?.dateFormat == "en-Gb") {
          moment.locale("en-Gb");
        } else {
          moment.locale("en-US");
        }
      });
    }
  }, [currOrganisation]);

  const fetchNextWeek = () => {
    getTimeLineById(page + 1).then((res: any) => {
      if (res?.data?.length > 0) {
        setNotifItem((prevItems: any[]) => [...prevItems, ...res.data]);
      } else {
        setHasMore(false);
      }
    });
    setPage(page + 1);
  };

  return (
    <div className={`${classes.topBar} top-nav-bar`}>
      <Toolbar className="d-flx">
        <div>
          <ListItemText
            primary={
              <Typography variant="h6" className="pageTitle">
                {/* {intl.get("header.main.title")} */}
                {currOrganisation.name}
                {/* {OrgTitle} */}
                <div className="btnGrp">
                  <IconButton
                    aria-label="Notification"
                    aria-controls="notification"
                    aria-haspopup="true"
                    // onClick={handleMenu}
                    color="inherit"
                  >
                    <InfoIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Notification"
                    aria-controls="notification"
                    aria-haspopup="true"
                    //onClick={handleMenu}
                    color="inherit"
                  >
                    <StarBorderIcon />
                  </IconButton>
                </div>
              </Typography>
            }
            secondary={intl.get("header.secondary.title")}
          />
        </div>
        <div className="sm-hide">
          <Link to={`/MyProfile`}>
            <IconButton color="inherit">
              {!profileImg && profileImg === "" ? (
                <img src={profile} className="proImg" alt="" height={80} />
              ) : (
                <img
                  src={`${profileImg}`}
                  alt=""
                  className="proImg"
                  height={80}
                />
              )}
            </IconButton>
          </Link>
          <IconButton onClick={toggleDarkTheme} color="inherit">
            <img src={darkmodeImg} alt="" />
          </IconButton>
          {/* <IconButton color="inherit">
            <img src={printer} alt="" />
          </IconButton> */}
          <Link to={`/Preferences`}>
            <IconButton color="inherit">
              <img src={preferencesIcon} height={20} alt="" />
            </IconButton>
          </Link>
          <Link to={`/organisationSettings`}>
            <IconButton color="inherit">
              <img src={cog} alt="" />
            </IconButton>
          </Link>
          <IconButton color="inherit">
            <img src={helpIcon} alt="" />
          </IconButton>
          <IconButton color="inherit" style={{ position: "relative" }}>
            <NotificationsNoneIcon
              style={{ color: "inherit" }}
              // className={classes.notifIcon}
              onClick={editClick}
            />
            <NotificationCircle count={count} />
          </IconButton>

          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
            }}
            color="inherit"
          >
            <img className="flagImg" src={flagIcon} alt="" />
          </IconButton>
          <Menu
            id="basic-menu"
            sx={{ height: 300 }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {loc?.map((item: any) => {
              return (
                <MenuItem
                  onClick={(ele: any) => {
                    let temp = `${item.substring(0, 2)}-${item.substring(
                      2,
                      4
                    )}`;
                    console.log("clicked lang", temp);
                    localStorage.setItem("lang", temp);
                    moment.locale(temp);
                    handleClose();
                  }}
                >
                  {item.substring(0, 2)}-{item.substring(2, 4)}
                </MenuItem>
              );
            })}
          </Menu>

          {/*   <IconButton color="inherit" className="notificationIcon">
            <img src={notificationIcon} alt="" />
            <span className="count">3</span>
          </IconButton> */}
          <IconButton className="logoutBtn" color="inherit" onClick={LogOut}>
            <LogoutOutlinedIcon />
          </IconButton>
        </div>
      </Toolbar>
      <CommonPopover
        id={`viewContrsolss`}
        open={popoverOpen}
        anchorEl={proOveranchorEl}
        handleClose={handleClosePopover}
        bodyContent={
          <>
            <div
              style={{
                // backgroundColor: "#EAEDFF",
                padding: "8px 10px 5px 10px",
                display: "flex",
                alignItems: "center",
                gap: 5,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginBottom: "5px",
                }}
              >
                {" "}
                Notifications
              </div>
              {/* <div style={{fontSize:"12px"}}>Mark as Read All</div> */}
            </div>
            <Divider />

            <div style={{ overflowY: "auto", height: "400px", width: "420px" }}>
              <InfiniteScroll
                dataLength={notifItem.length ? notifItem.length : 0}
                next={fetchNextWeek}
                height={400}
                hasMore={hasMore}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>End of Log - no more data to display</b>
                  </p>
                }
                loader={<h4>Loading...</h4>}
              >
                {" "}
                <NotificationItemContainer notifItem={notifItem} />
              </InfiniteScroll>
            </div>
          </>
        }
      />
    </div>
  );
};

export default TopNavbar;
