import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { FileUpload } from "../../redux/actions/FileUpload/FileUpload";
import { useDispatch } from "react-redux";
import { fileUploadServiceNew } from "../../services/fileUpload/FileUpload";

export function getFileNameAndExtension(url: string) {
  try {
    // Extract file name from URL
    const filenameRegex = /\/([^\/?#]+)$/;
    const filenameMatches = filenameRegex.exec(url);
    const filename = filenameMatches ? filenameMatches[1] : null;

    // Extract file extension from URL
    const extensionRegex = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    const extensionMatches = extensionRegex.exec(url);
    const fileExtension = extensionMatches ? extensionMatches[1] : null;

    // Determine MIME type based on file extension
    let type = "unknown";
    if (fileExtension) {
      switch (fileExtension.toLowerCase()) {
        case "png":
        case "jpg":
        case "jpeg":
          type = "png";
          break;
        case "pdf":
          type = "pdf";
          break;
        case "docx":
          type = "doc";
          break;
      }
    }

    return { filename, fileExtension, type };
  } catch (error) {
    console.error("Error:", error);
    return { filename: null, fileExtension: null, type: "unknown" };
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    attachmentBox: {
      border: "1px solid #ddd",
      display: "flex",
      flexDirection: "row",
      gap: 10,
      padding: 10,
      alignItems: "center",
      "& img": {
        height: "30px",
      },
      "& p": {
        fontSize: 10,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 70,
      },
      "& svg": {
        height: "20px !important",
        width: "20px !important",
        color: "#ff0000",
      },
    },
  })
);

const addAttchmentUsingFileAPI = async (fileObject: any) => {
  console.log("addAttchmentfileObject", fileObject);
  let res: any = await fileUploadServiceNew(fileObject);
  return res;
};

const AddAttachmentBlobWithPreview = (props: any) => {
  const classes = useStyles();

  const imgCallBack = async (files: any) => {
    console.log("AddAttachmentBlobWithPreview",files,files.length > 0)
    if (files.length > 0) {
      const newAttachments = [...props?.attachmentList];
      Array.from(files).forEach((file: any) => {
        if (props.isAttachmentOnFly) {
          console.log("imgCallBack files", file);

          let fileObject = {
            name: file.name,
            fileTypeId: 1,
            ExtensionTypeId: 1,
            formFile: file,
            moduleType: "quicksendmailattachment",
          };

          addAttchmentUsingFileAPI(fileObject).then((res: any) => {
            if (res) {
              console.log("addAttchmentUsingFileAPI res", res.data.filePath);
              newAttachments.push({
                attachmentPath: res.data.filePath,
                fileName: file?.name,
                fileType: file?.type,
              });
              props.setAttachmentList(newAttachments);
            } else {
              console.log("addAttchmentUsingFileAPI res", res);
            }
          });
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            newAttachments.push({
              preview: reader.result,
              fileName: file?.name,
              fileType: file?.type,
              formFile: file,
            });
            props.setAttachmentList(newAttachments);
          };
          reader.readAsDataURL(file);
        }
      });
    }
  };

  const handleDelete = (index: any) => {
    props.setAttachmentList(
      props.attachmentList.filter((_: any, i: any) => i !== index)
    );
  };

  return (
    <>
      {props.isShowBtn && (
        <Box>
          <>
            {" "}
            <input
              id="templateAttachment"
              accept={props?.accept}
              type="file"
              multiple={false}
              onChange={(e: any) => {
                imgCallBack(e.target.files);
              }}
              //value={FileUploadVal}
              className="d-none"
            />
            <label
              className="Add-Attachment"
              htmlFor="templateAttachment"
              style={{
                fontSize: "16px",
                cursor: "pointer",
                color: "#0A93FC",
                display: "inline-block",
                padding: "10px 0",
              }}
            >
            
            {props?.type=="asset" ? "Upload asset images" : "+ Add attachment"} {"("}{props?.attachmentList?.length}{")"}
            </label>
          </>
        </Box>
      )}

      {props?.attachmentList?.length > 0 && (
        
        <div
          style={{
            marginLeft: "0px",
            display: "flex",
            flexDirection: "row",
            gap: 10,
            flexWrap:"wrap"
          }}
        >
          {props?.attachmentList?.map((i: any, index: number) => {
            return (
              <>
                <Box className="col-auto">
                  <Box className={`${classes.attachmentBox}`}>
                    <img
                      src={`${i?.preview ? i.preview : i?.attachmentPath}`}
                      height={30}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="body2">
                        {i?.preview
                          ? i?.fileName?.length > 7
                            ? i?.fileName?.slice(0, 7)
                            : i?.fileName
                          : getFileNameAndExtension(i?.attachmentPath)
                              ?.filename}
                      </Typography>
                      <Typography style={{ color: "gray" }} variant="body2">
                        {i?.preview
                          ? i?.fileType
                          : getFileNameAndExtension(i?.attachmentPath)
                              ?.fileExtension}
                      </Typography>
                    </div>
                    <Box>
                      <DeleteOutlinedIcon
                        className="cursor-pointer"
                        onClick={() => {
                          handleDelete(index);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AddAttachmentBlobWithPreview;
