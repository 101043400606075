import axios from "axios";
import configs from "../../configs/config";
const TicketUrl = configs.BASE_URL_Ticket;
export const getTicketsUrl = `${TicketUrl}/Ticket`;
export const getUserTicketsUrl = `${TicketUrl}/user/Ticket`;
export const getTicketsForCalendar = `${TicketUrl}/Ticket/calendar/filters`;
export const getTicketsByFilterUrl = `${TicketUrl}/Ticket/filters`;
export const getTicketsByFilterReportUrl = `${TicketUrl}/Reports/pivot/filters`;
export const getTicketsByFilterCSVUrl = `${TicketUrl}/reports/csv`;

export const AddTicketTasktaskUrl = `${TicketUrl}/TicketTask`;
export const AddUserTaskUrl = `${TicketUrl}/user/TicketTask`;
export const addNewTicket = async (newTicket: any, url?:string) => {
  try {
    return await axios.post(
      `${TicketUrl}/${url ? url : 'Ticket'}`,
      newTicket,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const getTickets = async () => {
  try {
    return await axios.get(`${configs.BASE_URL_Ticket}/Ticket`);
  } catch (e) {
    console.log(e);
  }
};
export const getTicketsSsp = async () => {
  try {
    return await axios.get(`${configs.BASE_URL_Ticket}/user/Ticket`);
  } catch (e) {
    console.log(e);
  }
};

export const getApprovalNeededTicket = async () => {
  try {
      let result = await axios.get(`${configs.BASE_URL}/user/Ticket/approvals`);
      return result.data;
  } catch (e) {
      console.log(e)
      return false
  }
}
export const getApprovalNeededTasks = async () => {
  try {
      let result = await axios.get(`${configs.BASE_URL}/user/TicketTask/approvals`);
      return result.data;
  } catch (e) {
      console.log(e)
      return false
  }
}

export const AssignTicketToGroupOrAgent = async (sourceid:string|number='0',ticketId:string|number='0',groupId:string|number='0',agentId:string|number='0',statusId:string|number='2') => {
  try {
    console.log(`${TicketUrl}/Ticket/update/owner-group/byticket/${ticketId}?groupid=${groupId}&ownerid=${agentId}&agentid=${agentId}&statusid=${statusId}`);
    //return {};
    const response = await axios.get(`${TicketUrl}/Ticket/update/owner-group/byticket/${ticketId}?groupid=${groupId}&ownerid=${agentId}&agentid=${agentId}&statusid=${statusId}`);
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const TicketPriorityChange = async (sourceid:string|number='0',ticketId:string='0', priorityId:string='0') => {
  try {
    console.log("START_TIME",new Date())
    const response = await axios.get(`${TicketUrl}/Ticket/update/priority/byticket/${ticketId}?priorityid=${priorityId}`);
    ;
    console.log(response)
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const TicketStatusChange = async (sourceid:string|number='0',ticketId:string='0', statusId:string='0') => {
  try {
    const response = await axios.get(`${TicketUrl}/Ticket/update/ticketstatus/${ticketId}?statusid=${statusId}`);
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const getTicketsById = async (id: any) => {
  try {
    let result =  await axios.get(`${configs.BASE_URL_Ticket}/Ticket/${id}`);
    return result.data
  } catch (e) {
    console.log(e);
    return false
  }
};


export const updateNewTicket = async (newTicket: any,id:string,altUrl?:string) => {
  try {
    return await axios.put(
      `${configs.BASE_URL_Ticket}/${altUrl ? altUrl : "Ticket"}/${id}`,
      newTicket,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    console.log(e);
    return false
  }
};

export const mergeTicket = async (childId: any,parentId:any) => {
  try {
    let result =  await axios.get(`${configs.BASE_URL_Ticket}/Ticket/merge?parentTicketId=${parentId}&chindrenTicketId=${childId}`);
    return result
  } catch (e) {
    console.log(e);
    return false
  }
};
export const linkTicket = async (childId: any,parentId:any) => {
  try {
    let result =  await axios.get(`${configs.BASE_URL_Ticket}/Ticket/link?parentTicketId=${parentId}&chindrenTicketId=${childId}`);
    return result
  } catch (e) {
    console.log(e);
    return false
  }
};
export const unLinkTicket = async (childId: any,parentId:any) => {
  try {
    let result =  await axios.get(`${configs.BASE_URL_Ticket}/Ticket/unlink?chindrenTicketId=${childId}`);
    return result
  } catch (e) {
    console.log(e);
    return false
  }
};
export const unMergeTicket = async (childId: any) => {
  try {
    let result =  await axios.get(`${configs.BASE_URL_Ticket}/Ticket/unmerge?chindrenTicketId=${childId}`);
    return result
  } catch (e) {
    console.log(e);
    return false
  }
};



export const sendEmailAfterTicketCreation = async (payload: any) => {
  try {
    return await axios.post(
      `${configs.BASE_URL_Ticket}/Ticket/send/mail`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    console.log(e);
    return false
  }
};


