import axios from "axios";
import configs from "../../../configs/config";
import { ActionType } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json"
const dynamicFieldsURL = `${configs.BASE_URL}/DynamicFields`;
export const url = `${configs.BASE_URL}/MailService`;
const EmailSettingById=`${url}`
const SettingsById = `${url}/by/role/`;
const softDeleteUrl = `${url}/status/change`;
const hardDeleteUrl = `${url}/permanent/delete`;
export const ADD_SETTINGS = 'ADD_SETTINGS';
export const GET_TEMPLATES = 'GET_EMAIL_SETTINGS';
export const STORE_DYNAMICFIELDS = 'STORE_DYNAMICFIELDS';
export const UNSET_SETTINGS_ALERT = 'UNSET_SETTINGS_ALERT';
export const storeDynamicFields = (val: any) => ({
    type: STORE_DYNAMICFIELDS,
    payload: val,
});
export const storeAllEmailSettings = (val: any) => ({
  type: GET_TEMPLATES,
  payload: val,
});
export const addNewEmailSettings = (val: any) => ({
  type: ADD_SETTINGS,
  payload: val,
});
export const unsetSubmitValuesAction = () =>{
  return async (dispatch: any) => {
      dispatch(addNewEmailSettings(''));
  }
}
export const addEmailSettingsAction = (data: any,callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url,data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_EMAIL_SETTING}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      callback()
    } catch (error:any) {
      dispatch(addNewEmailSettings('1'));
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response &&  typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getEmailSettings = () => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(url);
      ;
      dispatch(storeAllEmailSettings(response.data));
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getMailSettingsByID = (id:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(`${EmailSettingById}/${id}`);
      callback(response.data)
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getEmailSettingsByID = (id:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(`${SettingsById}${id}`);
      callback(response.data)
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getDynamicFields = () => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(dynamicFieldsURL);
      dispatch(storeDynamicFields(response.data));
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const softDeleteEmailSettings = (id:number,status:boolean) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${softDeleteUrl}?ids=${id}&status=${status}`);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: status ? `${LanguageData.SUCCESS_EMAIL_SETTING}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}` : `${LanguageData.SUCCESS_EMAIL_SETTING}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}`,
          severity: status ? "success" : "error",
          open: true,
        },
      });
      dispatch(getEmailSettings());
      ;
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};

export const hardDeleteEmailSettings = (id:number) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${hardDeleteUrl}/${id}`);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_EMAIL_SETTING}${LanguageData.TICKET_DELETED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getEmailSettings());
      ;
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};

export const editEmailSettings = (id:number,request:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.put(`${url}/${id}`,request);
      console.log(`${url}/${id}`);
      dispatch(addNewEmailSettings('0'));
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:`${LanguageData.SUCCESS_EMAIL_SETTING}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      callback();
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};



export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default getEmailSettings;
