import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, InputBase, Paper, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewIcon from "../../../assest/icons/viewIcon.svg";
import { getFeedbackBySurvey } from "../../../redux/actions/Survey/feedback";
import { getSurveys } from "../../../redux/actions/Survey/survey";
import { IState } from "../../../redux/reducers/rootReducers";
import CommonStyles from "../../common/CommonStyles";
import Card from "../../common/card";
import CustomModalDialog from "../../common/customDialog";
import Breadcrumbs from "../../common2/Breadcrumbs";
import Dropdown from "../../common2/Dropdown";
import NoDataFound from "../../common2/NoDataFound";
import { ConvertZ } from "../../helpers/helperFunctions";
import SelectWebformField from "../../webForms/SelectWebformField";
import CalendarRangeOptionSurvey from "./CalendarRangeOptionSurvey";
import ResetFilterSurvey from "./ResetFilterSurvey";

export default function SurveyResults() {

    const classes = CommonStyles()
    const dispatch = useDispatch()
    const rangeVal = useSelector((state: IState) => state?.FeedbackReducer?.rangeval);
    const feedback = useSelector((state: IState) => state.FeedbackReducer.feedbackList)
    const [surveys, setSurvey] = useState([]);
    const [selected, setSelected] = useState<any>({});
    const [parsedFeedbackValue, setParsedFeedbackValue] = useState<any>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [Data, setData] = useState<any>({
        surveyID: 0,
        searchVal:"",
    });
    const onInputChangeHandler = (e: any) => {
        const val = e.target.value;
        const name = e.target.name;

        setData({
            ...Data,
            [name]: val,
        });
    };

    useEffect(() => {
        if (Data.surveyID !== 0) {
            dispatch(getFeedbackBySurvey(Data.surveyID,null, Data.searchVal,moment(rangeVal?.startDate)?.toISOString(),moment(rangeVal?.endDate)?.toISOString()))
        }
        const surveySelected=surveys.find((ele:any)=>ele.id===Data.surveyID)
        setSelected(surveySelected)
    }, [Data,rangeVal])
    useEffect(()=>{
        dispatch(getSurveys(getCallback))
    },[])
    const getCallback = (resStatus: string, res: any) => {
        if (resStatus === "0") {
            setSurvey(res);
        }
    }
    const closeModal = () =>{
        setOpenModal(false)
    }

    const webFormFields = useMemo(() => {
        return selected?.webform?.webformFieldList?.map((i: any) => {
            const { fieldLabel, fieldType, fieldPlaceholder, fieldId } = i;
            i = {
                ...i,
                id: fieldId,
                content: fieldType,
                placeholder: fieldPlaceholder,
                label: fieldLabel,
                type: "text",
            }
            return (<>
                {parsedFeedbackValue &&
                <SelectWebformField block={i} value={parsedFeedbackValue[fieldId]} editable={false}/>}
            </>
            );
        });
    }, [selected?.webform?.webformFieldList,parsedFeedbackValue]);

    const handleOpenModel = (id: number) => {
        setOpenModal(true);
        let selectedFeedback = feedback.find((ele: any) => ele.id === id);
        if (selectedFeedback) {
            const parsedFeedback = JSON.parse(selectedFeedback.feedbackValue);
            setParsedFeedbackValue(parsedFeedback)
        }
    };
    const clearSearch = () => {
        setData({
            ...Data,
            searchVal: "",
        });
    };
    const resetAndCalendars = useMemo(() => <><CalendarRangeOptionSurvey />
        <ResetFilterSurvey /></>, [classes])
    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title: 'Survey Management ',
                        path: '/adminPanel'
                    },
                    {
                        title: 'Survey Results',
                        path: '/SurveyResults'
                    }
                ]}
            />
            <br />
            <Box className="p-2 view-service">
                <Grid container className="pt-1" spacing={12} style={{paddingLeft:"80px",paddingTop:"80px"}}>
                    <Grid item md={4} xs={12} style={{position:"relative",top:"9px",padding:"7px"}}>
                        <Typography variant="h6" style={{ marginBottom: "2rem", marginLeft: "5px" }}>
                            Survey Results
                        </Typography>
                        <Dropdown
                            data={surveys}
                            classname="h-inputs sm-pd"
                            label="Survey"
                            styplePropTitle={classes.title}
                            value={Data.surveyID}
                            name="surveyID"
                            onInputChangeHandler={onInputChangeHandler}
                            inputStyle={classes.inputStyle}
                        />
                    </Grid>
                    <Grid item md={4} xs={12} className="survey-searchbox p-0">
                        <Box>
                            <Paper className={classes.SearchBox}>
                                <InputBase
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={onInputChangeHandler}
                                    name="searchVal"
                                    id='FilterSearchBox'
                                    value={Data.searchVal}
                                />
                                {Data.searchVal &&
                                    <IconButton onClick={clearSearch} aria-label="clear" className={classes.iconButton}>
                                        <ClearIcon />
                                    </IconButton>
                                }
                                <SearchIcon />
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12} className="survey-calender p-0">
                        <Box style={{display:"flex", gap:"15px", alignItems:"center"}}>
                            {resetAndCalendars}
                        </Box>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "20px", marginLeft: "80px" }}>
                        {(Data.surveyID === 0 && feedback.length === 0) ? (
                            <Grid item xs={12}>
                                <Typography>Please Select Survey or You don't have feedbacks for selected Survey</Typography>
                            </Grid>
                        ) : (
                            <>
                                {feedback.length === 0 ? (
                                    <Grid item xs={12}>
                                        <NoDataFound />
                                    </Grid>
                                ) : (
                                    feedback.map((item: any, key: number) => (
                                        <Grid item xs={12} sm={6} md={4} key={key}>
                                            <Card
                                                styleProp={classes.cardStyle}
                                                customHeader={
                                                    <Box className={`${classes.row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100}`}>
                                                        <Typography variant="body2" className={classes.title}>
                                                            {item.id}
                                                        </Typography>
                                                    </Box>
                                                }
                                                cardBody={
                                                    <Box>
                                                        <Typography variant="body1">
                                                            Created Date: <b>{ConvertZ(item.createdAt)}</b>
                                                        </Typography>
                                                        <Typography variant="body1">
                                                        <pre> Subject :{" "}
                                                            <b>
                                                                {item.subject ? item.subject : "---"}
                                                            </b>
                                                        </pre>
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            Ticket Id: <b>{item.ticketId ? item.ticketId : "---"}</b>
                                                        </Typography>
                                                    </Box>
                                                }
                                                cardFooter={
                                                    <Box>
                                                        <Tooltip title="View">
                                                            <img
                                                                src={ViewIcon}
                                                                alt="view icon"
                                                                onClick={() => handleOpenModel(item.id)}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                }
                                            />
                                        </Grid>
                                    ))
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
            {openModal &&
                <CustomModalDialog
                    title=""
                    parentClasses="sml-popup"
                    id="raiseTicketModalSSP"
                    isOpen={openModal}
                    onClose={closeModal}
                    headerContent={<>
                        <Typography variant="body2">
                            {selected.webform.name}
                        </Typography>
                    </>}
                    bodyContent={<>
                        {webFormFields}
                    </>}
                />
            }
        </>
    )
}