import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { IState } from "../../redux/reducers/rootReducers";
import { useCallback, useEffect, useState } from "react";
import { getContacts } from "../../redux/actions/ContactManagement/Contact";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import { SspStatusUrl, getTicketSources, getTicketStatus } from "../../redux/actions/SystemManagement/TicketStatus";
import CommonStyles from "../common/CommonStyles";
import { getTicketByContact } from "../../redux/actions/TicketsManagement/TicketsManagement";
import { addTicketAsContactUrl } from "./AidServices";
import { updateNewTicket } from "../../services/dashboard/dashboard.service";
import { ActionType } from "../../redux/actions/dashboard";
import TicketQuickView from "../common/ticketQuickView";
import CustomModalDialog from "../common/customDialog";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFormLabel from "../common/form-label/CustomFormLabel ";
import CustomInput from "../common/textField/CustomInput";
import CommonAddAttachment from "../common/CommonAddAttachment";
import { AttachmentItem } from "./NewTicket";
import CancelIcon from "@mui/icons-material/Cancel";
import { getPriority } from "../../redux/actions/SystemManagement/AddPriority";


const getValidationSchema = () => {
    const schemaObject: any = {};
    schemaObject["description"] = Yup.string().test(
        "description",
        function (value = "") {
            if (value.trim() === "") {
                return this.createError({
                    path: this.path,
                    message: "Please fill this field",
                });
            } else {
                return true;
            }
        }
    );
    return Yup.object().shape(schemaObject);
};

export default function ViewTicket() {

    const classes = CommonStyles()
    const dispatch = useDispatch()
    const param = useParams()
    const [allStatusObj, setAllStatusObj] = useState<any>({});
    const [deleteModalStatus, setDeleteModalStatus] = useState(false);
    const [selectedTicketObj, setSelectedTicketObj] = useState<any>({});
    const [modalContent, setModalContent] = useState<any>({});
    const [data, setData] = useState<any>({
        description: "",
    });
    const [open, setOpen] = useState(false);

    const ContactTickets = useSelector(
        (state: IState) => state?.ContactsReducer?.contactTickets
    );
    const TicketSources = useSelector(
        (state: IState) => state?.ticketStatusReducer?.TicketSources
    );
    const ContactListImgs = useSelector(
        (state: IState) => state?.ContactsReducer?.contactImgDetails
    );
    // const { Priority } = useSelector((state: IState) => state?.priorityReducer?.Data);
    useEffect(() => {
        if (ContactTickets?.length === 0) {
            dispatch(getTicketByContact());
        }
        if (!TicketSources || TicketSources.length === 0) {
            dispatch(getTicketSources(null, "user/Source"));
        }
        dispatch(getTicketStatus(getTicketStatusCB, SspStatusUrl));
        dispatch(getPriority())
    }, []);
    const getTicketStatusCB = (res: any = []) => {
        if (res?.[0] === "0") {
            let tempObj: any = {};
            res[1].forEach((i: any) => {
                if (!tempObj[`${i.id}`]) {
                    tempObj[`${i.id}`] = i.name;
                    tempObj[`${i.name}`] = i.id;
                }
            });
            setAllStatusObj(tempObj);
            console.log("popopopo", res, tempObj);
        }
    };
    const filterd = ContactTickets.filter((ele: any) => param.id == ele.id)
    console.log(filterd, allStatusObj);
    const closeTicket = (request: any) => {
        const sspSource = TicketSources.filter((x: any) => x?.name === "SSP")?.[0]
            ?.id;
        console.log("allStatusObj", allStatusObj, allStatusObj?.["Closed"]);
        const statusId: any = allStatusObj?.["Closed"];
        request.ticketLogs.statusId = statusId;
        request.ticketLogs.source = sspSource ? sspSource : 10;
        console.log("allStatusObj", request, statusId, allStatusObj);
        ticketUpdate(request).then((res: any) => {
            console.log("ticketUpdate res", res);
            if (res.status == 201 || res.status == 200) {
                triggerAlert(`Ticket (${res?.data?.id}) Closed Successfully!`, "success");
                setTimeout(() => {
                    closeModal();
                }, 200);
                dispatch(getTicketByContact());
                setDeleteModalStatus(false);
            }
        });
    };
    const ticketUpdate = async (payload: any) => {
        console.log("payload", payload);
        let newTicket: any = await updateNewTicket(
            payload,
            payload?.id,
            addTicketAsContactUrl
        );
        return newTicket;
    };
    const triggerAlert = useCallback((msg: string, type: string) => {
        dispatch({
            type: ActionType.SNACKBAR,
            payload: {
                message: msg,
                severity: type,
                open: true,
            },
        });
    }, []);
    const closeModal = () => {
        setOpen(false);
    };
    const openModal = () => {
        setOpen(true);
    };
    const chooseService = (index: number) => {
        console.log(index);
        const tempObj: any = ContactTickets?.[index];
        if (tempObj?.ticketLogs?.ticketLogAttachmentsList) {
            tempObj.ticketLogs.ticketLogAttachmentsList = [];
        }
        setData({
            ...tempObj,
            description: "",
            title: tempObj?.ticketLogs?.title,
            id: tempObj?.id
        });
        console.log(data);

        setTimeout(() => {
            openModal();
        }, 100);
    };
    const onSubmitUpdate = useCallback((values: any, resetForm: any) => {
        console.log("Success! Data submitted.", TicketSources, values);
        let request: any = JSON.parse(JSON.stringify(filterd[0]));
        const sspSource = TicketSources.filter((x: any) => x?.name === "SSP")?.[0]
            ?.id;
        request.ticketLogs.note = values.description;
        request.ticketLogs.source = sspSource ? sspSource : 10;
        delete request.description;
        delete request.title;
        request.ticketLogs.ticketLogAttachmentsList = request.ticketLogs.ticketLogAttachmentsList?.map ?
            request.ticketLogs.ticketLogAttachmentsList.map((i: any) => {
                const tempObj = { attachmentId: i.id };
                return tempObj;
            }) : [];
        console.log("request123", request);
        ticketUpdate(request).then((res: any) => {
            console.log("ticketUpdate res", res);
            if (res.status == 201 || res.status == 200) {
                triggerAlert(`Ticket (${res?.data?.id}) Updated with Asset Successfully!`, "success");
                setTimeout(() => {
                    closeModal();
                    resetForm();
                }, 200);
            }
        });
    }, []);
    return (
        <>
            <div style={{ marginTop: "50px", marginLeft: "5px" }}>

                <Grid container spacing={3} className="view-ticket">
                    {/* <Grid item md={2}>

                    </Grid> */}
                    <Grid item xs={6} md={8}>
                        <Avatar
                            className="iconAvatar contact-popup-avatar"
                            alt={data.profilePictureId}
                            src={data.url}
                            onClick={() => { }}
                        />
                        <Grid container spacing={1} className="view-ticket-data-outline">
                            <Grid item xs={12} md={6} className="view-ticket-data">
                                <Typography ><span>Ticket Id :</span>{param.id}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography ><span>Title :</span>{filterd[0]?.ticketLogs?.title === '' || filterd[0]?.ticketLogs?.title === null ? '-' : filterd[0]?.ticketLogs?.title} </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} className="view-ticket-data">
                                <Typography ><span>Ticket Status :</span>{filterd[0]?.ticketLogs?.statusId === '' || filterd[0]?.ticketLogs?.statusId === null ? '-' : allStatusObj?.[`${filterd[0]?.ticketLogs?.statusId}`]} </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Typography ><span>Description :</span>
                                    {filterd[0]?.ticketLogs?.note === '' || filterd[0]?.ticketLogs?.note === null ? '-' : filterd[0]?.ticketLogs?.note}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} className="view-ticket-data">
                                <Typography ><span>Due date :</span> {
                                    filterd[0]?.ticketLogs?.dueDateTime === '' || filterd[0]?.ticketLogs?.dueDateTime === null ? '-' : moment(filterd[0]?.ticketLogs?.dueDateTime).format("L LT")
                                }</Typography>
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <Typography ><span>Priority :</span> {filterd[0]?.ticketLogs?.priorityId === '' || filterd[0]?.ticketLogs?.priorityId === null ? '-' : filterd[0]?.ticketLogs?.priorityId}</Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item md={4}>

                    </Grid>
                </Grid>
                <Box className="view-ticket-button"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                    }}
                >
                    {allStatusObj?.[`${filterd[0]?.ticketLogs?.statusId}`] !== "Closed" && (
                        <>
                            <Button
                                className={`${classes.btn} ${classes.btnCancel}`}
                                variant="outlined"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setDeleteModalStatus(true);
                                    setSelectedTicketObj(filterd[0]);
                                }}
                            >
                                Close Ticket
                            </Button>
                            <Button
                                className={`${classes.btn} ${classes.btnSubmit}`}
                                variant="contained"
                                onClick={() => {
                                    chooseService(filterd[0]?.id);
                                }}
                            >
                                Update
                            </Button>
                        </>
                    )}
                </Box>
            </div>
            {deleteModalStatus && (
                <TicketQuickView
                    ticketInfo={selectedTicketObj}
                    openStatus={deleteModalStatus}
                    onCloseModal={() => {
                        setDeleteModalStatus(false);
                    }}
                    onSubmitForm={closeTicket}
                />
            )}
            {open && (
                <CustomModalDialog
                    title=""
                    parentClasses="sml-popup"
                    id="raiseTicketModalSSP"
                    isOpen={open}
                    onClose={closeModal}
                    headerContent={
                        <>
                            <Typography variant="body2">{data?.title} {data?.id}</Typography>
                        </>
                    }
                    bodyContent={
                        <>
                            <Formik
                                initialValues={{ ...data }}
                                validationSchema={getValidationSchema()}
                                onSubmit={(values, { resetForm }) => {
                                    onSubmitUpdate(values, resetForm);
                                }}
                            >
                                {(props) => (
                                    <AddForm
                                        {...props}
                                        classes={classes}
                                        modalContent={modalContent}
                                        closeModal={closeModal}
                                    />
                                )}
                            </Formik>
                        </>
                    }
                />
            )}
        </>
    )
}
export const AddForm = (props: any) => {
    const {
        errors,
        touched,
        values,
        setFieldValue,
        modalContent,
        classes,
        submitForm,
        closeModal,
    } = props;
    const onChangeFunction = (fieldName: string, value: any) => {
        setFieldValue(fieldName, value);
    };
    useEffect(() => {
        console.log("props123", props);
    }, [props]);
    const imageUploadCB = useCallback((files: any = []) => {
        const tempObj = values?.ticketLogs;
        tempObj.ticketLogAttachmentsList = [
            ...tempObj.ticketLogAttachmentsList,
            ...files,
        ];
        console.log("files>>", values?.ticketLogs?.ticketLogAttachmentsList, files);
        onChangeFunction("ticketLogs", tempObj);
    }, []);
    const attachmentDeleteHandler: any = useCallback((id: number) => {
        const tempObj = values?.ticketLogs;
        tempObj.ticketLogAttachmentsList = [
            ...values?.ticketLogs?.ticketLogAttachmentsList.filter(
                (i: any) => i.id !== id
            ),
        ];
        console.log("files>>", values?.ticketLogs?.ticketLogAttachmentsList);
        onChangeFunction("ticketLogs", tempObj);
        //imageUploadCB(values?.ticketLogs?.ticketLogAttachmentsList.filter((i:any)=>i.id !== id));
    }, []);
    return (
        <>
            <CustomFormLabel
                labelName={"Please specify your update below:"}
                isMandotary={true}
            />
            <CustomInput
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={touched.description && Boolean(errors.description)}
                label={"Description"}
                name="desc"
                value={values.description}
                onChange={(e: any) => {
                    onChangeFunction("description", e.target.value);
                }}
                helperText={touched.description && errors.description}
                placeholder={"Please Enter"}
                classNames={"bigTextarea"}
            />
            <Box>
                <CommonAddAttachment callback={imageUploadCB} />
                
                {values?.ticketLogs?.ticketLogAttachmentsList && (
                    <Box className={`${classes.row} ${classes.rowWrap} `}>
                        {values?.ticketLogs?.ticketLogAttachmentsList.map(
                            (i: any, index: number) => {
                                return (
                                    <>
                                        {/* <img src={`${i.filePath}`}  height={50}/> */}
                                        <Box className="col-4 p-3p" key={index}>
                                            <AttachmentItem
                                                fileData={i}
                                                callback={attachmentDeleteHandler}
                                            />
                                        </Box>
                                    </>
                                );
                            }
                        )}
                    </Box>
                )}
            </Box>
            <Box className="text-center">
                <Button
                    className={`${classes.btn} ${classes.btnCancel}`}
                    variant="outlined"
                    onClick={closeModal}
                >
                    <CancelIcon style={{ marginRight: "5px" }} />
                    Cancel
                </Button>

                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Button
                    className={`${classes.btn} ${classes.btnSubmit}`}
                    variant="contained"
                    onClick={submitForm}
                >
                    Submit
                </Button>
            </Box>
        </>
    );
};