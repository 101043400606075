import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import { IState } from "../../../redux/reducers/rootReducers";
import CustomTheme from "../../common/CustomTheme";
import BasicButton from "../../common/button/BasicButton";
import { createStyles, makeStyles } from "@mui/styles";
import UserIcon from "../../../assest/icons/user.png";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import timeSpanToReadbleFormat from "../../../util/timeSpanToReadbleFormat";
import { TicketActions } from "./TicketActions";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import linkArrowoIcon from "../../../assest/linkArrowIcon.svg";
import { ConvertZ } from "../../helpers/helperFunctions";

const TicketCardNew = memo((props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isShow, setIsShow] = useState(false);
  const { ticketData, type, parentClassNames=""} = props;
  const {
    title,
    contactId,
    group = {},
    agent = {},
    secondaryContactId,
    ticketType = {},
    ticketStatus = {},
    priority = {},
    slaRemaining="",
    slaRemainingInTicks="",
    dueDateTimeFormatted,
    openDateTimeFormatted,
    firstResponseDueDateTimeFormatted,
  } = ticketData?.ticketLogs;
  const contactImg = useMemo(
    () => (
      <Avatar
        className="sml-avatar"
        sx={{ height: 25, width: 25 }}
        alt={ticketData?.ticketLogs?.contacts?.knownAs || "PP"}
        src={`${ticketData?.ticketLogs?.contacts?.profilePicturePath || ""}`}
      />
    ),
    [ticketData, classes]
  );
  // const contactImg = useMemo(() => <img src={ContactListImgs?.[`${ticketData?.ticketLogs?.contacts?.profilePictureId}`] ? ContactListImgs?.[`${ticketData?.ticketLogs?.contacts?.profilePictureId}`] : UserIcon} className="cursor-pointer" alt="UserIcon" height={25} width={25} onClick={onPopoverImgClick} />, [ticketData,classes])

  const popover = useMemo(() => {
    const contactInfo = ticketData?.ticketLogs?.contacts || {};
    return (
      <>
        {contactId > 0 && (
          <ContactPopover contactInfo={contactInfo} secContactInfo={{}} />
        )}
      </>
    );
  }, [ticketData, classes]);
  const layout = useMemo(() => {
    return (
      <>
        <Box className={`${classes.ThemeCard} ${parentClassNames}`}>
          <Box className="cardHeader">
            <Box
              className={`${classes.row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100}`}
            >
              <Box
                className={`${classes.row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100} agentsHeader`}
              >
                <Box className={classes.imgBox}>
                  <Box>
                    {contactImg}
                    {popover}
                  </Box>

                  <Box>
                    <Typography
                      variant="body2"
                      onClick={() => {
                        dispatch({
                          type: "TICKET_VIEW_INFO",
                          payload: {...ticketData,type:"quick-update"},
                        });
                      }}
                      className={`${classes.title} pointer`}
                    >
                      &nbsp; ID: {ticketData.id}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  className={`${classes.title} ${classes.ticketCardTags} resolved`}
                >
                  {ticketStatus?.displayName || "-"}
                </Typography>
              </Box>
              {/* <Box>
                            <Typography
                                variant="body2"
                                className={`${classes.title} ${classes.ticketCardTags} ${classes.tagBlue}`}
                            >
                                {ticketData?.sourceCode}
                            </Typography>
                        </Box> */}
              <Box>
                <Typography
                  variant="body2"
                  className={`${classes.title} ${classes.ticketCardTags} ${classes.tagWarn} ${classes.TicketTag}  make-ticketBox`}
                >
                  {ticketType?.displayName || "-"}
                </Typography>
              </Box>
              <Box>
                <Tooltip title={`Priority - ${priority?.name}`}>
                  <Box
                    sx={{ background: priority?.color || "#555" }}
                    className={classes.priorityDot}
                  ></Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box className="cardContent">
            <Box
              className={`${classes.row} ${classes.flxWrap} ${classes.twoCols} txt-ellipsis`}
            >
              <Tooltip
                title={
                  <span>
                    Subject - {title}
                    <br />
                    Description - {ticketData?.ticketLogs?.description}
                  </span>
                }
              >
                <Typography variant="body1">Subject : {title}</Typography>
              </Tooltip>
              <Tooltip title={`Group - ${group?.name || "-"}`}>
                <Typography variant="body1">
                  Group : {group?.name || "-"}
                </Typography>
              </Tooltip>
              <Tooltip
                title={`Primary Contact - ${ticketData?.ticketLogs?.contacts?.knownAs}`}
              >
                <Typography className="ticket-card-contact" variant="body1">
                  Primary Contact :{" "}
                  <b className={classes.primaryTxt}>
                    {ticketData?.ticketLogs?.contacts?.knownAs}
                  </b>
                </Typography>
              </Tooltip>
              <Tooltip title={`Owner - ${agent?.firstName || "-"}`}>
                <Typography variant="body1">
                  Owner :{" "}
                  <b className={`${classes.primaryTxt} ticket-priority-data`}>
                    {agent?.firstName || "-"}
                  </b>
                </Typography>
              </Tooltip>
              {ticketData?.ticketLogs?.secondaryContacts?.knownAs && (
                <Tooltip
                  title={`Secondary Contact - ${ticketData?.ticketLogs?.secondaryContacts?.knownAs}`}
                >
                  <Typography className="ticket-card-contact" variant="body1">
                    Secondary Contact :{" "}
                    <b className={classes.primaryTxt}>
                      {ticketData?.ticketLogs?.secondaryContacts?.knownAs ||""}
                    </b>
                  </Typography>
                </Tooltip>
              )}
              <Tooltip
                title={`Due Date - ${dueDateTimeFormatted ?dueDateTimeFormatted :"----"}`}
              >
                <Typography variant="body1">
                  Due Date :{" "}
                  <b className={`${classes.primaryTxt} ticket-priority-data`}>
                    {dueDateTimeFormatted ?dueDateTimeFormatted :"----"}{" "}
                  </b>
                </Typography>
              </Tooltip>
              <Tooltip
                title={`Open Date - ${openDateTimeFormatted ? openDateTimeFormatted :"----"}`}
              >
                <Typography variant="body1">
                  Open Date :{" "}
                  <b className={`${classes.primaryTxt} ticket-priority-data`}>
                    {openDateTimeFormatted ? openDateTimeFormatted :"----"}{" "}
                  </b>
                </Typography>
              </Tooltip>
              <Tooltip
                title={`First action - ${firstResponseDueDateTimeFormatted ? firstResponseDueDateTimeFormatted :"----"},
                 ${ticketData?.ticketLogs?.nextActionDescription}`}
              >
                <Typography className="ticket-card-contact" variant="body1">
                  First action Date:{" "}
                  <b className={classes.primaryTxt}>
                  {firstResponseDueDateTimeFormatted ? firstResponseDueDateTimeFormatted :"----"}
                  </b>
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          <Box className="cardFooter">
            <>
              <Box
                style={{
                  backgroundColor: type == "child" ? "none" : "none",
                }}
                className={`${classes.row} ${classes.footerBg} ${classes.flxWrap}  ${classes.justifyBetween} p-5p`}
              >
                <Box className={`${classes.row} ${classes.flxWrap} ${classes.footerRow}`}>
                  <Box
                    className={`${classes.row} ${classes.alignItemsCenter} sla-txt`}
                  >
                    <Typography variant="body1" className={classes.primaryTxt}>
                      SLA: &nbsp;
                    </Typography>
                    <Typography variant="body1">
                      <b
                        className={`${
                          timeSpanToReadbleFormat(slaRemaining, "ss").includes("-") 
                            ? classes.dangerTxt
                            : classes.successTxt
                          }`}
                      >
                        {ticketData.ticketLogs?.ticketStatus?.name === "OnHold"
                          ? "On-Hold"
                          : timeSpanToReadbleFormat(slaRemaining, "ss")}
                        &nbsp; &nbsp;
                      </b>
                    </Typography>
                  </Box>
                  <TicketActions
                    type={type}
                    // openView={openView}
                    //onEditClick = {onEditClick}
                    ticketData={ticketData}
                    setIsShow={setIsShow}
                    isShow={isShow}
                    parentId={props?.parentTicketId || 0}
                    // contactEmail={contactInfo?.email}
                    // handleClickMore={handleClickMore}
                    //onMergeClick={props.onMergeClick}
                    // actionInfo={actionInfo}
                    // isSuperAdmin={rolesAccess?.isSuperAdmin}
                    // onOpenWhatsAppModel={onOpenWhatsAppModel}
                    // contact={contactInfo}
                  />
                </Box>
              </Box>
            </>
          </Box>
        </Box>
        {isShow &&
          ticketData?.ticketRelationshipList?.map((item: any) => {
            return (
              <>
                <div className={"linkArrowWrapper"}>
                  <img className={"linkArrowIcon"} src={linkArrowoIcon} />
                </div>
                {/* <ArrowDownwardIcon /> */}
                {item?.childTicket && (
                  <div style={{ marginLeft: "20px" }}>
                    <TicketCardNew
                      ticketData={item.childTicket}
                      onMergeClick={props.onMergeClick}
                      type={"child"}
                      parentTicketId={item.ticketId}
                    />
                  </div>
                )}
              </>
            );
          })}
      </>
    );
  }, [ticketData, classes, isShow]);
  return <>{layout}</>;
});

export const ContactPopover = (props: any) => {
  const { contactInfo, secContactInfo } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const onPopoverImgClick = useCallback(() => {
    console.log("contactInfo", contactInfo);
    dispatch({
      type: "CONTACT_POPUP_INFO",
      payload: contactInfo,
    });
  }, [contactInfo,secContactInfo]);
  return (
    <>
      <Box className="popover-box text-left">
        <Box className={classes.popoverContact}>
          <Box>
            {/* <img src={imgUrl ? imgUrl : UserIcon} className="cursor-pointer" alt="UserIcon" height={35} width={35} /> */}
            <Avatar
              className="sml-avatar cursor-pointer"
              sx={{ height: 30, width: 30 }}
              alt={contactInfo?.knownAs || "PP"}
              src={`${contactInfo?.profilePicturePath || ""}`}
              onClick={() => {
                onPopoverImgClick();
              }}
            />
          </Box>
          <Box>
            <Typography variant="body2" className={classes.title}>
              {/* <span className={classes.linkTitle}>
                        ID : {contactInfo?.id}
                      </span> */}
              <span className="d-block">
                <span className={`${classes.btnTxt} contact-type`}>
                  Primary
                </span>
                {contactInfo?.knownAs}{" "}
              </span>
              <span className="d-block">{contactInfo?.email}</span>
              <span className="d-block">{contactInfo?.businessPhone}</span>
            </Typography>
            {secContactInfo?.id && (
              <>
                <hr />
                <Typography variant="body2" className={classes.title}>
                  {/* <span className={classes.linkTitle}>
                            ID : {contactInfo?.id}
                          </span> */}
                  <span className="d-block">
                    <span className={classes.btnTxt}>Secondary</span>
                    {secContactInfo?.knownAs}
                  </span>
                  <span className="d-block">{secContactInfo?.email}</span>
                  <span className="d-block">
                    {secContactInfo?.businessPhone}
                  </span>
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export const ContactPopup = memo((props: any) => {
  const {
    classes,
    contactInfo,
    contactImgUrl,
    contactByIdDetails,
    setShowPopup,
  } = props;
  return (
    <>
      <Box className={classes.popupHolder}>
        <Box className="modal-dialog">
          <Box className="modal-content">
            <Box className="modal-header">
              <Typography variant="h5" className="txtCapitalize">
                {contactInfo?.knownAs}
                <b>
                  {contactInfo?.isVIP ? (
                    <DiamondOutlinedIcon fontSize="small" color="primary" />
                  ) : (
                    <></>
                  )}
                  &nbsp;&nbsp;
                  {contactInfo?.alert && contactInfo.alert !== "" ? (
                    <ReportProblemOutlinedIcon
                      fontSize="small"
                      color="warning"
                    />
                  ) : (
                    <></>
                  )}
                </b>
              </Typography>
              <Typography
                className="cursor-pointer"
                onClick={() => {
                  setShowPopup(null);
                }}
              >
                <CloseIcon className="cursor-pointer" />
              </Typography>
            </Box>
            <Box className="modal-body">
              <Grid container spacing={3}>
                <Grid item xs={6} md={3}>
                  <div className="aside t-0">
                    <img
                      src={contactImgUrl ? contactImgUrl : UserIcon}
                      alt="UserIcon"
                      height={"200px"}
                      width={"100%"}
                      className="Contactprofile"
                    />
                    <hr />
                    <Typography className={classes.textWithSpanTitle}>
                      <span>Name :</span> {contactInfo?.name}
                    </Typography>
                    <Typography className={classes.textWithSpanTitle}>
                      <span>Organisation :</span>{" "}
                      {contactByIdDetails?.contactOrganisation?.name
                        ? contactByIdDetails?.contactOrganisation?.name
                        : "-"}
                    </Typography>
                    <Typography className={classes.textWithSpanTitle}>
                      <span>Division :</span>{" "}
                      {contactByIdDetails?.division?.name
                        ? contactByIdDetails?.division?.name
                        : "-"}
                    </Typography>
                    <Typography className={classes.textWithSpanTitle}>
                      <span>Department :</span>{" "}
                      {contactByIdDetails?.department?.name
                        ? contactByIdDetails?.department?.name
                        : "-"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} md={6} className="bl-1">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Base Location :</span>
                        {contactByIdDetails?.baseLocation?.name
                          ? contactByIdDetails?.baseLocation?.name
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Office Location :</span>
                        {contactByIdDetails?.officeLocation?.name
                          ? contactByIdDetails?.officeLocation?.name
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Contact Type :</span>
                        {contactInfo?.contactType
                          ? contactInfo?.contactType
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Email :</span> {contactInfo?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Manager :</span> {contactInfo?.managerId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Job Title :</span> {contactInfo?.jobTitle}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Mobile Phone :</span>{" "}
                        {contactInfo?.mobilePhone === "" ||
                        contactInfo?.mobilePhone === null
                          ? "-"
                          : contactInfo?.mobilePhone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Bussiness Phone :</span>
                        {contactInfo?.businessPhone === "" ||
                        contactInfo?.businessPhone === null
                          ? "-"
                          : contactInfo?.businessPhone}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>WhatsApp Number :</span>
                        {contactInfo?.whatsAppNumber === "" ||
                        contactInfo?.whatsAppNumber === null
                          ? "-"
                          : contactInfo?.whatsAppNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Teams Id :</span>
                        {contactInfo?.teamsId === "" ||
                        contactInfo?.teamsId === null
                          ? "-"
                          : contactInfo?.teamsId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Employee Hire Date :</span>
                        {contactInfo?.employeeHireDate === "" ||
                        contactInfo?.employeeHireDate === null
                          ? "-"
                          : contactInfo?.employeeHireDate}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>External Employee Id :</span>
                        {contactInfo?.externalEmployeeId === "" ||
                        contactInfo?.externalEmployeeId === null
                          ? "-"
                          : contactInfo?.externalEmployeeId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Age Group :</span>
                        {contactInfo?.ageGroup === "" ||
                        contactInfo?.ageGroup === null
                          ? "-"
                          : contactInfo?.ageGroup}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Consent Provided For Minor :</span>
                        {contactInfo?.consentProvidedForMinor === "" ||
                        contactInfo?.consentProvidedForMinor === null
                          ? "-"
                          : contactInfo?.consentProvidedForMinor}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Legal Age Group Classification :</span>
                        {contactInfo?.legalAgeGroupClassification === "" ||
                        contactInfo?.legalAgeGroupClassification === null
                          ? "-"
                          : contactInfo?.legalAgeGroupClassification}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Self Service Access :</span>{" "}
                        {contactInfo?.isSelfServiceAccess ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textWithSpanTitle}>
                        <span>Single Sign On Enabled :</span>{" "}
                        {contactInfo?.isSingleSignOnEnabled ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="bl-1">
                  <Typography className={classes.textWithSpanTitle}>
                    <span>
                      Assets ({contactByIdDetails?.assetList?.length})
                    </span>
                  </Typography>
                  {(!contactByIdDetails?.assetList ||
                    contactByIdDetails?.assetList?.length === 0) && (
                    <>No Assets found</>
                  )}
                  {contactByIdDetails?.assetList &&
                    contactByIdDetails?.assetList.map((ele: any, i: number) => (
                      <Box key={ele.id}>
                        <Typography
                          className={classes.txtWithLink}
                          gutterBottom
                        >
                          <span>
                            {i + 1}
                            {")"}&nbsp;{ele.assetTag}{" "}
                            {ele?.assetType?.name
                              ? ` - ${ele?.assetType?.name}`
                              : ""}{" "}
                          </span>
                          <Link to={`/ViewAsset/${ele.id}`}>
                            <OpenInNewIcon />
                          </Link>
                        </Typography>
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});

const data = CustomTheme();
const mode = data?.theme;

const useStyles = makeStyles(() =>
  createStyles({
    footerRow:{
      width: "100%",
      justifyContent: "space-between"
    },
    priorityDot: {
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      marginLeft: "10px",
    },
    viewDtls: {
      background: "#f1f1f1",
      margin: 0,
      marginLeft: "5px !important",
      padding: "3px 10px",
      lineHeight: 1,
      borderRadius: "15px",
      "& a": {
        display: "flex !important",
        alignItems: "center",
        gap: "5px",
        color: "#000",
      },
      "& span": {
        fontSize: "10px",
        display: "block",
      },
    },
    viewPopupTitle: {
      fontSize: 15,
      maxWidth: "255px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    cardTcktCount: {
      padding: "5px 10px",
      background: "#ffa000",
      display: "inline",
      lineHeight: "1",
      fontSize: "12px",
      position: "relative",
      "& .dots": {
        position: "absolute",
        width: "8px",
        height: "8px",
        background: mode.cardHeader,
        left: "-5px",
        borderRadius: "50%",
        top: "50%",
        transform: "translateY(-50%)",
      },
      "& .dots.dots-right": {
        left: "unset",
        right: "-5px",
      },
      "& .dots.whiteBG": {
        background: mode.whiteSolid,
      },
    },
    cardBadges: {
      background: data.mode === "light" ? "#ddd" : mode.cardHeader,
      display: "block",
      lineHeight: 1,
      fontSize: "9px !important",
      borderRadius: "9px",
      padding: "2px 5px",
      fontWeight: "500 !important",
      fontFamily: "'DM Sans', sans-serif  !important",
      cursor: "pointer",
    },
    primaryTxt: {
      color: data.mode === "light" ? "#1261b4" : mode.text,
    },
    warningTxt: {
      color: "#F39732 !important",
    },
    successTxt: {
      color: "#00b315",
    },
    dangerTxt: {
      color: "red",
    },
    footerBg: {
      background: data.mode === "light" ? `#eff0f5` : mode.solids,
    },
    twoCols: {
      "& > *": {
        maxWidth: "50% !important",
        flex: "0 0 50%",
        padding: "2px 5px",
      },
    },
    ThemeCard: {
      padding: "0",
      background: `${mode.cardBody}`,
      boxShadow: "1px 2px 2px rgb(4 24 87 / 22%) ",
      borderRadius: "4px ",
      // overflow: "hidden",
      marginBottom: "14px",
      "& .statusBox": {
        height: "25px",
        width: "40px",
      },
      "& .cardHeader": {
        background: mode.cardHeader,
        padding: "7px 13px",
        color: mode.text,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .userIcon": {
        padding: "0 0",
      },
      "& .cardContent": {
        padding: "14px 14px",
        paddingBottom: "4px",
        // minHeight:"50px",
        // borderBottom: '1px solid #ddd',
        "& td,th": {
          fontSize: "12px",
          padding: "4px",
          wordBreak: "break-all",
        },
        "& p": {
          fontSize: "10px",
        },
        "& .cardSubTitle": {
          fontSize: 13,
          margin: "0",
          minHeight: "40px",
        },
      },
      "& .cardFooter": {
        // padding: "5px",
        "&  img": {
          cursor: "pointer",
        },
        "& >div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          "& >*": {
            marginLeft: "10px",
          },
        },
        "& p": {
          fontSize: "12px",
          color: mode.text,
        },
      },
      "@media screen and (max-width:980px)": {
        margin: 0,
      },
    },
    flxWrap: {
      flexWrap: "wrap",
    },
    cardStyleBg: {
      height: "auto",
      // backgroundColor: "#ffe79c !important" ,
      "& .agentsHeader": {
        "& >div": {
          width: "25px",
          height: "25px",
          lineHeight: "25px !important",
          fontSize: "13px",
          marginRight: "10px",
        },
        "& > p": {
          fontSize: "13px",
        },
      },
    },
    tagBlue: {
      background: "#1261b4 !important",
      color: "#ffffff !important",
    },
    tagWarn: {
      background: "#f39732 !important",
      color: "#ffffff !important",
    },
    TicketTag: {
      "&::after": {
        background: mode.cardHeader,
      },
      "&::before": {
        background: mode.cardHeader,
      },
      "&.whitebck": {
        "&::after": {
          background: `#fff`,
        },
        "&::before": {
          background: `#fff`,
        },
      },
    },
    ticketCardTags: {
      display: "block",
      fontSize: "11px",
      padding: "5px 8px",
      background: mode.solids,
      whiteSpace: "nowrap",
      margin: "0 4px",
      color: mode.text,
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    w100: {
      width: "100% !important",
    },
    justifyBetween: {
      justifyContent: "space-between !important",
    },
    justifyCenter: {
      justifyContent: "center !important",
    },
    textWithSpanTitle: {
      lineHeight: "1.5",
      marginBottom: 10,
      fontSize: "13px",
      "& > span": {
        display: "block",
        fontSize: "11px",
        lineHeight: "1",
        color: mode.link,
      },
    },
    popupHolder: {
      position: "fixed",
      top: 0,
      left: 0,
      background: "#0005",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      zIndex: 1399,
      "& .modal-dialog": {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .modal-header": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        justifyContent: "space-between",
        borderBottom: "1px solid #ddd",
        paddingBottom: "10px",
      },
      "& .modal-body": {
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        padding: "0px",
      },
      "& .modal-body > div": {
        margin: "0 !important",
        maxWidth: "calc(100% - 15px)",
      },
      "& .modal-content": {
        background: "#fff",
        width: "100%",
        maxWidth: "1000px",
        borderRadius: "5px",
        //padding: "20px",
        "& img.Contactprofile": {
          width: "calc(100% - 10px)",
        },
        "& img": {
          objectFit: "cover",
        },
      },
    },
    row: {
      display: "flex",
    },
    tagTxt: {
      background: "#ddd",
      marginRight: "5px",
      width: "max-content",
      maxWidth: "100%",
      padding: "0px 6px",
      fontSize: "12px !important",
    },
    btnTxt: {
      background: "#ddd",
      marginRight: "5px",
      width: "max-content",
      maxWidth: "100%",
      padding: "0px 6px",
      borderRadius: "10px",
      fontSize: "7px !important",
    },
    imgBox: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      gap: "6px",
      "& img": {
        borderRadius: "50%",
        objectFit: "cover",
      },
      "& .popover-box": {
        position: "absolute",
        top: "100%",
        left: 0,
        background: "#fff",
        padding: "10px",
        zIndex: 1,
        boxShadow: "0 0 4px 0px #ddd",
        borderRadius: "5px",
        display: "none",
        minWidth:"200px"
      },
      "& div:hover .popover-box": {
        display: "block",
      },
    },
    title: {
      flexGrow: 1,
    },
    linkTitle: {
      color: "#2196f3 !important",
      wordBreak: "break-word",
    },
    popoverContact: {
      display: "flex",
      gap: "10px",
      "& p": {
        fontSize: 10,
        "& svg": {
          width: "0.60em",
          height: "0.60em",
        },
      },
      "&> p": {
        padding: "0 5px !important",
      },
      txtWithLink: {
        fontSize: ".7em",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        "& span": {
          wordBreak: "break-all",
        },
        "& a": {
          fontSize: ".7em",
          textDecoration: "none",
          display: "inline-block",
          lineHeight: 1,
          "& svg": {
            fontSize: "12px",
          },
        },
      },
    },
    txtWithLink: {
      fontSize: ".7em",
      display: "flex",
      alignItems: "center",
      gap: "5px",
      "& a": {
        fontSize: ".7em",
        textDecoration: "none",
        display: "inline-block",
        lineHeight: 1,
        "& svg": {
          fontSize: "12px",
        },
      },
    },
    btn: {
      margin: "10px",
      lineHeight: "24px",
      fontSize: "13px",
      fontWeight: 400,
      color: "#ffffff",
      padding: "5px",
      width: "100px",
      "@media screen and (max-width:980px)": {
        padding: "5px 9px",
        fontSize: "9px",
        width: "auto",
        lineHeight: "18px",
        minWidth: "auto",
        "& svg": {
          height: ".6em",
        },
      },
    },
    btnCancel: {
      color: "#6989fa",
      width: "110px",
      backgroundColor: "white",
      border: "2px solid #6989fa",
      marginRight: "10px",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    btnSubmit: {
      color: "#ffffff !important",
      minWidth: "110px !important",
      width: "auto",
      whiteSpace: "nowrap",
      // backgroundColor: "#1976d2 !important",
    },
  })
);
export default TicketCardNew;
