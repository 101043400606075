import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json"
import * as intl from "react-intl-universal";
export const url = `${configs.BASE_URL}/TicketType`;
const bulkUpdate = `${configs.BASE_URL}/TicketType/status/change?ids=`;
const deleteUrl = `${configs.BASE_URL}/TicketType/permanent/delete/`;
export const storeTicketTypeList = (val: any) => ({
  type: 'getTicket',
  payload: val,
});
export const storeAllTicketTypeList = (val: any) => ({
  type: 'getAllTicket',
  payload: val,
});
const getTicket = (callback?:any,conactUrl?:string) => {
  return async (dispatch: any) => {
    try {
      debugger
      const response = await axios(conactUrl ? `${configs.BASE_URL}/${conactUrl}` : url);
      dispatch(loading(false));
      if(response)
      console.log("response.data",response);
      dispatch(storeAllTicketTypeList(response.data));
      const activeTypes = response.data.filter((x:any)=>x.isActive)
      dispatch(storeTicketTypeList({data:activeTypes}));
      if(callback) callback(['0', activeTypes]);
    } catch (error:any) {
      if(callback) callback(['1', error.response])
      return console.log(error);
    }
  };
};


export const ticketTypeAdd = (data: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_TICKET_TYPE}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getTicket());
      callback(response);
    } catch (error: any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data,
            severity: "error",
            open: true,
          },
        });
      }
    }
  };
};
export const updateTicketTypeStatus = (data: any, id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_TICKET_TYPE}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      })
      dispatch(getTicket());
      callback(response);
    } catch (error: any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data,
            severity: "error",
            open: true,
          },
        });
      }
    }
  };
};
export const deleteTicketType = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}`);
      dispatch({
        type: "deleteTicketType",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
         // message: `${intl.get("Success.!.\n Ticket.Type.Updated")}`, add msg in constant file and add that msg here 
          severity: "success",
          open: true,
        },
      });
      dispatch(getTicket());
    } catch (error) {
      return console.log(error);
    }
  };
};


export const softDeleteTicketType = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkUpdate}${id}`);
      dispatch({
        type: "softDeleteTicketType",
        payload: response,
      });
      dispatch(getTicket());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_TICKET_TYPE}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const multipalUpdateTicketType = (data: any, ids: any,status:any) => {
  const idMsg = ids.length === undefined ? `${LanguageData.SUCCESS_TICKET_TYPE}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_TICKET_TYPE}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  const statusMsg = status ? `${LanguageData.SUCCESS_TICKET_TYPE}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_TICKET_TYPE}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return function (dispatch: any) {
    axios.delete(`${bulkUpdate}${ids}&status=${status}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdateTicketType",
      });
      dispatch(getTicket());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};

// export const singleTicket = (id: any) => {
//   return async (dispatch: any) => {
//     try {
//       const response = await axios.get(`${url}/${id}`);
//       dispatch({
//         type: "singleTicket",
//         payload: response,
//       });
//     } catch (error:any) {
//       if (error.response) {
//         dispatch({
//           type: ActionType.SNACKBAR,
//           payload: {
//             message: error.response.data,
//             severity: "error",
//             open: true,
//           },
//         });
//       }  
//     }
//   };
// };
export const singleTicket = (id:any="",callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(`${url}/${id}`);
      if(callback) callback(response.data)
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default getTicket;
