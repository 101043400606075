import React, { useEffect, useState,Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { IState } from "../redux/reducers/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import { PrivateAuth, PostLoginAdminAuth, SSPAuth, AdminPortalAuth, PostLoginLoader } from "./PrivateAuth";
import { useNavigate } from "react-router-dom";
import { getLoggedAgent } from "../redux/actions/userManagement/agents";
import { isLoginAction } from "../redux/actions/Login/Login";
import { ContactByTokenAction } from "../redux/actions/ContactManagement/Contact";
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
const AppRouter: React.FC = () => {
  const navigate = useNavigate();
  let logInfo: any = localStorage.getItem("loginInfo");

  const isUserLoggedIn = useSelector(
    (state: IState) => state?.LoginReducer?.isLoggedIn
  );
  const dispatch = useDispatch();
  const [isContactLoggedIn, setIsContactLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [firstUpdate, setfirstUpdate] = useState(false);
  useEffect(() => {
    const loginValues = localStorage.getItem("loginInfo");
    if (loginValues !== null) {
      dispatch(isLoginAction(true));
    }
  });
  useEffect(() => {
    let logInfo: any = localStorage.getItem("loginInfo");
    if (logInfo !== null) {
      //setCurrUser([logInfo.data]);
      const {token,isContact, isAdminLogin} = JSON.parse(logInfo);
      if(isContact){
        dispatch(ContactByTokenAction());
      }else{
        if(!isAdminLogin){
          dispatch(getLoggedAgent());
          // navigate("/home");
        }
        
      }
      
    } else {
     // navigate("/");
    }
  }, []);
  useEffect(()=>{

    if (isUserLoggedIn && logInfo !== null) {
      let {isContact} = JSON.parse(logInfo);
      let {isAdminLogin} = JSON.parse(logInfo);
      if(isAdminLogin && !isAdmin) setIsAdmin(true)
      if(isContact && !isContactLoggedIn) setIsContactLoggedIn(true)
      setfirstUpdate(true);
      console.log('isUserLoggedIn',isUserLoggedIn,isAdmin,isAdminLogin)
    }
    
    console.log('isUserLoggedIn',isUserLoggedIn,isAdmin)

  },[isUserLoggedIn])
  const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses?.isLoaded);
  const isRolesLoaded = useSelector((state: IState) => state?.LoginReducer?.roleAccess?.isLoaded);

  // useEffect(()=>{
  //   console.log("SuspenseSuspense",firstUpdate, isUserLoggedIn, isContactLoggedIn, isAdmin, AllData, isRolesLoaded)
  // },[firstUpdate, isUserLoggedIn, isContactLoggedIn, isAdmin, AllData, isRolesLoaded])
  return (
    <>
    <Suspense fallback={<>
      <Box className="page-loader">
        <CircularProgress />
      </Box>
    </>}>
      <Routes>
          {/* {PostLoginAdminAuth()} */}
          {(!isUserLoggedIn && !isContactLoggedIn) && PrivateAuth()}
          {(firstUpdate && isUserLoggedIn && !isContactLoggedIn && !isAdmin && AllData && isRolesLoaded) &&  PostLoginAdminAuth()}
          {(firstUpdate && isUserLoggedIn && !isContactLoggedIn && !isAdmin && (!AllData || !isRolesLoaded)) &&  PostLoginLoader()}
          {(firstUpdate && isUserLoggedIn && isContactLoggedIn && !isAdmin) &&  SSPAuth()}
          {(firstUpdate && isUserLoggedIn && isAdmin) &&  AdminPortalAuth()}
      </Routes>
    </Suspense>
    </>
  );
};

export default AppRouter;
