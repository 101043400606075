import { Box } from "@mui/material";
import { Suspense, lazy, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import DashboardSkeletonLoader from "./TicketDashboardWidgets/views/widgets/DashboardSkeletonLoader";
//import PageLayout from "./PageLayout";
// import CommonStyles from "../../common/CommonStyles";
// import TicketGroups from "./GroupsList";
// import DashboardMyTickets from "./myTickets";
// import TicketDashboardPriority from "./TicketDashboardPriority";
// import { ActionType } from "../../../redux/actions/Snackbars";
// import { useLocation, useNavigate } from "react-router-dom";
// import StatusDashboard from "./TicketStatusDashboard";
//import {getAllTickets} from "../../../redux/actions/TicketsManagement/TicketsManagement";//getAllDAshboardData,
// import TableExp from "./TableExp";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { getAgentPermission } from "../../navbar/sideNavbar/SidebarPermissions";
// import { getPermission } from "../../../redux/actions/SystemManagement/Permission";
// import { StoreAccessData } from "../../../redux/actions/Login/Login";
// import DashboardSkeletonLoader from "./TicketDashboardWidgets/views/widgets/DashboardSkeletonLoader";
// import TicketViewModal from "./TicketViewModalHolder";
// import ContactPopover from "./TicketDashboardWidgets/views/widgets/ContactPopover";
// import ContactView from "./TicketDashboardWidgets/views/widgets/ContactView";

const PageLayout = lazy(()=>import("./PageLayout"))
const CommonAppFilter = lazy(()=>import("../../navbar/sideNavbar/CommonAppFilter"))
//const TicketViewModal = lazy(()=>import("./TicketViewModalHolder"))

export const handleTime:any = { // code to read user's inputs for 1.5s on filter
  remind(apicall:any) {
      //console.log("doAPiCAll",apicall);
      apicall()
      this.timeoutID = undefined;
  },

  setup(action:any,interval:number=500) {
      if (typeof this.timeoutID === "number") {
          this.cancel();
      }
      this.timeoutID = setTimeout(
          (apiAction) => {
              this.remind(apiAction);
          },
          interval,
          action,
      );
  },

  cancel() {
      clearTimeout(this.timeoutID);
  },
};
const TicketDashboardHolder = memo(() =>{
  // const dispatch = useDispatch();
  // const FiltersVal: any = useSelector((state: IState) => state?.filtersTicketManagementReducer);
  //const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses);
  // const [firstload,setFirstLoad] = useState(true)
  // useEffect(()=>{
  //   console.log('do_api_call',AllData?.ticketStatus);
  //   setFirstLoad(false);
  //   if(!AllData?.ticketStatus || firstload) return;
  //   handleTime.cancel()
  //   handleTime.setup(()=>{dispatch(getAllTickets(FiltersVal,AllData))});
  // },[FiltersVal])
  
  return(
    <>
      <Suspense fallback={<DashboardSkeletonLoader/>}>
        <CommonAppFilter />
        <TicketDashboardHolder1 />
      </Suspense>
    </>
  )
})
const TicketDashboardHolder1 = memo(() =>{
  //const {AllData} = props;
  const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses);
  console.log('TicketDashboardHolder')
  // if(!AllData?.ticketStatus){
  //   return(<><DashboardSkeletonLoader/></>)
  // }
  return(
    <Box>
      {/* <Suspense fallback={<><DashboardSkeletonLoader/></>}> */}
      {/* <DashboardSkeletonLoader /> */}
      {/* {!AllData?.ticketStatus && <><DashboardSkeletonLoader /></>} */}
      <>
          {AllData?.ticketStatus && <PageLayout />}
      </>
      {/* <TicketViewModal /> */}
      {/* </Suspense> */}
    </Box>
  )
})
// const style = {
//   height: 30,
//   border: "1px solid green",
//   margin: 6,
//   padding: 8
// };
// export const LazyLoading = () => {
//   const [items,setItems] = useState(Array.from({ length: 20 }))
//   const [hasMore,setHasMore] = useState(true)

//   const fetchMoreData = () => {
//     if (items.length >= 100) {
//         setHasMore(false);
//       return;
//     }
//     // a fake async api call like which sends
//     // 20 more records in .5 secs
//     setTimeout(() => {
//         setItems(items.concat(Array.from({ length: 20 })));
//     }, 500);
//   };

//     return (
//       <Box>
//         <InfiniteScroll
//           dataLength={items.length}
//           next={fetchMoreData}
//           hasMore={hasMore}
//           loader={<h4>Loading...</h4>}
//         //   height={400}
//           endMessage={
//             <Typography style={{ textAlign: "center" }}>
//               <b>Yay! You have seen it all</b>
//             </Typography>
//           }
//         >
//           {items.map((i, index) => (
//             <Box style={style} key={index}>
//               Box - #{index}
//             </Box>
//           ))}
//         </InfiniteScroll>
//       </Box>
//     );
// }
export default TicketDashboardHolder;