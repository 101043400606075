import { connect, useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import { memo, useEffect, useState } from "react";
import SelectAllCategoriesOption from "./SelectAllCategories";
import CommonStyles from "../../../common/CommonStyles";
import { Accordion, Box, AccordionSummary, Typography, AccordionDetails, Button } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { storeCategoriesForRole } from "../../../../redux/actions/userManagement/role";
import { ChooseCategory } from "../../../workflowConfig/workflowModules/AddNewFilterValue";
const CategoryPart = memo((props:any) =>{
    const {allCategoryTree=[]} = props;
    const [isLoaded,setIsLoaded] = useState(false);
    useEffect(()=>{
        if(allCategoryTree?.length>0){
            setIsLoaded(true)   
        }
    },[allCategoryTree.length]);
    return <>
        {isLoaded &&<>
            
            <CategoryPart1 {...props} />
            </>
        }
    </>
    
})


const CategoryPart1 = memo(({checkedItems,allCategoryTree=[],checkedCategories=[]}:any) =>{
    console.log('CategoryPart1_CategoryPart1',checkedCategories)
    const [expanded,setExpand] = useState(false);
    const dispatch = useDispatch();
    const classes = CommonStyles();
    const [showCategory,setShowCategory] = useState(false);
    const [isFirstLoad,setIsFirstLoad] = useState(true);
    const [categories,setCategories] = useState([]);
    const [activeTicketTypes,setActiveTicketTypes] = useState<any>([]);
    const [selectedValue,setSelectedValue] = useState(checkedCategories);
    const toggleCollapse = () =>{
        setExpand(!expanded); 
    }
    const closeHandler = () =>{
        setShowCategory(false)
    }
    const ChooseCategoryCB = (ids:any) =>{
        console.log("ChooseCategoryCB",ids)
        setSelectedValue(ids)
    }
    useEffect(()=>{
        if(checkedItems){
            const keys = Object.keys(checkedItems);
            const selectedTicketTypes =  keys.filter((i:any)=>checkedItems[i].length > 0)
            console.log('checkedItems_checkedItems',selectedTicketTypes)
            if(!isFirstLoad && activeTicketTypes.length > selectedTicketTypes.length ){
                setSelectedValue([])
            }
            if(activeTicketTypes.length !== selectedTicketTypes.length ){
                setActiveTicketTypes(selectedTicketTypes)
            }
        }
    },[checkedItems])
    useEffect(()=>{
        if(isFirstLoad){
            setIsFirstLoad(false);
            return;
        } 
        console.log('activeTicketTypes',allCategoryTree,activeTicketTypes)

        if(allCategoryTree.length>0){
            setCategories(allCategoryTree.filter((i:any)=>{
               return i?.isActive && i?.ticketTypeId && activeTicketTypes.includes(`${i?.ticketTypeId}`)
            }))
        }
    },[activeTicketTypes.length])
    useEffect(()=>{
        console.log('CategoryPart123',selectedValue)
        dispatch(storeCategoriesForRole(selectedValue))
    },[selectedValue])
    return(<>
        {categories?.length > 0 && 
            <Accordion expanded={expanded} className="accordionDtls">
                <AccordionSummary aria-controls={`roles-category-content`} id={`roles-category-header`} className='accrodTitle'>
                    <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box>
                            <Typography>Categories</Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                            {/* <SelectAll categories={categories} selectedValue={selectedValue} callback={ChooseCategoryCB}/> */}
                            <SelectAllCategoriesOption categories={categories} selectedValue={selectedValue} callback={ChooseCategoryCB} />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {expanded ?
                                <KeyboardArrowUpOutlinedIcon className="pointer" onClick={toggleCollapse} /> :
                                <KeyboardArrowDownOutlinedIcon className="pointer" onClick={toggleCollapse} /> 
                            }
                        </Box>
                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    <Button className="choose-Category-Btn" onClick={()=>{setShowCategory(true)}}>Select Category ({selectedValue?.length})</Button>
                    
                </AccordionDetails>
            </Accordion>
        }
            {showCategory &&
                <ChooseCategory closeHandler={closeHandler} allCategoryTree={categories} callback={ChooseCategoryCB} defaultValue={selectedValue} />
            }
    </>)
})


const mapStateToProps = (state:IState) => {
    return ({
        checkedItems : state?.roleReducer?.agentCheckBoxStatus?.TicketTypes,
        checkedCategories : state?.roleReducer?.agentCheckBoxStatus?.Categories,
        allCategoryTree : state?.categoryReducer?.allCategoryTree
    })
};
export default connect(mapStateToProps)(CategoryPart);//AgentCheckBoxList;//