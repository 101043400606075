import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@mui/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { Drawer, Typography } from '@mui/material';
import { createTheme } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
const theme = createTheme();

const useStyles = makeStyles(() =>
    createStyles({
        drawer: {
            width: 350
        },
        drawerMain: {
            padding: 10,
        },
        drawerheader: {
            height: 50,
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid',
            justifyContent:"space-between"
        },
        typographyAddNew: {
            marginLeft: 3,
            color: theme.palette.text.primary
        },
    }),
);

export interface ISearch {
    onClose(event: React.KeyboardEvent | React.MouseEvent): void,
    drawerState: boolean,
    children?: React.ReactNode,
    title: string,
    anchor?: "bottom" | "left" | "right" | "top" | undefined
}

const SideDrawer: React.FC<ISearch> = (props: ISearch) => {
    const classes = useStyles();
    const { onClose,drawerState, title ,anchor="right",children } = props;

    const toggleDrawer =
    () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            onClose(event)
        };
    return (
        <Drawer
            className='location-type-drawer'
            anchor={anchor}
            open={drawerState}
            onClose={toggleDrawer()}
            classes={{
                paperAnchorRight: classes.drawer
            }}
        >
            <div className={`${classes.drawerMain} assest-status-drawer`}>
                <div className={`${classes.drawerheader} assest-drawer`}>
                    <Typography className={classes.typographyAddNew} variant="h6">
                        {title}
                    </Typography>
                    <CloseIcon className="cursor-pointer" onClick={(e)=>onClose(e)} />
                </div>
                {children}
            </div>
        </Drawer>
    )
}

export default SideDrawer;