import {
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import MenuIcon from "@mui/icons-material/Menu";
import intl from "react-intl-universal";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../redux/actions/Theme/Theme";
import { IState } from "../../redux/reducers/rootReducers";
import CommonStyles from "./../common/CommonStyles";
import darkmodeImg from "./../../assest/icons/menu/darkmode.svg";
import printer from "./../../assest/icons/menu/print.svg";
import cog from "./../../assest/icons/menu/settings.svg";
import preferencesIcon from "./../../assest/preferences.svg";
import helpIcon from "./../../assest/icons/menu/how.svg";
import flagIcon from "./../../assest/icons/menu/flag.svg";
import ticketsIcon from "./../../assest/icons/menu/ticket.svg";
import ChatIcon from "./../../assest/icons/menu/chat.svg";
import { getOrganisationWithToken } from "../../redux/actions/FreeTrial/FreeTrial";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import ForwardSharpIcon from "@mui/icons-material/ForwardSharp";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import EmergencyRecordingOutlinedIcon from "@mui/icons-material/EmergencyRecordingOutlined";
import { DoLogOutAction } from "../../redux/actions/Login/Login";
import profile from "./../../assest/profile.png";
import * as locales from "@mui/material/locale";
import moment from "moment";
import LogoImg from "./../../assest/logo.png";
import { storeAllResponses } from "../../redux/actions/TicketsManagement/TicketsManagement";
import SideDrawer from "../common/sideDrawer";
import ConfirmationNumberOutlined from "@mui/icons-material/ConfirmationNumberOutlined";
import ApprovalIcon from "@mui/icons-material/Approval";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { getProfileImage } from "../../redux/actions/userManagement/agents";
import { useMsal } from "@azure/msal-react";
console.log("localeslocales", locales);
type SupportedLocales = keyof typeof locales;
export interface ITopNavbar {
  classNames?: string;
}
function isActive(pathname: string) {
  return false;
}
const TopNavbarSSP: React.FC<ITopNavbar> = (props: ITopNavbar) => {
  const { classNames = "" } = props;
  const navigate = useNavigate();
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const useStyles = CommonStyles;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loc, setLoc] = useState([] as any);
  const [OrgTitle, setOrgTitle] = useState<string | null>("");
  const [drawerState, setDrawerState] = useState(false);
  const profileImg = useSelector(
    (state: IState) => state?.agentReducer?.saveProfile
  );
  const currOrganisation = useSelector(
    (state: IState) => state?.FreeTrialReducer?.getLoggedOrganisation
  );
  const currContact = useSelector(
    (state: IState) => state?.ContactsReducer?.loggedContact
  );
  useEffect(() => {
    let currcontactInfo = { ...currContact };
    // console.log("currcontactInfocurrcontactInfo", currcontactInfo);
    if (!profileImg && currContact.profilePictureId) {
      dispatch(getProfileImage("profile", currcontactInfo.profilePictureId));
    }
  }, [profileImg, currContact.profilePictureId]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const temp = Object.keys(locales);
    temp.unshift("enGb");
    setLoc(temp);
  }, [locales]);

  useEffect(() => {
    dispatch(getOrganisationWithToken(true));
    const orgName = sessionStorage.getItem("tenantCode");
    setOrgTitle(orgName ? orgName : "");
  }, []);
  const { instance } = useMsal();
  function LogOut() {
    if (instance.logout) instance.logout();
    dispatch(theme(false));
    localStorage.clear();
    dispatch(DoLogOutAction());
    dispatch(storeAllResponses({}));
    navigate("/");
  }
  function handleClose() {
    setAnchorEl(null);
  }
  const toggleDarkTheme = () => {
    dispatch(theme(!toggleTheme));
    // document.body.classList.toggle('dark-mode')
    //ThemeStatus()
  };
  const handleMenu = () => {
    setDrawerState(true);
  };
  const OnCloseDrawer = () => {
    setDrawerState(false);
  };
  const [ChatDrawerstate, setChatDrawerstate] = useState<boolean>(false);
  const Filterdrawer = () => {
    setChatDrawerstate(true);
  };
  const OnCloseChatDrawer = () => {
    setChatDrawerstate(false);
  };

  return (
    <>
      <div className={`${classes.topBar} ${classNames}`}>
        <Toolbar className="d-flx">
          <div>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  className="pageTitle"
                  style={{ fontWeight: 600 }}
                >
                  {/* {intl.get("header.main.title")} */}
                  {/* {currOrganisation.name} */}
                  <span
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    {/* {OrgTitle} */}
                    {currOrganisation.name}
                  </span>
                  <div className="btnGrp">
                    <IconButton
                      aria-label="Notification"
                      aria-controls="notification"
                      aria-haspopup="true"
                      // onClick={handleMenu}
                      color="inherit"
                    >
                      <InfoIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Notification"
                      aria-controls="notification"
                      aria-haspopup="true"
                      //onClick={handleMenu}
                      color="inherit"
                    >
                      <StarBorderIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Menu"
                      aria-controls="menu"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                      className={"md-none"}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                </Typography>
              }
              secondary={"Self Service Portal"}
            />
          </div>
          <div className="sm-hide">
            <Link to={`/MyProfileSSP`}>
              <IconButton color="inherit">
                {!profileImg && profileImg === "" ? (
                  <img src={profile} className="proImg" alt="" height={80} />
                ) : (
                  <img
                    src={`${profileImg}`}
                    alt=""
                    className="proImg"
                    height={80}
                  />
                )}
              </IconButton>
            </Link>
            <IconButton onClick={toggleDarkTheme} color="inherit">
              <img src={darkmodeImg} alt="" />
            </IconButton>
            {/* <Link to={`/Preferences`}>
              <IconButton color="inherit">
                <img src={preferencesIcon} height={20} alt="" />
              </IconButton>
            </Link>
            <Link to={`/organisationSettings`}>
              <IconButton color="inherit">
                <img src={cog} alt="" />
              </IconButton>
            </Link>
            <IconButton color="inherit">
              <img src={helpIcon} alt="" />
            </IconButton> */}

            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event: any) => {
                setAnchorEl(event.currentTarget);
              }}
              color="inherit"
            >
              <img className="flagImg" src={flagIcon} alt="" />
            </IconButton>
            <Menu
              id="basic-menu"
              sx={{ height: 300 }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {loc?.map((item: any) => {
                return (
                  <MenuItem
                    onClick={(ele: any) => {
                      let temp = `${item.substring(0, 2)}-${item.substring(
                        2,
                        4
                      )}`;
                      console.log("clicked lang", temp);
                      localStorage.setItem("lang", temp);
                      moment.locale(temp);
                      handleClose();
                    }}
                  >
                    {item.substring(0, 2)}-{item.substring(2, 4)}
                  </MenuItem>
                );
              })}
            </Menu>
            {/* <Link to={`/MyTaskApproval`}>
            <IconButton color="inherit" className="notificationIcon">
              <img src={ticketsIcon} alt="" />
              <span className="count">0</span>
            </IconButton>
            </Link> */}
{/* 
            <IconButton
              className="logoutBtn"
              color="inherit"
              onClick={Filterdrawer}
            >
              <ChatOutlinedIcon />
            </IconButton> */}

            <IconButton className="logoutBtn" color="inherit" onClick={LogOut}>
              <LogoutOutlinedIcon />
            </IconButton>
          </div>
        </Toolbar>
      </div>

      <SideDrawer
        onClose={OnCloseChatDrawer}
        drawerState={ChatDrawerstate}
        anchor={"bottom"}
        title="Chat"
      >
        <Box style={{ marginTop: "5px" }}>
          <Box className="modal-header-chatbox chatbox">
            <h5>Contact Us</h5>
            <p>Chat with Virutual Assistant</p>
          </Box>

          <Box className="modal-body-chatbox chat-box">
            <Box className="p-1">
              <Box>
                <p>
                  Call the Service Desk for help with your IT requests,
                  questions, and problems.
                </p>
              </Box>

              <Box className="chatOptions">
                <a href="">
                  <img
                    src="/static/media/email-icon.a7b0ee852825bc22aa5df8a41345fd2e.svg"
                    alt=""
                  />{" "}
                  Email: helpdesk@simplisys.co.uk
                </a>
                <a href="">
                  <img
                    src="/static/media/teams.f5b7b9184539963eaa17466828156163.svg"
                    alt=""
                  />{" "}
                  Teams call
                </a>
                <a href="">
                  <ScreenShareOutlinedIcon /> Screen share with us
                </a>
                <a href="">
                  <EmergencyRecordingOutlinedIcon /> Record the issue and send
                </a>
                <a href="">
                  <img
                    src="/static/media/whatsapp.6660427c746df6f43dc90f0619c76e28.svg"
                    alt=""
                  />{" "}
                  WhatsApp us
                </a>
                <a href="">
                  <img
                    src="/static/media/Phone-icon.488c0af2e0f6acc94134ef4688fc8ad0.svg"
                    alt=""
                  />{" "}
                  Call us:800-123-45678
                </a>
              </Box>
            </Box>

            <Box
              className="bg-white chat-box-teaxtarea"
              data-dismiss="modal"
              aria-label="Close"
            >
              <input type="text" placeholder="Type your message" />
              <AttachmentOutlinedIcon />
              <ForwardSharpIcon />
            </Box>
          </Box>
        </Box>
      </SideDrawer>
      
      <SideDrawer
        onClose={OnCloseDrawer}
        drawerState={drawerState}
        title={"Menu"}
      >
        <>
          <List>
            <Link onClick={OnCloseDrawer} to="/" className={`icon-sidemenu`}>
              <ListItem>
                <ListItemIcon id="homeIcon">
                  <HomeOutlinedIcon />
                  <span> Home</span>
                </ListItemIcon>
              </ListItem>
            </Link>
            <Link
              onClick={OnCloseDrawer}
              to="/addNew"
              className={`icon-sidemenu`}
            >
              <ListItem>
                <ListItemIcon id="newTicIcon">
                  <ConfirmationNumberOutlined />
                  <span> New Ticket</span>
                </ListItemIcon>
              </ListItem>
            </Link>
            <Link
              onClick={OnCloseDrawer}
              to="/myApproval"
              className={`icon-sidemenu`}
            >
              <ListItem>
                <ListItemIcon id="newTicIcon">
                  <ApprovalIcon />
                  <span> My Approval</span>
                </ListItemIcon>
              </ListItem>
            </Link>
          </List>
          <Divider />
          <Box className={`md-none ${classes.sideLogoutBtn}`} onClick={LogOut}>
            <LogoutOutlinedIcon />
            <span> logout</span>
          </Box>
        </>
      </SideDrawer>
    </>
  );
};

export default TopNavbarSSP;
