import React, { useEffect, useState } from "react";
import "./App.css";
import MainPage from "./util/MainPage";
import { loadLocales } from "./util/locale";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import "moment/min/locales";
import { useDispatch } from "react-redux";
import { theme } from "./redux/actions/Theme/Theme";
import { messaging, onMessage, requestPermission } from "./firebase";
import { ActionType } from "./redux/actions/dashboard";
const packageData = require("../package.json");
console.log(
  `%cversion------${packageData.version}`,
  "color: green; font-size: 50px;"
);

const App: React.FC = () => {
  const [initIntl, setInitIntl] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const initLocale = await loadLocales();
      setInitIntl(initLocale);
    }
    fetchData();
    const isDarkmode: any = localStorage.getItem("darkTheme");
    if (isDarkmode) {
      dispatch(theme(true));
    }
    // Handle incoming messages
    onMessage(messaging, (payload: any) => {
      console.log("Message received", payload);
      // Handle notification or data payload as needed
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: payload?.data?.title,
          severity: "success",
          open: true,
        },
      });
    });
  }, []);

  return initIntl ? <MainPage /> : <></>;
};

export default App;
