import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { createTheme } from "@mui/material/styles";
import { makeStyles, styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import grup from "../../assest/icons/images/groups.svg";
import { IState } from "../../redux/reducers/rootReducers";
import AvatarText from "../common/avatarWithText";
import CustomTheme from "./../common/CustomTheme";

const theme = createTheme();
const data = CustomTheme();
const Item = styled(Paper)(() => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "red",
}));

const KnowledgeManagement: React.FC = () => {
  const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
  const [mode, setMode] = useState({} as any);
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const [modulesList,setModulesList] = useState<any>([]);
  useEffect(() => {
    if (toggleTheme) {
      setMode(data.all.dark);
    } else {
      setMode(data.all.light);
    }
  }, [toggleTheme]);
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
    },
    box: {
      backgroundColor: mode.solids,
      padding: "20px",
      "& .thumb": {
        lineHeight: "1",
      },
    },
    typography: {
      marginLeft: 10,
    },
    title: {
      fontSize: "24px",
      lineHeight: "29px",
      color: mode.text,
      marginBottom: "20px",
      "@media screen and (max-width : 980px)": {
        fontSize: "15px",
        marginBottom: "5px",
      },
    },
  }));
  const classes = useStyles();
  
  useEffect(()=>{
    console.log('rolesAccess',rolesAccess)
    let SysFields = [];
    if(!rolesAccess){
      return;
    }
    if(rolesAccess?.isSuperAdmin ||  rolesAccess?.Article?.AdminAccess){
      SysFields.push({
        avatarText: "Articles",
        avatarURL: grup,
        avatarAlt: "Articles",
        redirectLink: "/ArticlesList",
      });
    };

    if(rolesAccess?.isSuperAdmin ||  rolesAccess?.ArticleType?.AdminAccess){
      SysFields.push({
        avatarText: "Articles Type",
        avatarURL: grup,
        avatarAlt: "Articles Type",
        redirectLink: "/ArticleType",
      });
    };

    if(rolesAccess?.isSuperAdmin ||  (rolesAccess?.Article?.AdminAccess && rolesAccess?.Roles?.Edit)){
      SysFields.push({
        avatarText: "Articles & Permissions",
        avatarURL: grup,
        avatarAlt: "Articles & Permissions",
        redirectLink: "/ArticlesAndPermissions",
      });
    };
    setModulesList([...SysFields]);
  },[rolesAccess])
  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.box}>
        <Typography className={classes.title}>Knowledge Base Management</Typography>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {modulesList.map((mgt:any, index:number) => {
            return (
              <Grid item xs={6} md={4} key={index} className="thumbs">
                <Item style={{ backgroundColor: `${mode.body}` }}>
                  <AvatarText
                    avatarText={mgt.avatarText}
                    avatarURL={mgt.avatarURL}
                    avatarAlt={mgt.avatarAlt}
                    redirectLink={mgt.redirectLink}
                    isExtLink={false}
                  />
                </Item>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}; 

export default KnowledgeManagement;