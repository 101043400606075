import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getWallboardByCode, getWallboardById } from "../../../redux/actions/Wallboard/Wallboard";
import { Box, Grid, Typography } from "@mui/material";
import Breadcrumbs from "../../common2/Breadcrumbs";
import VerticalChart from "../../charts/horizontal-chart/VerticalChart";
import HorizontalChart from "../../charts/horizontal-chart/HorizontalChart";
import LineChart from "../../charts/line/LineChart";
import PieChart from "../../charts/pie/PieChart";
import DoughnutChart from "../../charts/dougnut/DoughnutChart";
import { IPieData } from "./InterfaceWallBoard";
import { alertMessage } from "../../../redux/actions/SystemIcons/systemIcons";
import { getTenantCodeBySubDomain } from "../../../redux/actions/Login/Login";

const ViewChart = () => {
    const param = useParams();
    const dispatch = useDispatch();
    const [viewVal, setViewVal] = useState<any>(null);
    const [viewValCode, setViewValCode] = useState<any>(null);
    const [wallBoardTypeList, setWallBoardTypeList] = useState<any[]>([]);
    const [chartsInitialized, setChartsInitialized] = useState<boolean>(false);
    const [externalWebLink, setExternalWebLink] = useState('');
    const [tenantCode, setTenantCode] = useState("vizionsys")
    const CurrLocation = window.location.origin
    const subDomain = window.location.host.split('.')?.[0];

    const priorityName: any = {
        "P1": 409,
        "P2": 88,
        "P3": 60,
        "P4": 9,
        "Low": 23,
        "Medium": 16,
        "Urgent": 20,
        "Default": 2,
        "Emergency": 1,
        "P": 0,
        "highest": 0,
        "demo": 0,
        "sd": 0,
        "middle range": 0,
        "Powerful": 0,
        "Medium range": 0,
        "low": 0,
        "high": 0,
        "p5": 0,
        "p2": 0,
        "Urgent Priority": 0,
        "w": 0,
        "new ": 0,
        "testing": 0,
        "first": 0,
        "testing Foreign key": 9,
        "toast": 0,
        "test": 0,
        "rubesh": 0,
    };

    const AgentData: any = {
        "Rubesh Kumar": 0,
        "Akshay Kulkarni": 3,
        "Krishna Kulkarni": 0,
        "Shamanth S": 1,
        "Amith b Patil": 351,
        "Girish giri Kulkarni": 14,
        "Augustine  Kudiyirippil": 69,
        "Girish Kulkarnii": 2,
        "Jennifer Aniston": 4,
        "Peter Lench": 64,
        "ramesh Hill": 0,
        "Jon Peterson": 0,
        "Girish Kulkarni": 0,
        "Rahul patil": 2,
        "Harsha Patel": 0,
        "Agent ash": 6,
        "Shamanth JM": 10,
        "Varun Kulkarni": 0,
        "testing test": 1,
        "Structure PvtLtd": 0,
        "Ashwini Ash": 28,
        "Ragul Lingan": 1,
        "Pramod Kulakarni": 0,
        "aaaaaa ": 0,
        "Asss Asss": 0,
        "ygyugyghgh hhh": 0,
        "ascs ": 0,
        "Amresh patil": 0,
        "ashwini ": 0,
        "Gaurav Kumar": 1,
        "Harish Kulkarni": 0,
        "Harshi Patel": 0,
        "new agent asd": 0,
        "tiger ssss": 0,
        "Girish Patel": 0,
        "Pramod Kulkarni": 0,
        "shams ssss": 0,
        "ASWWdd2d4455 aAAA": 0,
        "aSSa A": 0,
        "Shamsudheen Asdf": 0,
        "Ashwini Backend": 0,
        "Shamanth Frontend rest": 0,
        "ASS ASS": 0,
        "Harsha  p": 0,
        "Rishi Patel": 0,
        "Soundarya Patil": 0
    }

    const ticketStatus: any = {
        "New": 36,
        "New to Me": 159,
        "In Progress": 312,
        "On Hold": 12,
        "Pending": 256,
        "Resolved": 16,
        "Closed": 51,
        "Rejected": 1,
        "Delete": 2,
        "Pending Authorisation": 31,
        "Approved": 0,
        "Not applicable": 0,
        "TT": 0,
        "Open": 1,
        "Pending Close": 0,
        "custom status": 0,
        "waiting": 2,
        "cancel": 0,
        "Third Party dummy": 10,
        "Third Party": 14,
        "pending treat": 1,
        "Merge": 0,
        "ashwini": 0,
        "toast": 0,
        "test toast": 0,
        "kumar": 0,
        "aaaaaaa": 0,
    };

    const groupData: any = {
        "1st Line": 1,
        "2nd Line": 2,
        "3rd Line": 3,
        "Senior Team": 4,
        "Test Line": 5,
        "New_Gr": 6,
        "SAR": 5,
        "New": 4,
        "Data Breeches": 9,
        "Management": 10,
        "Office": 12,
        "tets": 5,
        "aaaaaaaaaa": 7,
        "test": 3,
        "testing ": 1,
        "15 th line": 0,
        "Management > Office": 0,
        "Management - Store": 0,
        "New group - 1st line": 0,
        "test one": 0,
        "Groups Foreign key ": 0,
        "Toast": 0,
        "Seniors Batch": 0,
        "An error occurred whil": 12,
        "An error occurred while ": 0,
        "Proceed for Disabled": 0
    }

    const ticketTypes: any = {
        "Problem": 36,
        "Event": 81,
        "Project": 65,
        "Case": 80,
        "Incident": 66,
        "Service Request": 199,
        "Changes": 23,
        "Ac milan": 0,
        "New ticket type": 0,
        "new ticket type": 0,
        "Deleting Issue": 0,
        "ticket type updated": 0,
        "Ticket type q": 0,
        "ticket type new": 0,
        "rubesh": 0,
        "name": 0,
        "test type": 0,
        "ticket type": 0,
        "h": 0,
        "testing t type": 0,
        "type of ticket ": 0,
        "Agustine f": 0,
        "testing one": 9,
        "Toast message toast": 0,
        "Toast message": 0,
        "testing three": 0,
        "testing four": 0,
        "testing two": 0,
        "test toast": 0,
        "dd Ticket Type": 0,
        "test toast one": 0,
        "New ContactType": 0,
        "aaaaaaa": 0,
        "qqqqqq": 0,
        "rrrrrrrr": 0,
        "new": 0,
        "old ": 0,
        "kumars": 0,
        "kumar": 0
    }

    useEffect(() => {
        if (param?.code && tenantCode) {

            dispatch(getWallboardByCode(param.code, getWallboardByCodeCB, tenantCode))
        }
        if (param?.id) {
            dispatch(getWallboardById(param.id, getWallboardByIdCB))
        }
    }, [param?.id, tenantCode])
    useEffect(() => {
        if (param?.code) {
            console.log("subDomain",subDomain);
            
            if (subDomain && subDomain !== 'portal' && !subDomain.includes('localhost')) {
                dispatch(getTenantCodeBySubDomain(`${subDomain}`, TenantCodeCB));
            }
        }
    }, [param?.code])

    const TenantCodeCB =(resStatus: string, res: any)=>{
        if (resStatus === "0") {
            setTenantCode(res.tenantcode);
        }
    }
    const getWallboardByIdCB = (resStatus: string, res: any) => {
        console.log("getWallboardByIdCB", res, resStatus);
        if (resStatus === "0") {
            setViewVal(res);
        }
    };
    const getWallboardByCodeCB = (resStatus: string, res: any) => {
        console.log("getWallboardByIdCB", res, resStatus);
        if (resStatus === "0") {
            setViewValCode(res);
        }
    };

    useEffect(() => {

        if (viewVal?.wallBoardTypeList && !chartsInitialized) {
            const newWallBoardTypeList = viewVal.wallBoardTypeList.map((i: any) => {
                const { chartType, data } = i;
                if (chartType && data.datasets[0].label) {
                    let newData;
                    switch (data.datasets[0].label) {
                        case "Group":
                            newData = groupData;
                            break;
                        case "Priority":
                            newData = priorityName;
                            break;
                        case "TicketStatus":
                            newData = ticketStatus;
                            break;
                        case "Agent":
                            newData = AgentData;
                            break;
                        case "TicketType":
                            newData = ticketTypes;
                            break;
                        default:
                            newData = {};
                    }

                    const chart: any = renderChart(chartType, newData, data.datasets[0].label);
                    return { chartType: chartType, data: chart, isActive: true };
                }
                return null;
            })
            setWallBoardTypeList(newWallBoardTypeList);
            setChartsInitialized(true);
            setExternalWebLink(`${CurrLocation}/#/ViewChart/${viewVal.code}`)
        }
    }, [viewVal, chartsInitialized]);

    const extractData = (dataObject: any, value: any) => {
        if (dataObject && dataObject[value]) {
            return [dataObject[value].dataList, dataObject[value].count] || [];
        }
        return [];
    };
    useEffect(() => {
        if (viewValCode?.wallBoardTypeList && !chartsInitialized) {
            const newWallBoardTypeList = viewValCode.wallBoardTypeList.map((i: any) => {
                const { chartType, value } = i;
                if (chartType && value) {
                    const newData = extractData(i.wallboardData, value)

                    const chart = renderChart(chartType, newData[0], value);
                    return { chartType: chartType, data: chart, count: newData[1], isActive: true };
                }
                return null;
            })

            setWallBoardTypeList(newWallBoardTypeList);
            setChartsInitialized(true);
        }
    }, [viewValCode, chartsInitialized]);

    const renderChart = (type: any, data: any, property: string = "") => {
        const labels = Object.keys(data);
        const values = Object.values(data);
        const chartData: IPieData = {
            labels: labels,
            chartType: type,
            datasets: [
                {
                    label: property,
                    data: values,
                    backgroundColor: [
                        "rgba(75,192,192,0.2)",
                        "rgba(153,102,255,0.2)",
                        "rgba(255,159,64,0.2)",
                        "rgba(255,99,132,0.2)",
                        "rgba(54,162,235,0.2)",
                        "rgba(255,206,86,0.2)",
                    ],
                    borderColor: [
                        "rgba(75,192,192,1)",
                        "rgba(153,102,255,1)",
                        "rgba(255,159,64,1)",
                        "rgba(255,99,132,1)",
                        "rgba(54,162,235,1)",
                        "rgba(255,206,86,1)",
                    ],
                    borderWidth: 1,
                },
            ],
        };
        return chartData;
    };

    const ChartItem = useMemo(() => {
        return wallBoardTypeList.slice(0, 9).map((chartComponent: any, index: any) => {
            switch (chartComponent?.chartType) {
                case "VerticalBarGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ marginBottom: '25px' }}>
                            <VerticalChart label={chartComponent?.data?.labels} dataSets={chartComponent.data.datasets} />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                case "HorizontalBarGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ position: "relative", marginBottom: '25px' }}>
                            <HorizontalChart label={chartComponent?.data?.labels} dataSets={chartComponent.data.datasets} />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                case "LineGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ marginBottom: '25px' }}>
                            <LineChart datasets={chartComponent.data.datasets} labels={chartComponent?.data?.labels} />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                case "PieGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ marginBottom: '25px' }}>
                            <PieChart labels={chartComponent?.data?.labels} datasets={chartComponent.data.datasets} />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                case "DoughnutGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ marginBottom: '25px' }}>
                            <DoughnutChart
                                labels={chartComponent?.data?.labels}
                                datasets={chartComponent.data.datasets}
                            />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                default:
                    return (
                        <Grid item md={4} xs={12} key={index}>
                            <>Choose dropdowns to render chart!</>
                        </Grid>
                    );
            }
        });
    }, [wallBoardTypeList]);
    const handleCopyLink = (e: any) => {
        e.preventDefault();
        if (externalWebLink) {
            navigator.clipboard.writeText(externalWebLink)
                .then(() => {
                    dispatch(alertMessage(`Success! External WallBoard Link Copied`, "success"))
                    console.log('Link copied to clipboard');
                })
                .catch((err) => {
                    dispatch(alertMessage(`Failed to Copy External WallBoard Link `, "error"))
                    console.error('Failed to copy link: ', err);
                });
        }
    };
    return (
        <>
            <Box className="p-1">
                {param.id &&
                    <Breadcrumbs
                        data={[
                            {
                                title: 'Reports Management ',
                                path: '/ReportsManagement'
                            },
                            {
                                title: 'WallBoard',
                                path: '/WallBoard'
                            },
                            {
                                title: `View Wallboard`,
                                path: `${"/ViewChart/" + param.id}`
                            }
                        ]}
                    />

                }
                <Box className="white-bg p-2 mt-1 add-article">
                    {param.id &&
                        <>
                            <Typography>
                                {"View Wallboard"}
                            </Typography>
                            <hr />
                        </>
                    }
                        <Typography>
                            {viewVal ? `WallBoard - ${viewVal?.name}` : `WallBoard - ${viewValCode?.name}`}
                        </Typography>

                    <div style={{marginTop:'10px'}}>
                        <Typography>
                            {viewVal && (
                                <a href="#" onClick={handleCopyLink}>
                                    Copy External WallBoard Link here
                                </a>
                            )}
                        </Typography>
                    </div>
                    <Grid container spacing={1} alignItems={"end"} style={{ marginTop: "25px" }}>
                        {ChartItem}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default ViewChart;
