import { Avatar, Box, InputAdornment, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BasicButton from "../../../components/common/button/BasicButton";
import CustomDialog from "../../../components/common/dialog/CustomDialog";
import { ActionType } from "../../../redux/actions/dashboard";
import { IState } from "../../../redux/reducers/rootReducers";
import LanguageData from "../../../configs/LanguageData.json"
import {
  linkTicket,
  mergeTicket,
  unLinkTicket,
  unMergeTicket,
} from "../../../services/dashboard/dashboard.service";
import SearchIcon from "@mui/icons-material/Search";
import TicketCardNew from "../../TicketManagementSystem/Modules/TicketCard";
import { LinkIcon } from "../../common/svgIcons/chartIcon";
import { TextBoxLatest } from "../../common/TextBoxLatest/TextBoxLatest";
import { debounce } from "lodash";
import { ITicket, getAllTickets, loadTicketsOnSearch } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import MergeIcon from "@mui/icons-material/Merge";


const mergeApiCall = async (childId: any, parentId: number) => {
  let response: any = await mergeTicket(childId, parentId);
  return response;
};
const linkApiCall = async (childId: any, parentId: number) => {
  let response: any = await linkTicket(childId, parentId);
  return response;
};
const unlinkApiCall = async (childId: any, parentId: number) => {
  let response: any = await unLinkTicket(childId, parentId);
  return response;
};
const unMergeApiCall = async (childId: any) => {
  let response: any = await unMergeTicket(childId);
  return response;
};
interface IMergeProps{
  ticketInfo?: any;
}
const Merge = (props:IMergeProps) => {
  const {ticketData,type,parentId} = props?.ticketInfo || {};
  const [ticketToLink, setTicketToLink] = React.useState<any>(0);
  const FiltersVal: any = useSelector((state: IState) => state?.filtersTicketManagementReducer);
  const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses);
  const [modelOpen , setModelOpen]= React.useState(false)
  const dispatch = useDispatch();
  const classes = useStyles();
  const closeHandler = ()=>{
    dispatch({
      type: "LINK_MERGE_POPUP_INFO",
      payload: null,
    });
  }
  return (
    <>
      
      <CustomDialog
        heading={`${type === "link" ? "Link" : type === "merge" ? "Merge" : type === "unMerge" ? "Unmerge" : "Unlink"} a ticket`}
        body={
          <>
            {type === "link" && 
              <Box className={classes.linkHolder}>
                <Box>
                  <aside className="aside">
                      {(type !== "unLink" && ticketData) && (
                          <TicketCardNew
                            ticketData={ticketData}
                            parentClassNames="hide-actions"
                          />
                      )}
                  </aside>
                </Box>
                <Box className="col-icon">
                  <LinkIcon />
                </Box>
                <Box sx={{width:"100%"}}>
                  {type === "link" && (
                    <>
                      <SearchTickets AllData={AllData} parentId={ticketData?.id} chosenTicketId={ticketToLink.id} callback={setTicketToLink} />
                    </>
                  )}
                </Box>
              </Box>
            }
            {type === "unLink" && 
              <>
                <Box className="p-2">Do you want to Unlink this ticket from parent Ticket {parentId}? </Box>
              </>
            }
            {type === "unMerge" && 
              <>
                <Box className="p-2">Do you want to UnMerge this ticket from its parent? </Box>
              </>
            }
            {type === "merge" &&
              <Box className={classes.linkHolder}>
              <Box>
                <aside className="aside">
                    {(type !== "link" && ticketData) && (
                        <TicketCardNew
                          ticketData={ticketData}
                          parentClassNames="hide-actions"
                        />
                    )}
                </aside>
              </Box>
              <Box className="col-icon">
                <MergeIcon />
              </Box>
              <Box sx={{width:"100%"}}>
                {type === "merge" && (
                  <>
                    <SearchTickets AllData={AllData} parentId={ticketData?.id} chosenTicketId={ticketToLink.id} callback={setTicketToLink} />
                  </>
                )}
              </Box>
            </Box>
            }
          </>
        }
        open={true}
        handleClose={closeHandler}
        footer={
          <>
            <BasicButton
              color="primary"
              label={"Cancel"}
              onClick={closeHandler}
              size="small"
              variant={"outlined"}
              endIcon={false}
            />
            <BasicButton
              type={ type == "unLink" ? "button" : "submit"}
              isLoading={false}
              disabled={ticketToLink.id === 0 && type === "link" ? true : false}
              color="primary"
              label={type}
              onClick={() => {
                //setAddCategoryPopup(false);
                if (type == "link") {
                  linkApiCall(ticketToLink.id,ticketData?.id).then(
                    (res: any) => {
                      if (res.status == 200) {
                        console.log("mergeApiCall", res);
                        dispatch({
                          type: ActionType.SNACKBAR,
                          payload: {
                            message: `${LanguageData.SUCCESS}${ticketToLink.id}${LanguageData.TICKET_LINKED_SUCESSFULLY}${ticketData?.id} `,
                            severity: "success",
                            open: true,
                          },
                        });
                        closeHandler();
                        dispatch(getAllTickets(FiltersVal,AllData,null,null,null))
                      }
                    }
                  );
                } 
                else if(type == "unLink"){
                  unlinkApiCall(ticketData.id,parentId).then(
                    (res: any) => {
                      if (res.status == 200) {
                        console.log("mergeApiCall", res);
                        dispatch({
                          type: ActionType.SNACKBAR,
                          payload: {
                            message: `${LanguageData.SUCCESS}${ticketData.id} ${LanguageData.TICKET_UNLINKED_SUCCESSFULLY}${parentId}`,
                            severity: "success",
                            open: true,
                          },
                        });
                        dispatch(getAllTickets(FiltersVal,AllData,null,null,null))
                        // dispatch(getAllTickets({},{},null,callback,null))
                        closeHandler();
                      }
                    }
                  ); 
                }
                
                else {
                  if (type == "merge") {
                    if(ticketToLink.ticketRelationshipList.length > 0){
                      setModelOpen(true)
                    } else (
                      mergeApiCall(ticketToLink.id, ticketData?.id).then(
                        (res: any) => {
                          if (res.status == 200) {
                            console.log("mergeApiCall", res);
                            dispatch({
                              type: ActionType.SNACKBAR,
                              payload: {
                                message: `${LanguageData.SUCCESS}${ticketToLink.id} ${LanguageData.TICKET_MERGED_SUCCESSFULLY}${ticketData?.id}`,
                                severity: "success",
                                open: true,
                              },
                            });
                            dispatch(getAllTickets(FiltersVal, AllData, null, null, null))
                            closeHandler();
                          }
                        }
                      )
                      
                    )
                  }
                  else if (type == "unMerge") {
                    unMergeApiCall(ticketData?.id).then(
                      (res: any) => {
                        if (res.status == 200) {
                          console.log("mergeApiCall", res);
                          dispatch({
                            type: ActionType.SNACKBAR,
                            payload: {
                              message: `${LanguageData.SUCCESS}${ticketData.id} ${LanguageData.TICKET_UNMERGED_SUCCESSFULLY}`,
                              severity: "success",
                              open: true,
                            },
                          });
                          dispatch(getAllTickets(FiltersVal, AllData, null, null, null))
                          closeHandler();
                        }
                      }
                    );
                  }
                }
              }}
              size="small"
              variant={"contained"}
              endIcon={false}
            />
          </>
        }
      />
      {modelOpen && (
          <CustomDialog
            heading={`Confirm Merge`}
            body={
              <>
                <div>
                  <p style={{maxWidth:"300px"}}>The ticket you're about to merge has linked tickets.
                    Are you sure you want to continue?
                    This action will render the linked tickets unavailable.</p>
                </div>
              </>
            }
            open={modelOpen}
            handleClose={() => {
              setModelOpen(false);
            }}
            footer={
              <>
                {" "}
                <BasicButton
                  color="primary"
                  label={"Cancel"}
                  onClick={()=>{setModelOpen(false)}}
                  size="small"
                  variant={"outlined"}
                  endIcon={false}
                />
                <BasicButton
                  onClick={() => {
                    mergeApiCall(ticketToLink.id, ticketData?.id).then(
                      (res: any) => {
                        if (res.status == 200) {
                          console.log("mergeApiCall", res);
                          dispatch({
                            type: ActionType.SNACKBAR,
                            payload: {
                              message: `${LanguageData.SUCCESS}${ticketToLink.id} ${LanguageData.TICKET_MERGED_SUCCESSFULLY}${ticketData?.id}`,
                              severity: "success",
                              open: true,
                            },
                          });
                          dispatch(getAllTickets(FiltersVal, AllData, null, null, null))
                          closeHandler();
                        }
                      }
                    )
                  }}
                  type="submit"
                  label={"OK"}
                  isLoading={false}
                  isDisabled={false}
                  color="primary"
                  size="small"
                  variant={"contained"}
                  endIcon={false}
                />
              </>
            }
          />
      )}
    </>
  );
};




const SearchTickets = (props:any) =>{
  const {callback,chosenTicketId,parentId,AllData} = props;
  const loader: any = useSelector((state: IState) => state?.LayoutTicketManagementReducer?.showLinkLoader);
  const searchRef:any = React.createRef();
  const dispatch = useDispatch();
  const [list,setList] = React.useState<ITicket[]>([]);
  const [dataInfo,setDataInfo] = React.useState<any>(null);
  const onChangeHandler = debounce(() =>{
    onSearchKeyDownHandler({key:"Enter"})
  },400);
  React.useEffect(()=>{
    onChangeHandler();
  },[])
  const onSearchKeyDownHandler = debounce((event: any) =>{
    if (event.key === 'Enter') {
        console.log('Enter key pressed', AllData?.groups, AllData?.ticketStatus);
        let tempObj:any = {
          groups:[],
          status:[],
        }
        if(dataInfo){
          tempObj = dataInfo;
        }else{
          AllData?.groups.map((i:any)=>{
            if(i.isActive){tempObj.groups.push(i.id);}
          });
          AllData?.ticketStatus.map((i:any)=>{
            if(i.isActive && i.name !== "Closed" && i.name !== "Delete"){
              tempObj.status.push(i.id);
            }
          });
          setDataInfo(tempObj);
        }
        // return
        dispatch(loadTicketsOnSearch(searchRef?.current?.value || "", tempObj.status, tempObj.groups, loadTicketsOnSearchCB));
        callback(0)
    }
  },400);
  const loadTicketsOnSearchCB = (res:ITicket[]) =>{
    setList(res.filter((i:any)=>{
      if(parentId === i.id || i?.ticketLogs?.ticketStatus?.name === "Closed" || i?.ticketLogs?.ticketStatus?.name === "Delete"){
        return false
      }else{
        return true;
      }
    }));
  };

  return(<>
    <Box className="link-ticket-search">
      <Box>
        <TextBoxLatest
            placeholder="Type to search"
            parentClassNames="searchBox-link"
            ref={searchRef}
            onKeyDownHandler={onSearchKeyDownHandler}
            onchangeHandler={onChangeHandler}
            name='search'
            type="search"
            InputProps={{
                endAdornment: (
                    <>
                     {loader ? <span className="link-spinner"><span className="spinner-loader"></span></span> :  <InputAdornment onClick={()=>{onSearchKeyDownHandler({key:"Enter"})}} className='icon-holder' position="end"> <SearchIcon  /> </InputAdornment> }
                    </>
                ),
            }}
        />
      </Box>
      <Box>
        {/* {(list?.length > 0 && list?.length === 30) &&<>
          <Typography className="sml-txt">Related Tickets ({list.length})</Typography>
        </>} */}
        <Box className="ticket-items">
            {list.map((i:ITicket,index:number)=>{
                return(
                  <Box className={`ticketToLink ${chosenTicketId === i.id ? "selected" :""}`}  onClick={()=>{callback(i)}} key={index}>
                    <SmallTicketCard ticketdata={i.ticketLogs} id={i?.id}/>
                  </Box>
                )
            })}
            {list?.length === 0 &&<>
                <Typography className="text-center p-2 sml-txt">
                    No Tickets Found.!
                </Typography>
            </>}
        </Box>
      </Box>
    </Box>
  </>)
}

const SmallTicketCard = (props: any) => {
  const { title, description, priority, ticketStatus, ticketType, contacts = {}, agent } = props?.ticketdata || {};
  if(contacts?.profilePicturePath === "/"){
    contacts.profilePicturePath = null
  }
  return (
    <Box className="Ticket-search-item">
      <Box className="header">
        <Box>
          <Avatar
            className="sml-avatar cursor-pointer"
            sx={{ height: 20, width: 20 }}
            alt={contacts?.knownAs || "PP"}
            src={`${contacts?.profilePicturePath || ""}`}
          />
          <Typography className="id">{props?.id}</Typography>
        </Box>
        <Box className="sts-values">
          <Typography className="badges">{priority?.name}</Typography>
          <Typography className="badges">{ticketType?.displayName}</Typography>
          <Typography className="badges">{ticketStatus?.displayName}</Typography>
        </Box>
      </Box>
      <Box>
        <Tooltip title={description}>
          <Typography className="body-content">{title}</Typography>
        </Tooltip>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state:IState) => {
  return ({
    ticketInfo: state?.TicketViewReducer?.linkMerge,
  })
};

export default connect(mapStateToProps)(Merge);


const useStyles = makeStyles(() =>
  createStyles({
    linkHolder:{
      alignItems:"center",
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "760px",
      "& > div" : {
        maxWidth: "45%",
      },
      "& .link-ticket-search" :{
          width: "100%",
          padding: "10px 13px",
          background: "#ddd5",
          borderRadius: "5px",
          "& .searchBox-link":{
            "& svg" : {
              fontSize: "17px",
              transform: "translateX(-10px)",
              cursor: "pointer"
            }
          },
          "& .ticket-items":{
            maxHeight:"200px",
            overflowY:"scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar" :{
              display: "none"
            }
          }
      }
    }
  })
)