import React, { useEffect } from "react";
import { forwardRef } from "react";
import BasicButton from "../../components/common/button/BasicButton";
import CustomDialog from "../../components/common/dialog/CustomDialog";
import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import CustomInput from "../../components/common/textField/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CloseIcon from "@mui/icons-material/Close";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MaterialTable from "material-table";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SyncAltRoundedIcon from "@mui/icons-material/SyncAltRounded";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from "@mui/styles";
import Select from "react-select/dist/declarations/src/Select";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getAssetsAction } from "../../redux/actions/AssetsManagement/Assets";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
export const tableIcons: any = {
  Search: forwardRef((props, ref) => <SearchRoundedIcon />),
  DetailPanel: forwardRef((props, ref) => <ChevronRightIcon />),
  Clear: forwardRef((props, ref) => <CloseRoundedIcon />),
  ResetSearch: forwardRef((props, ref) => <CloseRoundedIcon />),
  SortArrow: forwardRef((props, ref) => (
    <SyncAltRoundedIcon
      sx={{ color: "gray", transform: "rotate(90deg)", width: "15px" }}
    />
  )),
  Export: forwardRef((props, ref) => <GetAppRoundedIcon />),
  Filter: forwardRef((props, ref) => (
    <FilterListRoundedIcon sx={{ width: "20px" }} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPageRoundedIcon sx={{ width: "20px" }} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPageRoundedIcon sx={{ width: "20px" }} />
  )),
  NextPage: forwardRef((props, ref) => (
    <NavigateNextRoundedIcon sx={{ width: "20px" }} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <NavigateBeforeRoundedIcon sx={{ width: "20px" }} />
  )),
};

const useStyles = makeStyles(() => ({
  wrapper: {
    "& .MuiInputBase-root": {
      fontSize: "14px",
      width: "150px",
      height: "30px",
    },
  },
}));




const AddAsset = ({
  setIsOpenAsset,
  isOpenAsset,
  selected,
  defaultContactFilter,
  onAssetSelected
}: {
  setIsOpenAsset: any;
  isOpenAsset: boolean;
  selected: any;
  defaultContactFilter?: any;
  onAssetSelected: any
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [assetListToShow, setAssetListToShow] = React.useState<any>([]);
  const AssetsList = useSelector(
    (state: IState) => state?.AssetsReducer?.AllAssets
  );
  useEffect(() => {
    if(!AssetsList){
    dispatch(getAssetsAction())
    }
  }, []);

  useEffect(() => {
    if(AssetsList?.length>0){
    console.log('AssetsList', AssetsList)
    let temp : any = []
    AssetsList?.filter((item: any)=>{
      if(item.isActive){
      let a ={
        id: item?.id,
        assetId: item?.id,
        name: item?.name,
        type: item?.assetType?.name,
        dept: item?.contacts?.department?.name,
        conatctName: item?.contacts?.name,
        tag: item?.assetTag,
        // src: <InsertPhotoIcon/>,
      }
      temp.push(a)
    }
    })
    setAssetListToShow(temp)
  }
  }, [AssetsList]);
  let a: any = [];
  return (
    <>
      <CustomDialog
        heading="Add Asset"
        body={
          <>
            <div className={`addAssetModal ${classes.wrapper}`}>
              <MaterialTable
                onSelectionChange={(seleced: any) => {
                  console.log("selected assets", seleced);
                  a = seleced;
                }}
                options={{
                  filtering: true,
                  sorting: false,
                  exportButton: true,
                  showTitle: false,
                  selection: true,
                  showFirstLastPageButtons: false,
                  paginationType: "stepped",
                  headerStyle: {
                    backgroundColor: "#EAF2FD",
                  },
                  maxBodyHeight: "300px",
                }}
                localization={{
                  toolbar: {
                    nRowsSelected: "{0} Asset(s) selected",
                  },
                  body: {
                    emptyDataSourceMessage: "No Assets to display",
                  },
                }}
                style={{ border: "1px solid white", boxShadow: "none" }}
                columns={[
                  {
                    title: "Photo of asset",
                    field: "name",
                    render: (rowData : any) => (
                     
                      <div style={{
                        height: 36,
                        width:10,
                        borderRadius: "5px",
                        boxShadow:
                          "7px 10px 17px -4px rgba(115,109,109,0.48)",
                      }}>{rowData.src}</div>
                    ),
                  },
                  { title: "Name", field: "name" },
                  { title: "Contact", field: "conatctName",defaultFilter: defaultContactFilter, },
                  { title: "Type", field: "type" },
                  { title: "Department", field: "dept" },
                  { title: "Tags", field: "tag" },
                ]}
                data={assetListToShow}
                title=""
                icons={tableIcons}
              />
            </div>
          </>
        }
        open={isOpenAsset}
        handleClose={() => {
          setIsOpenAsset(false);
        }}
        footer={
          <>
            <div style={{ display: "flex", gap: 10 }}>
              <BasicButton
                onClick={() => {
                  setIsOpenAsset(false);
                }}
                type={"cancel"}
                isLoading={false}
                isDisabled={false}
                color="primary"
                size="large"
                label={"Cancel"}
                variant={"outlined"}
                endIcon={<CloseIcon sx={{ width: "20px" }} />}
              />
              <BasicButton
                onClick={() => {
                  const total: any = [...selected, ...a];
                  const ids = total.map((o: any) => o.id);
                  const filtered = total.filter(
                    ({ id }: { id: any }, index: any) =>
                      !ids.includes(id, index + 1)
                  );

                  if (filtered.length > 0) {
                    console.log("filtered onAssetSelected", filtered)
                    onAssetSelected(filtered)
                  }
                  setIsOpenAsset(false);
                }}
                type="submit"
                label={`Select Asset(s)`}
                isLoading={false}
                isDisabled={false}
                color="primary"
                size="large"
                variant={"contained"}
                endIcon={<AddRoundedIcon sx={{ width: "20px" }} />}
              />
            </div>
          </>
        }
      />
    </>
  );
};
export default AddAsset;
