import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import "react-phone-input-2/lib/style.css";
import BasicButton from "../../components/common/button/BasicButton";
import CustomTree from "../../components/common/custom-tree/CustomTree";
import CustomDialog from "../../components/common/dialog/CustomDialog";
import CustomInput from "../../components/common/textField/CustomInput";
const AddCategoryCopy = ({
  setAddCategoryPopup,
  addCategoryPopup,
  category,
  onCategorySubmit,
}: {
  setAddCategoryPopup: any;
  addCategoryPopup: boolean;
  category: any;
  onCategorySubmit: any;
}) => {
  const [value, setValue] = React.useState<any>("");
  const [tempCat, setTempCat] = React.useState<any>([]);

  console.log("data", category);

  const checkName = (name: string = "") => {
    return name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  };
  React.useEffect(() => {
    if (category?.[0]?.subCategoryList?.isActive) {
      let tempItem = category[0].subCategoryList;
      setTempCat(tempItem);
    } else {
      setTempCat([]);
    }
  }, [category]);

  React.useEffect(() => {
    console.log("value", value);
    if (!category) return;
    try {
      const tempCategory = JSON.parse(JSON.stringify(category));
      let tempItem = tempCategory?.[0]?.subCategoryList
        ? tempCategory[0].subCategoryList
        : [];
      let subList: any = [];

      tempItem.forEach((i: any) => {
        if (i.isActive) {
          let itemsList: any = [];

          i.itemList.forEach((j: any) => {
            if (j.isActive) {
              let childList = j.childrenItemList.filter((k: any) =>
                checkName(k.name)
              );
              if (childList.length > 0 || checkName(j.name)) {
                j.childrenItemList = childList;
                itemsList.push(j);
              }
            }
          });

          if (itemsList.length > 0 || checkName(i.name)) {
            i.itemList = itemsList;
            subList.push(i);
          }
        }
      });

      console.log("setTempCat(subList)", category?.[0], subList, value);
      setTempCat(subList);
    } catch (e) {
      console.log(e);
    }
  }, [value]);

  return (
    <>
      <CustomDialog
        heading="Add Category"
        body={
          <>
            <div className="category-content"  style={{ width: "400px" }}>
              <div style={{ marginBottom: "20px" }}>
                <CustomInput
                  isDisabled={false}
                  readOnly={false}
                  iconPosition={"start"}
                  icon={<SearchIcon />}
                  isError={false}
                  label={"Category"}
                  name="category"
                  value={value}
                  onClick={() => {}}
                  onChange={(e: any) => {
                    console.log("change", e.target.value);
                    setValue(e.target.value);
                  }}
                  helperText={""}
                  placeholder={"Search category"}
                />
              </div>
              <div style={{ padding: 5, fontSize:"17px", fontWeight:600 }}>{category?.[0]?.name}</div>
              <CustomTree
                data={tempCat}
                value={value}
                onClick={(id: any, name: any) => {
                  console.log("id, name", id, name);
                  onCategorySubmit({
                    label: name,
                    id: id,
                  })
                }}
              />
            </div>
          </>
        }
        open={addCategoryPopup}
        handleClose={() => {
          setAddCategoryPopup(false);
        }}
        footer={
          <>
            <BasicButton
              type={"submit"}
              isLoading={false}
              isDisabled={false}
              color="primary"
              label={"Save"}
              onClick={() => {
                setAddCategoryPopup(false);
              }}
              size="large"
              variant={"contained"}
              endIcon={false}
            />
          </>
        }
      />
    </>
  );
};
export default AddCategoryCopy;
