import React, { useEffect, useState } from "react";
import SideNavbar from "../components/navbar/sideNavbar/SideNavbar";
import TopNavbar from "../components/navbar/sideNavbar/TopNavbar";
import MobileMenu from "../components/navbar/sideNavbar/MobileMenu";
import AppRouter from "./AppRouter";
import MySnackbar from "../components/common/snackbars";
import Loader from "../components/common/loading";
import CommonStyles from "./../components/common/CommonStyles";
import { IState } from "../redux/reducers/rootReducers";
import { connect, useSelector } from "react-redux";
import SideNavbarSSP from "../components/SelfServicePortal/SideBarSSP";
import { useLocation } from "react-router-dom";
import TopNavbarSSP from "../components/SelfServicePortal/TopNavSSP";
import TopNavBarForAdmin from "../components/navbar/admin-navbar/top-navbar/TopNavBar";
import SideNavbarForAdmin from "../components/navbar/admin-navbar/side-navbar/SideNavBar";
import configs from "../configs/config";
import { Box } from "@mui/material";

const generateLoginScreen = (
  SideBar: string,
  isContactLoggedIn: boolean,
  isAdmin: boolean,
  classes: any
) => {
  if (SideBar == "loggedIn" && !isContactLoggedIn && !isAdmin) {
    return (
      <>
        <MySnackbar />
        <Loader />
        <div className={`sidebar ${classes.BodyRoot}`}>
          <MobileMenu />
          <SideNavbar />
          <TopNavbar />
          <main className="basic-v-gutter">
            <AppRouter />
          </main>
        </div>
      </>
    );
  } else if (SideBar == "loggedIn" && isContactLoggedIn && !isAdmin) {
    return (
      <>
        <MySnackbar />
        <SideNavbarSSP />
        <div className={`sidebar ${classes.BodyRoot}`}>
          <main className="basic-v-gutter">
            <TopNavbarSSP />
            <AppRouter />
          </main>
        </div>
      </>
    );
  } else if (isAdmin) {
    return (
      <>
        <MySnackbar />
        <TopNavBarForAdmin />
        <SideNavbarForAdmin />
        <div className={`sidebar ${classes.BodyRoot}`}>
          <main className="basic-v-gutter">
            <AppRouter />
          </main>
        </div>
      </>
    );
  } else if (SideBar == "notLoggedIn") {
    return (
      <>
        {" "}
        <MySnackbar />
        <AppRouter />
      </>
    );
  } else {
   return  <>loading</>;
  }
};

const MainPage: React.FC = ({isUserLoggedIn,theme}:any) => {
  console.log('themeMainPagetheme',theme)
  const classes = CommonStyles();
  const [isContactLoggedIn, setIsContactLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [SideBar, setSideBar] = useState("initial");
  const currentURL = window.location.href;

  useEffect(() => {
    setTimeout(() => {
      console.log("configs--", configs.BASE_URL);
      let sts = localStorage.getItem("loginInfo");
      let notLogin = sts === null ? "notLoggedIn" : "loggedIn";
      setSideBar(notLogin);
    }, 10);
  }, [isUserLoggedIn]);

  
  useEffect(() => {
    let logInfo: any = localStorage.getItem("loginInfo");
    if (isUserLoggedIn && logInfo !== null) {
      let { isContact } = JSON.parse(logInfo);
      let { isAdminLogin } = JSON.parse(logInfo);
      if (isContact && !isContactLoggedIn) {
        setIsContactLoggedIn(true);
      } else {
        setIsContactLoggedIn(false);
      }
      if (isUserLoggedIn && isAdminLogin) {
        setIsAdmin(true);
      }
    }else{
      setIsAdmin(false);
      setIsContactLoggedIn(false);
    }
    console.log("logged", isAdmin, isContactLoggedIn);
    
  }, [isUserLoggedIn]);

  return (<Box className={`${theme ? "dark-mode" : ""}`}>
    {generateLoginScreen(SideBar, isContactLoggedIn, isAdmin, classes)}
  </Box>)
};

const mapStateToProps = (state:IState) => {
  return ({
    isUserLoggedIn : state?.LoginReducer?.isLoggedIn,
    theme : state?.theme?.theme
  })
};

export default connect(mapStateToProps)(MainPage);
//export default MainPage;
