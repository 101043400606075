import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend,ChartDataLabels);


// some of this code is a variation on https://jsfiddle.net/cmyker/u6rr5moq/




const options : any={
  maintainAspectRatio: false,	// Don't maintain w/h ratio
  plugins: {
    legend: {
        position: 'top' as const,
        
      labels: {
        usePointStyle: false,
        filter: (legendItem: any, data: any) => data.datasets[0].data[legendItem.index] != 0
      },
    },
    ChartDataLabels,
      datalabels: {
        display: true,
        labels:{
          name: {
            align: 'top',
            color:"white",
            font: {size: 9},
            formatter: function(value : any, ctx: any) {
              console.log('ctx.chart.data.datasets[ctx.dataIndex+1]', ctx.chart.data.labels[ctx.dataIndex])

              return ctx.active
                ? ''
                : ctx.chart.data.datasets[0].data[ctx.dataIndex]<=0? "" : ctx.chart.data.labels[ctx.dataIndex];
            }
          },
          value: {
            color: "white",
            formatter: function(value : any, ctx: any) {
              console.log('ctx.chart.data.datasets[ctx.dataIndex+1]', ctx.chart.data.datasets[0].data[ctx.dataIndex])
              return ctx.active
                ? ''
                : ctx.chart.data.datasets[0].data[ctx.dataIndex]<=0 ? "": ctx.chart.data.datasets[0].data[ctx.dataIndex];
            }
          }
        }
      }
  }
}

const PieChart = ({labels,datasets}:{labels: any,datasets: any})=>{
  const data = {
    labels: labels,
    datasets: datasets
  };
    return (
        <Pie
          data={data}
          options={options}
        />
    );
  }
export default PieChart;


