import React, { useEffect, useState } from 'react';
import CustomModalDialog from "../customDialog";
import CommonAppCarousel from '../../common2/commonCarousel';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid } from '@mui/material';
import CommonStyles from '../CommonStyles';
export default function PopupImageSlider(props:any) {
  console.log('PopupImageSlider',props)
  const classes = CommonStyles();
  const {closeHandler,activeImgIndex=0,carouselArr=[]} = props;
  const [carouselItems, setCarouselItems] = useState([] as any);
  const genCarouselArr = () => {
    let items:any = [];
    items = carouselArr.map((item:any) => {
      return (
        <Grid container className={classes.upgradePopup} spacing={0}>
          <Grid item xs={12}>
            <img src={item.filePath}  style={{objectFit:"contain",width:"100%",maxHeight: "calc(100vh - 100px)"}} height="auto" alt="" />
          </Grid>
        </Grid>
      );
    });
    setCarouselItems(items);
  };
  useEffect(()=>{
    genCarouselArr();
  },[carouselArr.length])
  return (
    <>
        <CustomModalDialog
          title=""
          parentClasses="transperantModal sml-popup transparent-modal"
          id="imageSlider"
          isOpen={true}
          onClose={closeHandler}
          headerContent={<></>}
          bodyContent = {
            <>
            <CommonAppCarousel
              stepVal={activeImgIndex}
              bodyContent={carouselItems}
              prevIcon={<ArrowBackIosIcon />}
              nxtIcon={<ArrowForwardIosIcon />}
              classNames="upgradeModal"
            />
            </>
          }
        />
    </>
  );
}
