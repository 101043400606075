import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getEmailSettings,
  addEmailSettingsAction,
  editEmailSettings,
} from "../../../redux/actions/adminInterfaces/EmailSettings";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import CommonStyles from "../../../components/common/CommonStyles";
import AppLoader from "../../../components/common/AppLoader";
import InputBox from "../../../components/common2/InputBox";
import { addEmailSettingsSspAction, editEmailSettingsSsp, getEmailSettingsSsp } from "../../../redux/actions/SelfServicePortal/ContactAid/EmailSettingsSsp/EmailSettingsSsp";

const getValidationSchema = () => {
  const schemaObject: any = {};

  schemaObject["name"] = Yup.string().test("name", function (value = "") {
    if (value.trim() === "") {
      return this.createError({
        path: this.path,
        message: "Please Enter Title",
      });
    } else if (!/^[A-Za-z\s]+$/.test(value)) {
      return this.createError({
        path: this.path,
        message: "Numbers & Special characters are not allowed.",
      });
    } else {
      return true;
    }
  });
  schemaObject['mailId'] = Yup.string().email().test('mailId',
    function (value = "") {
      if (value.trim() === '') {
        return this.createError({ path: this.path, message: "Please Enter Email " });
      } else { return true; }
    }
  );
  schemaObject["clientId"] = Yup.string().required();
  schemaObject["tenantId"] = Yup.string().required();
  schemaObject["clientSecret"] = Yup.string().required();
  return Yup.object().shape(schemaObject);
};

function OutlookConfigSsp() {
  const param: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = CommonStyles();
  const [isUpdate, setisUpdate] = useState(false);
  const [loader, setloader] = useState(false);

  const isSettingsAdded = useSelector(
    (state: IState) => state?.EmailTemplatesReducer?.isTemplateAdded
  );
  const emailSettingsList = useSelector(
    (state: IState) => state?.EmailSettingsSspReducer?.AllEmailSettingsSsp
  );

  const [Data, setData] = useState({
    clientId: "",
    tenantId: "",
    mailId: "",
    clientSecret: "",
    type: "OutLook",
    name: "",
    isActive: true
  });

  const backHandler = () => {
    navigate("/EmailSettingsSsp");
  };

  const onchangeHandler = (e: any) => {
    let val = e.target.value;
    const name = e.target.name;
    setData({
      ...Data,
      [name]: val,
    });
  };
  useEffect(() => {
    dispatch(getEmailSettingsSsp());
  }, []);


  useEffect(() => {
    console.log(isSettingsAdded);
    if (isSettingsAdded === "0") {
      backHandler();
    }
  }, [isSettingsAdded]);
  useEffect(() => {
    console.log("emailSettingsList", emailSettingsList);
    if (param?.id && emailSettingsList && emailSettingsList.length > 0) {
      let editItem = emailSettingsList.filter((x: any) => x.id === +param.id);
      if (editItem.length == 0) return;
      editItem = editItem[0];
      setisUpdate(true);
      console.log(editItem);
      setData(editItem);
    }
  }, [param, emailSettingsList]);
  return (
    <>
      <AppLoader enabled={loader} />
      {(!param.id || Data?.name !== "") && (
        <Formik
          initialValues={{ ...Data }}
          validationSchema={getValidationSchema()}
          onSubmit={(values, { resetForm }) => {
            console.log("Success! Data submitted.", values);
            const request: any = { ...values };
            debugger
            if (param.id) {
              dispatch(editEmailSettingsSsp(param.id, request, backHandler));
            } else {
              dispatch(addEmailSettingsSspAction(request, backHandler));
            }
          }}
        >
          {(props) => (
            <AddForm
              backHandler={backHandler}
              {...props}
            />
          )}
        </Formik>
      )}
    </>
  );
}


export const AddForm = (props: any) => {
  const {
    errors,
    touched,
    backHandler,
    submitForm,
    values,
    setFieldValue,
  } = props;
  const param: any = useParams();
  const classes = CommonStyles();

  useEffect(() => {
    console.log("props123", props);
  }, [props]);
  const handleChange = (content: any) => {
    setFieldValue("body", content);
  };

  return (
    <>
      <Box className={`pageHolder ${classes.px1}`}>
        <Box>
          <Grid container spacing={1}>
            <Grid item md={5} xs={12}>
              <InputBox
                title="Title"
                type="text"
                placeholder="Please Enter"
                styleProp={classes.BoxTitle}
                inputStyle={classes.inputStyle}
                name="name"
                onChange={(e: any) => {
                  setFieldValue("name", e.target.value);
                }}
                value={values.name}
                required={true}
                errMsg={errors?.name}
                showErr={touched?.name && errors?.name ? true : false}
              />

              <InputBox
                title="Client Id"
                type="text"
                placeholder="Please Enter"
                styleProp={classes.BoxTitle}
                inputStyle={classes.inputStyle}
                name="clientId"
                onChange={(e: any) => {
                  setFieldValue("clientId", e.target.value);
                }}
                value={values.clientId}
                required={true}
                errMsg={errors?.clientId}
                showErr={touched?.clientId && errors?.clientId ? true : false}
              />

              <InputBox
                title="Mail Id"
                type="text"
                placeholder="Please Enter"
                styleProp={classes.BoxTitle}
                inputStyle={classes.inputStyle}
                name="mailId"
                onChange={(e: any) => {
                  setFieldValue("mailId", e.target.value);
                }}
                value={values.mailId}
                required={true}
                errMsg={errors?.mailId}
                showErr={touched?.mailId && errors?.mailId ? true : false}
              />

              <InputBox
                title="Client Secret"
                type="text"
                placeholder="Please Enter"
                styleProp={classes.BoxTitle}
                inputStyle={classes.inputStyle}
                name="clientSecret"
                onChange={(e: any) => {
                  setFieldValue("clientSecret", e.target.value);
                }}
                value={values.clientSecret}
                required={true}
                errMsg={errors?.clientSecret}
                showErr={
                  touched?.clientSecret && errors?.clientSecret ? true : false
                }
              />

              <InputBox
                title="Tenant Id"
                type="text"
                placeholder="Please Enter"
                styleProp={classes.BoxTitle}
                inputStyle={classes.inputStyle}
                name="tenantId"
                onChange={(e: any) => {
                  setFieldValue("tenantId", e.target.value);
                }}
                value={values.tenantId}
                required={true}
                errMsg={errors?.tenantId}
                showErr={touched?.tenantId && errors?.tenantId ? true : false}
              />


              <Box className="text-center">
                <Button
                  className={`${classes.btn} ${classes.btnCancel}`}
                  variant="outlined"
                  onClick={backHandler}
                >
                  <CancelIcon style={{ marginRight: "5px" }} />
                  Cancel
                </Button>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Button
                  className={`${classes.btn} ${classes.btnSubmit}`}
                  variant="contained"
                  onClick={submitForm}
                >
                  {!param?.id ? "Save" : "Update"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default OutlookConfigSsp;
