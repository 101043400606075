import axios from "axios"; 
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
const url = `${configs.BASE_URL}/AssetType`
export const assetTypeUrl = `${configs.BASE_URL}/AssetType`
export const getAssetTypesAction = () => {
    return async (dispatch: any) => {
      try {
        ;
        const response = await axios(url);
        console.log(response)
        dispatch(
            { 
                type:'STORE_ASSETTYPES',
                payload: response.data, 
            }
        )
      } catch (e:any) {
          console.log(e.response)
      }
    };
};
export default getAssetTypesAction;