import moment, { Moment } from "moment";
import Form, { generateTagFromEdit } from "./Form";
import {
  iPermissions,
  iTicketManagementProperties,
  iUserFormData,
} from "./Type";
import { getTicketsById } from "../../../services/dashboard/dashboard.service";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import { getAssetsAction } from "../../../redux/actions/AssetsManagement/Assets";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { ConvertZWithoutFormat } from "../../../components/helpers/helperFunctions";
import { getCategory } from "../../../redux/actions/SystemManagement/AddCategory";

export const FindItem = (list: any, searchItem: any) => {
  let temp = list?.find((x: any) => x?.id == searchItem);
  return temp ? temp : 0;
};
export const FindItemByName = (list: any, searchItem: any) => {
  let temp = list?.find((x: any) => x?.code === searchItem);
  return temp ? temp : 0;
};
const getTicketsByIdFunc = async (id: any) => {
  let res = await getTicketsById(id);
  return res;
};

const modifyLabel = (item: any) => {
  if (item) {
    return {
      label: `${item.name},(${item.email})`,
      name: item.name,
      value: item.name,
      id: item.id,
      knownAs: item.knownAs,
      userName: item.userName,
      jobTitle: item.jobTitle,
      email: item.email,
      whatsAppNumber: item.whatsAppNumber,
      company: item.companyName,
      place: item.officeLocation,
      no: item.mobilePhone,
      isVIP: item.isVIP,
      alert: item.alert,
      profilePictureId: item.profilePictureId,
      icon: <AccountCircleOutlinedIcon sx={{ color: "#a6a6a6" }} />,
    };
  } else {
    return null;
  }
};

const modifyAssetWIthName = (asset: any, AssetsList: any) => {
  console.log("modifyAssetWIthName--", asset, AssetsList);
  return asset;
};

const generateCategoryLabel = (catId: any, catList: any) => {
  const categoryObj: any = {};
  catList?.map((i: any) => {
    categoryObj[i.id] = i.name;
    i.subCategoryList.map((j: any) => {
      categoryObj[j.id] = j.name;
      j.itemList.map((k: any) => {
        categoryObj[k.id] = k.name;
        k.childrenItemList.map((l: any) => {
          categoryObj[l.id] = l.name;
        });
      });
    });
  });
  console.log("categoryObj", categoryObj);
  if (categoryObj?.[catId]) {
    return {
      label: categoryObj?.[catId],
      id: catId,
    };
  }
};

const Edit = ({
  ticketManagementProperties,
  permissions,
  style,
  id,
}: {
  ticketManagementProperties: iTicketManagementProperties;
  permissions: iPermissions;
  style: any;
  id: any;
}) => {
  const currentDate: Moment = moment(new Date());
  const dispatch = useDispatch();

  const AssetsList = useSelector(
    (state: IState) => state?.AssetsReducer?.AllAssets
  );
  const Category = useSelector(
    (state: IState) => state?.categoryReducer?.Data?.data
  );

  useEffect(() => {
    if (!AssetsList) {
      dispatch(getAssetsAction());
    }
    // dispatch(getCategory());
  }, []);

  const [editData, setEditData] = useState<any>(false);

  useEffect(() => {
    console.log("category show", Category);
    getTicketsByIdFunc(id).then((res) => {
      const defaultValue: any = {
        id: editData.id,
        primaryContact: modifyLabel(res?.ticketLogs?.contacts),
        ticketType: FindItem(
          ticketManagementProperties.TicketTypes,
          res?.ticketLogs?.ticketTypeId
        ),
        secondaryContact: modifyLabel(res?.ticketLogs?.secondaryContacts),
        priority: FindItem(
          ticketManagementProperties.priorities,
          res?.ticketLogs?.priorityId
        ),
        source: FindItemByName(
          ticketManagementProperties.ticketSources,
          res?.ticketLogs?.sourceCode
        ),
        subject: res?.ticketLogs?.title,
        openDate: res?.ticketLogs?.openDateTime,
        desc: res?.ticketLogs?.description,
        nextActionDesc: res?.ticketLogs?.nextActionDescription,
        nextActionDate: res?.ticketLogs?.firstResponseDueDateTime,
        dueDate: res?.ticketLogs?.dueDateTime,
        tag: generateTagFromEdit(res?.ticketTagList),
        asset: res?.ticketAssetList,

        category: generateCategoryLabel(res?.ticketLogs?.categoryId, Category),
        groupId: res?.ticketLogs?.groupId,
        agentId: res?.ticketLogs?.agentId,
        statusId: res?.ticketLogs?.statusId,
        note: res?.ticketLogs?.note ? res?.ticketLogs?.note : "",
        effort: res?.ticketLogs?.effort,
        totalEffort: res.totalEffort,
        attachment: res?.ticketLogs?.ticketLogAttachmentsList,
        circulationListTO: res.ticketLogs?.circulationListTO,
        circulationListBCC: res.ticketLogs?.circulationListBCC,
        circulationListCC: res.ticketLogs?.circulationListCC,
      };
      console.log("category defaultValue show", defaultValue);

      setEditData(defaultValue);
    });
  }, [AssetsList, Category]);

  return (
    <>
      <div
        style={{
          color: "#273A6B",
          fontSize: "22px",
          fontWeight: 900,
          marginTop: "7px",
          marginBottom: "15px",
          marginLeft: "15px",
        }}
        className={`${style.heading} tickets-heading`}
      >
        Edit ticket {id}
      </div>
      <div
        style={{ padding: "0 0 20px 20px" }}
        className={`${style.wrapper} add-ticket-items`}
      >
        {editData && (
          <Form
            ticketManagementProperties={ticketManagementProperties}
            permissions={permissions}
            style={style}
            defaultValue={editData}
            id={id}
          />
        )}
      </div>
    </>
  );
};

export default Edit;
