import { Box, Button, InputAdornment, Popover, TextField, Tooltip, Typography } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { createRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import { storeFiltersValue } from "../../../../redux/actions/TicketsManagement/GetTickets";
import { IState } from "../../../../redux/reducers/rootReducers";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomTheme from "../../../common/CustomTheme";
import { TextBoxLatest } from "../../../common/TextBoxLatest/TextBoxLatest";
import { handleTime } from "../../../Dashboard/TicketManagement/TicketDashboardHolder";
const data = CustomTheme();
const AgentsOption = memo((props:any) => {
    const {agentsList,chosenAgents,theme} = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedObj, setSelectedObj] = useState<any>({});
    const [extraUsers, setExtraUsers] = useState<any>([]);

    /* ===== POPOVER ==== */
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = useCallback((event: any) => {setAnchorEl(event.currentTarget)},[anchorEl]);
    const handleClose = useCallback(() => {setAnchorEl(null)},[anchorEl]);
    /* ===== POPOVER ==== */
    
    useEffect(()=>{
        console.log('chosenAgents',chosenAgents)
        const temp:any = {};
        if(chosenAgents.map){
            chosenAgents.map((i:any)=>{
                temp[i] = true;
            })
            setSelectedObj(temp)
        }
    },[chosenAgents])
    
    useEffect(()=>{
        console.log('chosenAgents',chosenAgents)
        if(agentsList.filter){
            const tempArr:any = agentsList.filter((i:any,index:number)=>index >= 6)
            setExtraUsers(tempArr)
        }
    },[agentsList?.length])
    
    const clickHandler = useCallback((val: any, action: string) => {
        let tempArr: any = [...chosenAgents];
        console.log('chosenAgents',chosenAgents, tempArr,action,val);

        if (action === "add") {
            tempArr.push(val);
        } else {
            tempArr = tempArr.filter((i: any) => i !== val);
        }
        console.log('chosenAgents1',chosenAgents, tempArr,action,val);

        dispatch(storeFiltersValue(tempArr, 'TCKT_AGENTS_VALUE'));
    },[chosenAgents])

    
    
    const dropdownChecked = useCallback(() =>{
        let temp = false;
        agentsList.map((i:any,index:number)=>{
            if(index >= 6 && selectedObj?.[i.id]){
                temp = true;
            }
        });
        return temp;
    },[selectedObj,agentsList])



    const togglerUsers = useMemo(()=>{
        return <>{extraUsers.length > 0 && <>
            <Tooltip title={`${extraUsers.length} Agents`} >
                <Box onClick={handleClick} aria-describedby={"agent-filter-popover"} className={`${classes.userBoxNav} ${dropdownChecked() ? "checked" : ""}`} sx={{ background: "#ddd !important" }}>
                    +{extraUsers.length}
                </Box>
            </Tooltip>
        </>}</>
    },[classes,dropdownChecked])


    return (
        <Box>
            <Box className={`${classes.row} agent-selection-boxes`}>
                {agentsList && agentsList?.map && agentsList.map((i: any, index: number) => {
                    const { userName,firstName, lastName } = i;
                    const iconName = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
                    return (
                        <>
                            {index < 6 &&
                                <Tooltip title={`${firstName} ${lastName} - ${userName}`} key={index}>
                                    <Box onClick={() => clickHandler(i.id, selectedObj?.[i.id] ? "remove" : "add")} className={`${selectedObj?.[i.id] ? 'checked' : ''} ${classes.userBoxNav} agents-name`}>
                                        {iconName}
                                    </Box>
                                </Tooltip>
                            }
                        </>
                    )
                })}
                {togglerUsers}  
            </Box>
            <Popover
                id={"agent-filter-popover"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
            >
                <ExtraAgents 
                    chosenAgents = {chosenAgents}
                    agents = {extraUsers}
                    selectedObj = {selectedObj}
                />
            </Popover>
        </Box>
    )
})

// {index >= 6 &&
interface IExtraAgentsProps {
    chosenAgents:any;
    agents:any[];
    selectedObj:any
}
export const ExtraAgents = (props:IExtraAgentsProps) =>{
    const classes = useStyles();
    const searchRef:any = createRef();
    const dispatch = useDispatch();
    const {chosenAgents,agents=[],selectedObj} = props;
    const [agentList,setAgentList] = useState(agents)
    const clickHandler = useCallback((val: any, action: string) => {
        let tempArr: any = [...chosenAgents];
        if (action === "add") {
            tempArr.push(val);
        } else {
            tempArr = tempArr.filter((i: any) => i !== val);
        }
        dispatch(storeFiltersValue(tempArr, 'TCKT_AGENTS_VALUE'));
    },[chosenAgents])

    useEffect(()=>{
        if(searchRef?.current?.focus){
            searchRef.current.focus()
        }
    },[])
    const searchHandler = (e:any) =>{
        if(!searchRef?.current) return;
        handleTime.cancel();
        handleTime.setup(()=>{
            setAgentList(agents.filter((i:any)=>`${i?.firstName} ${i?.lastName}`.toLowerCase().includes(searchRef.current.value.toLowerCase())))
        },200);
    }
    return(
        <>
            <Typography sx={{ p: 1 }}>Choose Agents</Typography>
            <Box className="filter-list-search">
                <TextBoxLatest
                    placeholder="Search..."
                    ref={searchRef}
                    onchangeHandler={searchHandler}
                />
                <Box className="filter-list-items">
                    {agentList && agentList?.map && agentList.map((i: any, index: number) => {
                        return (<Typography key={index} className={classes.filterItem} sx={{ p: 1 }} onClick={() => { clickHandler(i.id, selectedObj?.[i?.id] ? "remove" : "add") }}>{selectedObj?.[i?.id] ? <CheckBoxIcon className={classes.checkedIcon} /> : <CheckBoxOutlineBlankIcon />} {i?.firstName} {i?.lastName}</Typography>)
                    })}
                    {(!agentList?.length || agentList.length === 0) && 
                        <Typography className="no-records">No Records Found</Typography>
                    }
                </Box>
            </Box>
        </>
    )
}
const useStyles = makeStyles(() => ({
    row: {
        display: "flex",
    },
    rowWrap: {
        flexWrap: "wrap",
    },
    filterItem:{
        fontSize:"11px",
        textTransform:"uppercase",
        cursor:"pointer",
        padding: "2px 5px",
        display: "flex",
        alignItems: "center",
        gap: "2px",
        "& svg":{
            width: "18px",
            height: "18px",
            color: "#777",
        }
    },
    filterTitle:{
        fontSize:"13px",
        borderBottom:"1px solid #ddd"
    },
    checkedIcon:{
        color:"#007bff !important"
    },
    userBoxNav: {
        fontSize: '10px',
        borderRadius: '50%',
        lineHeight: '25px',
        minWidth: '25px',
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: '0 0 0px 0px #48b963',
        transition: '.5s ease-in-out',
        textTransform:"uppercase",
        "&.checked": {
            boxShadow: '0 0 0px 4px #48b963',
            zIndex: 1
        }
    },
}))



const mapStateToProps = (state:IState) => {
    return ({
        agentsList : state?.TicketsReducer?.allResponses?.agentsPerRole,
        chosenAgents : state?.filtersTicketManagementReducer?.agents,
        theme : state.theme.theme,
    })
  };
  
export default connect(mapStateToProps)(AgentsOption);