import moment from "moment";
import { iFormData } from "../../pages/new-ticket/LatestTicket/Type";

export default function convertAPIResponseToLib(obj: any, ticketId: any) {
  if (obj.hasOwnProperty("name")) {
    if (
      obj.hasOwnProperty("subCategoryList") &&
      Array.isArray(obj.subCategoryList)
    ) {
      return {
        label: obj.name,
        id: obj.id,
        checked: ticketId == obj.id ? true : false,
        expanded: ticketId == obj.id ? true : false,
        ticketTypesIds: obj?.categoryTicketTypeList?.map(
          (item: any) => item.ticketTypeId
        ),
        children: obj.subCategoryList.map((choice: any) => {
          return convertAPIResponseToLib(choice, ticketId);
        }),
      };
    } else if (obj.hasOwnProperty("itemList") && Array.isArray(obj.itemList)) {
      return {
        label: obj.name,
        id: obj.id,
        checked: ticketId == obj.id ? true : false,
        expanded: ticketId == obj.id ? true : false,
        ticketTypesIds: obj?.categoryTicketTypeList?.map(
          (item: any) => item.ticketTypeId
        ),
        children: obj.itemList.map((choice: any) => {
          return convertAPIResponseToLib(choice, ticketId);
        }),
      };
    } else if (
      obj.hasOwnProperty("childrenItemList") &&
      Array.isArray(obj.childrenItemList)
    ) {
      return {
        label: obj.name,
        id: obj.id,
        checked: ticketId == obj.id ? true : false,
        expanded: ticketId == obj.id ? true : false,
        ticketTypesIds: obj?.categoryTicketTypeList?.map(
          (item: any) => item.ticketTypeId
        ),
        children: obj.childrenItemList.map((choice: any) => {
          return convertAPIResponseToLib(choice, ticketId);
        }),
      };
    } else {
      return {
        label: obj.name,
        id: obj.id,
        checked: ticketId == obj.id ? true : false,
        expanded: ticketId == obj.id ? true : false,
        ticketTypesIds: obj?.categoryTicketTypeList?.map(
          (item: any) => item.ticketTypeId
        ),
        children: [],
      };
    }
  }
}

export const truncate = (input: any) =>
  input?.length > 100 ? `${input.substring(0, 100)}...` : input;


export function addEllipsis(str: string, maxLength: number) {
  if (str.length <= maxLength) {
      return str;
  }
  return str.substring(0, maxLength - 3) + '...';
}

export function parseTimeSpan(timeSpan: string = "0.00:00:00") {
  const tempObj = {
    days: "00",
    hours: "00",
    mins: "00",
  };
  const splitForDays = timeSpan.split(".");
  if (splitForDays?.length === 2) {
    tempObj.days = splitForDays[0];
  }
  const splitForTime = splitForDays[splitForDays.length - 1]
    .split(":")
    .reverse();
  if (splitForTime.length > 0) {
    splitForTime.map((i: string, index: number) => {
      if (index === 1) {
        tempObj.mins = i;
      } else if (index === 2) {
        tempObj.hours = i;
      }
    });
  }
  return tempObj;
}
export function timeSpanToReadbleFormatNew(
  timeSpan: string,
  type: string = "long"
): string {
  if (!timeSpan) {
    return "Error: Time span is empty";
  }

  const timeParts: string[] = timeSpan.split(":");
  let formattedTimeSpan: string = "";
  let days: string = "";

  // Check if there are days and append to formattedTimeSpan
  const daysIndex: number = timeParts[0].indexOf(".");
  if (daysIndex !== -1) {
    days = timeParts[0].substring(0, daysIndex);
    formattedTimeSpan += `${days} d`;
    if (parseInt(days) > 1) {
      formattedTimeSpan += "";
    }
    formattedTimeSpan += " ";
  }

  // Append hours and minutes to formattedTimeSpan
  const hoursIndex: number = daysIndex !== -1 ? daysIndex + 1 : 0;
  const hours: string = timeParts[0].substring(hoursIndex) || "0";
  const mins: string = timeParts[1] || "0";

  if (type === "long") {
    if (parseInt(days) > 0 || parseInt(hours) > 0 || parseInt(mins) > 0) {
      if (parseInt(hours) > 0) {
        formattedTimeSpan += `${parseInt(hours)} ${
          parseInt(hours) === 1 ? "hour" : "hours"
        } `;
      }
      if (parseInt(mins) > 0) {
        if (parseInt(hours) > 0) {
          formattedTimeSpan += `${parseInt(mins)} ${
            parseInt(mins) === 1 ? "minute" : "minutes"
          } `;
        } else {
          formattedTimeSpan += `${parseInt(mins)} ${
            parseInt(mins) === 1 ? "minute" : "minutes"
          }`;
          if (parseInt(mins) < 60) {
            formattedTimeSpan += " ";
          }
        }
      }
    } else {
      formattedTimeSpan = "0";
    }
  } else if (type === "short") {
    formattedTimeSpan = `${days}d ${parseInt(hours)}h ${parseInt(mins)}m`;
  } else {
    formattedTimeSpan = "Invalid type";
  }

  // Remove trailing space
  formattedTimeSpan = formattedTimeSpan.trim();

  return formattedTimeSpan;
}

export function formatTimeWithDays(durationInSeconds: any) {
  const days = Math.floor(durationInSeconds / 86400);
  const hours = Math.floor((durationInSeconds % 86400) / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const formattedTime = `${String(days).padStart(2, "0")}d ${String(
    hours
  ).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
  return formattedTime;
}
export const isDateInRange = (date: any, startDate: any, endDate: any) => {
  return date >= startDate && date <= endDate;
};

export const categorizeDateRange = (startDate: any, endDate: any) => {
  const currentDate = new Date();
  if (startDate && endDate) {
    const areDatesSame = startDate?.toDateString() === endDate?.toDateString();

    if (areDatesSame && startDate) {
      if (startDate?.toDateString() === currentDate?.toDateString()) {
        return "Today";
      } else {
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        if (startDate.toDateString() === yesterday.toDateString()) {
          return "Yesterday";
        }
      }
    }

    const thisWeekStart = new Date(currentDate);
    thisWeekStart.setHours(0, 0, 0, 0);
    thisWeekStart.setDate(currentDate.getDate() - currentDate.getDay());
    const thisWeekEnd = new Date(currentDate);
    thisWeekEnd.setHours(23, 59, 59, 999);
    thisWeekEnd.setDate(thisWeekStart.getDate() + 6);

    if (
      isDateInRange(startDate, thisWeekStart, thisWeekEnd) &&
      isDateInRange(endDate, thisWeekStart, thisWeekEnd)
    ) {
      return "This week";
    }

    const lastWeekStart = new Date(currentDate);
    lastWeekStart.setHours(0, 0, 0, 0);
    lastWeekStart.setDate(currentDate.getDate() - currentDate.getDay() - 7);
    const lastWeekEnd = new Date(lastWeekStart);
    lastWeekEnd.setHours(23, 59, 59, 999);
    lastWeekEnd.setDate(lastWeekStart.getDate() + 6);

    if (startDate >= lastWeekStart && endDate <= lastWeekEnd) {
      return "Last week";
    }

    const currentMonthStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const currentMonthEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    currentMonthEnd.setHours(23, 59, 59, 999);

    if (
      isDateInRange(startDate, currentMonthStart, currentMonthEnd) &&
      isDateInRange(endDate, currentMonthStart, currentMonthEnd)
    ) {
      return "This month";
    }

    const lastMonthStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    );
    lastMonthEnd.setHours(23, 59, 59, 999);

    if (
      isDateInRange(startDate, lastMonthStart, lastMonthEnd) &&
      isDateInRange(endDate, lastMonthStart, lastMonthEnd)
    ) {
      return "Last month";
    }
  }

  return false; // If the dates do not match any category
};

export function getDateRange(category: string) {
  const currentDate = new Date();

  switch (category) {
    case "Today":
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);

      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      console.log("chekc important", {
        startDate: todayStart,
        endDate: todayEnd,
      });
      return { startDate: todayStart, endDate: todayEnd };
    case "Yesterday":
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);

      const yesterdayStart = new Date(yesterday);
      yesterdayStart.setHours(0, 0, 0, 0);

      const yesterdayEnd = new Date(yesterday);
      yesterdayEnd.setHours(23, 59, 59, 999);

      return { startDate: yesterdayStart, endDate: yesterdayEnd };
    case "This week":
      const thisWeekStart = new Date(currentDate);
      thisWeekStart.setHours(0, 0, 0, 0);
      thisWeekStart.setDate(currentDate.getDate() - currentDate.getDay());
      const thisWeekEnd = new Date(currentDate);
      thisWeekEnd.setHours(23, 59, 59, 999);
      thisWeekEnd.setDate(thisWeekStart.getDate() + 6);
      return { startDate: thisWeekStart, endDate: thisWeekEnd };
    case "Last week":
      const lastWeekStart = new Date(currentDate);
      lastWeekStart.setHours(0, 0, 0, 0);
      lastWeekStart.setDate(currentDate.getDate() - currentDate.getDay() - 7);
      const lastWeekEnd = new Date(lastWeekStart);
      lastWeekEnd.setHours(23, 59, 59, 999);
      lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
      return { startDate: lastWeekStart, endDate: lastWeekEnd };
    case "This month":
      const currentMonthStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const currentMonthEnd = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      currentMonthEnd.setHours(23, 59, 59, 999);
      return { startDate: currentMonthStart, endDate: currentMonthEnd };
    case "Last month":
      const lastMonthStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
      const lastMonthEnd = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      );
      lastMonthEnd.setHours(23, 59, 59, 999);
      return { startDate: lastMonthStart, endDate: lastMonthEnd };
    default:
      return null; // Invalid category
  }
}
export const timeSpanIntoObject = (time:string="")=>{
  const tempObj:any = {
    days:0,
    hours:0,
    mins:0,
  }
  const splitedVal = time.replaceAll(":00",":").replaceAll(".00",".").split(":");
  const daySplited = splitedVal[0].split(".");
  if(daySplited.length > 1){
    tempObj.days = daySplited[0];
    tempObj.hours = daySplited[1];
  }else{
    tempObj.days = "";
    tempObj.hours = splitedVal[0];
  }
  tempObj.mins = splitedVal[1];

  return tempObj;
}
export const getPagePermission = (roleObj: any, path: string = "/") => {
  console.log("roleObjroleObj", roleObj, path);
  if (roleObj?.isSuperAdmin || path === "/" || path === "/*") return true;
  switch (path) {
    case "/AllNewTickets":
      return roleObj?.NewTickets?.View;
    case "/adminPanel":
      return roleObj?.sysAdminAccess;
    case "/Ticket-Types":
      return roleObj?.TicketTypes?.View;
    case "/Permission":
      return roleObj?.Permissions?.View;
    case "/Category":
      return roleObj?.Categories?.View;
    case "/category/add-categories":
      return roleObj?.Categories?.Create;
    case "/category/:id":
      return roleObj?.Categories?.Edit;
    case "/Priority":
      return roleObj?.Priorities?.View;
    case "/priority/add-priority":
      return roleObj?.Priorities?.Create;
    case "/priority/:id":
      return roleObj?.Priorities?.Edit;
    case "/Working-Time-Plan":
      return roleObj?.WorkingTimePlan?.View;
    case "/Working-Time-Plan/create-wtp":
      return roleObj?.WorkingTimePlan?.Create;
    case "/Working-Time-Plan/:id":
      return roleObj?.WorkingTimePlan?.Edit;
    case "/SLA":
      return roleObj?.SLA?.View;
    case "/SLA/create-sla":
      return roleObj?.SLA?.Create;
    case "/SLA/:id":
      return roleObj?.SLA?.Edit;
    case "/Ticket-Status":
      return roleObj?.TicketStatus?.View;
    case "/EmailTemplates":
      return roleObj?.EmailTemplates?.View;
    case "/Add-EmailTemplates/:id":
      return roleObj?.EmailTemplates?.Edit;
    case "/Add-EmailTemplates":
      return roleObj?.EmailTemplates?.Create;
    case "/workflow-list":
      return roleObj?.Workflow?.View;
    case "/workflow/:id":
      return roleObj?.Workflow?.Edit;
    case "/workflow":
      return roleObj?.Workflow?.Create;
    case "/source":
      return false;
    case "/WhatsApp":
      return false;
    case "/AddWhatsApp":
      return false;
    case "/AddWhatsApp/:id":
      return false;
    case "/ActiveDirectory":
      return roleObj?.OrganisationAzureActiveDirectiory?.View;
    case "/AddActiveDirectory":
      return roleObj?.OrganisationAzureActiveDirectiory?.Create;
    case "/AddActiveDirectory/:id":
      return roleObj?.OrganisationAzureActiveDirectiory?.Edit;
    case "/EmailSettings":
      return roleObj?.MailService?.View;
    case "/Add-EmailSettings/:id":
      return roleObj?.MailService?.Edit;
    case "/Add-EmailSettings":
      return roleObj?.MailService?.Create;
    case "/PowerBISettings":
      return false;
    case "/AddPowerBI":
      return false;
    case "/AddPowerBI/:id":
      return false;
    case "/Intune":
      return false;
    case "/AddIntune":
      return false;
    case "/AddIntune/:id":
      return false;
    case "/ServicesSSPList":
      return false;
    case "/AddServicesSSP":
      return false;
    case "/ServicesSSPList/:id":
      return false;
    case "/UserManagement":
      return roleObj?.usrAdminAccess;
    case "/Groups":
      return roleObj?.Groups?.View;
    case "/Groups/add-groups":
      return roleObj?.Groups?.Create;
    case "/Groups/:id":
      return roleObj?.Groups?.Edit;
    case "/Agents":
      return roleObj?.Agent?.View;
    case "/Agents/add-agents":
      return roleObj?.Agent?.Create;
    case "/Agents/:id":
      return roleObj?.Agent?.Edit;
    case "/Agent-Groups":
      return (roleObj?.Agent?.Edit && roleObj?.Groups?.Edit) ? true : false;
    case "/Roles":
      return roleObj?.Roles?.View;
    case "/Roles/add-roles":
      return roleObj?.Roles?.Create;
    case "/Roles/:id":
      return roleObj?.Roles?.Edit;
    case "/Settings":
      return false;
    case "/organisationProfile":
      return false;
    case "/organisationSettings":
      return false;
    case "/MyProducts":
      return false;
    case "/about":
      return false;
    case "/Preferences":
      return true;
    case "/MyProfile":
      return true;
    case "/ContactManagement":
      return roleObj?.contactAdminAccess;
    case "/contact":
      return roleObj?.Contacts?.View;
    case "/add-contact/:id":
      return roleObj?.Contacts?.Edit;
    case "/add-contact":
      return roleObj?.Contacts?.Create;
    case "/Organization":
      return roleObj?.Organisation?.View;
    case "/Location":
      return roleObj?.Location?.View;
    case "/Location/Add-Location":
      return roleObj?.Location?.Create;
    case "/Add-Location/:id":
      return roleObj?.Location?.Edit;
    case "/ViewLocation/:id":
      return roleObj?.Location?.View;
    case "/LocationType":
      return roleObj?.LocationType?.View;
    case "/Organization/Add-Organization":
      return roleObj?.LocationType?.Create;
    case "/Add-Organization/:id":
      return roleObj?.LocationType?.Edit;
    case "/Division":
      return roleObj?.Division?.View;
    case "/Department":
      return roleObj?.Department?.View;
    case "/OrganizationType":
      return roleObj?.OrganisationType?.View;
    case "/AssetManagement":
      return roleObj?.assetAdminAccess;
    case "/CMDB":
      return roleObj?.CMDB?.View;
    case "/AddAsset":
      return roleObj?.CMDB?.Create;
    case "/AddAsset/:id":
      return roleObj?.CMDB?.Edit;
    case "/ViewAsset/:id":
      return roleObj?.CMDB?.View;
    case "/Asset-Type":
      return roleObj?.AssetType?.View;
    case "/Add-AssetType":
      return roleObj?.AssetType?.Create;
    case "/Add-AssetType/:id":
      return roleObj?.AssetType?.Edit;
    case "/AssetStatus":
      return roleObj?.AssetStatus?.View;
    case "/AdditionalFields":
      return roleObj?.AdditionalField?.View;
    case "/Add-AdditionalFields":
      return roleObj?.AdditionalField?.Create;
    case "/Add-AdditionalFields/:id":
      return roleObj?.AdditionalField?.Edit;
    case "/ReportsManagement":
      return roleObj?.ReportsManagementAccess;
    case "/All-Reports":
      return roleObj?.CustomReports?.View;;
    case "/CreateReport":
      return roleObj?.CustomReports?.Create;
    case "/CreateReport/view/:id":
      return roleObj?.CustomReports?.View;
    case "/CreateReport/:id":
      return roleObj?.CustomReports?.Edit;
    case "/All-Pivot-Table":
      return false;
    case "/Report":
      return false;
    case "/CSVReport":
      return roleObj?.CsvReports?.View;
    case "/pivot-table":
      return false;
    case "/pivot-table/:id":
      return false;
    case "/PowerBI":
      return roleObj?.PowerBiReport?.AdminAccess;
    case "/ViewReport/:id":
      return false;
    case "/TicketManagement":
      return roleObj?.TicketDashboard?.AdminAccess;
    case "/TicketManagementSys":
      return roleObj?.TicketDashboard?.AdminAccess;
    case "/home/ticket/new-ticket":
      return roleObj?.TicketDashboard?.AdminAccess;
    case "/home/ticket/:ticketId":
      return roleObj?.TicketDashboard?.AdminAccess;
    case "/TicketInfoView/:ticketId":
      return roleObj?.TicketDashboard?.AdminAccess;
    case "/calender":
      return roleObj?.TicketDashboard?.AdminAccess;
    case "/history/:ticketId":
      return roleObj?.TicketDashboard?.AdminAccess;
    case "/KnowledgeBase":
      return roleObj?.knowledgeBaseAccess;
    case "/ArticlesList":
      return roleObj?.Article?.View;
    case "/ViewArticle/:id":
      return roleObj?.Article?.View;
    case "/createArticle":
      return roleObj?.Article?.Create;
    case "/ArticlesList/:id":
      return roleObj?.Article?.Edit;
    case "/ArticleType":
      return roleObj?.ArticleType?.View;
    case "/SurveyList":
      return roleObj?.Survey?.View;
    case "/createSurvey":
      return roleObj?.Survey?.Create;
    case "/SurveyList/:id":
      return roleObj?.Survey?.Edit;
    case "/SurveyTemplates":
      return roleObj?.SurveyTemplates?.View;
    case "/AddSurveyTemplate":
      return roleObj?.SurveyTemplates?.Create;
    case "/AddSurveyTemplate/:id":
      return roleObj?.SurveyTemplates?.Edit;
    case "/Ranking":
      return roleObj?.Roles?.Edit;
    default:
      return false;
  }
};

export const returnFormValues = (currentTarget:any) =>{
  const formData = new FormData(currentTarget);
  let data: iFormData = {};
  formData.forEach((value, key) => {
      data[key] = value.toString();
  });
  return data;
}

// export const ConvertZ = (dateTime: any) => {
//   if (typeof dateTime === 'string' && !dateTime.toLowerCase().endsWith('z')) {
//     dateTime += 'z';
//   }
//   return moment(dateTime).local().format("L LT");
// }
export const ConvertZ = (dateTime: any) => {
  if (typeof dateTime === 'string') {
    if (!dateTime.toLowerCase().endsWith('z') && !dateTime.includes('+') && !dateTime.includes('-')) {
      dateTime += 'Z';
    }
    const parsedDate = moment.utc(dateTime)
    if (parsedDate.isValid()) {
      return parsedDate.local().format("L LT");
    }
  }
  return 'Invalid Date';
};
export const ConvertZWithoutFormat = (dateTime: any) => {
  if (typeof dateTime === 'string' && !dateTime.toLowerCase().endsWith('z')) {
    dateTime += 'z';
  }
  return dateTime
}

export const getPrioritiesWithTicketTypeKey = (priorities:any[]=[]) =>{
  const tempObj:any = {}
  priorities.map((i:any)=>{
      i.ticketTypeList.map((j:any)=>{
          tempObj[j?.ticketTypeId] = [...(tempObj[j?.ticketTypeId] || []), i]
      })
      return i;
  });
  return tempObj;
}