import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import moment from "moment";
import * as React from "react";
import CustomTheme from "./CustomTheme";
export default function RecipeReviewCard(props: any) {
  const {ticket,id,sourceCode} = props;
  console.log("after drop new is", props)

  const {  ticketId, description, openDateTime, title } = ticket;
  const classes = useStyles();
  const layout = React.useMemo(()=><Box className={`${classes.customSmlCard} cardHolder-card`}>
  <div className="cardHeader">
    <span>
      <b>{id} &nbsp;</b>
    </span>
    <span>
      <b>Source: {sourceCode || "-"}</b>
    </span>
  </div>

  <div className="p-0">
      <Tooltip
      title={
        <>
        <div className={`desc-box p-5p`}>
            <Typography variant="caption" display="block" className={`desc ellipses-4l`}>
              {title}
            </Typography>
            <Typography variant="caption" display="block" className="desc ellipses-4l">
              {description}
            </Typography>
            {ticket?.agent?.firstName &&
            <Typography variant="caption" display="block" className={`desc ellipses-4l`}>
              Owner : <b> {ticket?.agent?.firstName}</b>
            </Typography>
            }
            <Box className={`bt-1 ${classes.row}`}>
            <Typography variant="caption" display="block" className="desc">
              {moment(openDateTime).format("L")}
            </Typography>
            </Box>
        </div>
        </>
      }
      >
      <Box className="desc-box-holder p-5p">
        <Typography
          variant="caption"
          display="block"
          className="desc ellipses-2l"
        >
            {title}
        </Typography>
        {ticket?.agent?.firstName  &&
        <Typography
          variant="caption"
          display="block"
          className="desc ellipses-2l"
        >
            Owner : <b> {ticket?.agent?.firstName}</b>
        </Typography>
        }
      </Box>
      </Tooltip>
      
  </div>
  <div className="cardFooter">
    <span className={classes.primaryTxt}>
      {ticket?.contacts?.knownAs}  
    </span>
  </div>
</Box>,[ticket,classes])
  return (
    <>
      {layout}
    </>
  )
}
const data = CustomTheme();
const mode = data?.theme;

const useStyles = makeStyles(() =>
  createStyles(
  {
    customSmlCard:{
      overflow:'visible !important',
      background: "#fff",
      borderRadius: "3px",
      marginBottom: "2px",
      "& p":{
        fontSize:10
      },
      "& .desc":{
        fontSize: '10px',
      },
      "& .cardHeader":{
        background: mode.cardHeader,
        fontSize: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3px 7px'
      },
      "& .cardFooter":{
        borderTop: `1px solid ${mode.solids}`,
        fontSize: '10px',
        padding: '3px 7px',
        "& span":{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          display: "inline-block"
        }
      }
    },
    row: {
      display: "flex",
    },
    primaryTxt:{
      color : data?.mode === "light" ? '#1261b4' : mode.text, 
    }
  }))