import {
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
  } from "@mui/material";
  import { Link } from "react-router-dom";
  import React, { useState, useEffect } from "react";
  import { useNavigate } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import darkmodeImg from "./../../../../assest/icons/menu/darkmode.svg";
  import cog from "./../../../../assest/icons/menu/settings.svg";
  import preferencesIcon from "./../../../../assest/preferences.svg";
  import helpIcon from "./../../../../assest/icons/menu/how.svg";
  import flagIcon from "./../../../../assest/icons/menu/flag.svg";
  import ticketsIcon from "./../../../../assest/icons/menu/ticket.svg";
  import ChatIcon from "./../../../../assest/icons/menu/chat.svg";
  import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
  import profile from "./../../../../assest/profile.png";
  import * as locales from "@mui/material/locale";
  import moment from "moment";
  import LogoImg from "./../../../../assest/logo.png";
import { IState } from "../../../../redux/reducers/rootReducers";
import CommonStyles from "../../../common/CommonStyles";
import { theme } from "../../../../redux/actions/Theme/Theme";
import { DoLogOutAction } from "../../../../redux/actions/Login/Login";
import { storeAllResponses } from "../../../../redux/actions/TicketsManagement/TicketsManagement";
import { useMsal } from "@azure/msal-react";
  console.log("localeslocales", locales);
  type SupportedLocales = keyof typeof locales;
  export interface ITopNavbar {
    classNames?: string;
  }
  const TopNavBarForAdmin: React.FC<ITopNavbar> = (props:ITopNavbar) => {
    const {classNames=""} = props;
    const navigate = useNavigate();
    const toggleTheme = useSelector((state: IState) => state.theme.theme);
    const useStyles = CommonStyles;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loc, setLoc] = useState([] as any);
    const profileImg = useSelector(
      (state: IState) => state?.agentReducer?.saveProfile
    );
    const open = Boolean(anchorEl);
  
    useEffect(() => {
      const temp = Object.keys(locales);
      temp.unshift("enGb");
      setLoc(temp);
    }, [locales]);
    const { instance, accounts } = useMsal();
    function LogOut() {
      if(instance.logout) instance.logout();
      dispatch(theme(false));
      dispatch(DoLogOutAction());
      dispatch(storeAllResponses({}));
      navigate("/");
    }
    function handleClose() {
      setAnchorEl(null);
    }
    const toggleDarkTheme = () => {
      dispatch(theme(!toggleTheme));
      //ThemeStatus()
    };
    return (
      <div className={`${classes.topBar} ${classNames}`}>
        <Toolbar className="d-flx sm-hide">
          <div style={{paddingLeft:"45px"}}>
            {/* <img src={LogoImg} alt="" height={50} /> */}
          </div>
          <div className="sm-hide">
            <Link to={`/MyProfile`}>
              <IconButton color="inherit">
                {!profileImg && profileImg === "" ? (
                  <img
                    src={profile}
                    className="proImg"
                    alt=""
                    height={80}
                  />
                ) : (
                  <img
                    src={`${profileImg}`}
                    alt=""
                    className="proImg"
                    height={80}
                  />
                )}
              </IconButton>
            </Link>
            <IconButton onClick={toggleDarkTheme} color="inherit">
              <img src={darkmodeImg} alt="" />
            </IconButton>
            {/* <Link to={`/Preferences`}>
              <IconButton color="inherit">
                <img src={preferencesIcon} height={20} alt="" />
              </IconButton>
            </Link> */}
            {/* <Link to={`/organisationSettings`}>
              <IconButton color="inherit">
                <img src={cog} alt="" />
              </IconButton>
            </Link> */}
            {/* <IconButton color="inherit">
              <img src={helpIcon} alt="" />
            </IconButton> */}
  
            {/* <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event: any) => {
                setAnchorEl(event.currentTarget);
              }}
              color="inherit"
            >
              <img className="flagImg" src={flagIcon} alt="" />
            </IconButton> */}
            {/* <Menu
              id="basic-menu"
              sx={{ height: 300 }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {loc?.map((item: any) => {
                return (
                  <MenuItem
                    onClick={(ele: any) => {
                      let temp = `${item.substring(0, 2)}-${item.substring(
                        2,
                        4
                      )}`;
                      console.log("clicked lang", temp);
                      localStorage.setItem("lang", temp);
                      moment.locale(temp);
                      handleClose();
                    }}
                  >
                    {item.substring(0, 2)}-{item.substring(2, 4)}
                  </MenuItem>
                );
              })}
            </Menu> */}
  
            {/* <IconButton color="inherit" className="notificationIcon">
              <img src={ticketsIcon} alt="" />
              <span className="count">3</span>
            </IconButton> */}
            {/* <Link to={`/`}>
              <IconButton color="inherit">
                <img src={ChatIcon} alt="" />
              </IconButton>
            </Link> */}
            <IconButton className="logoutBtn" color="inherit" onClick={LogOut}>
              <LogoutOutlinedIcon />
            </IconButton>
          </div>
        </Toolbar>
      </div>
    );
  };
  
  export default TopNavBarForAdmin;
  