// Refractoring code in progress.
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
ChartJS.defaults.font.family = "'DM Sans', sans-serif";
ChartJS.defaults.font.size = 14;

const footer = (tooltipItems: any) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem: any) {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};


const optionsForTicketTrendsChart : any = {
  maintainAspectRatio: false, // Don't maintain w/h ratio
  plugins: {
    legend: {
      display:true, 
      position: 'top',
      labels: {
          usePointStyle: false,
          boxHeight: 2

        },
    },
    
    ChartDataLabels,
    datalabels: {
      display: true,
      color: "white",
      font: {
        size: 9,
        weight: "bold"
      }
    },
    tooltip: {
      display: false,
      callbacks: {
        footer: footer,
      },
      usePointStyle: true,
  
      filter: function (tooltipItem: any) {
        //console.log('tooltipItem.datasetIndex', tooltipItem.dataIndex,tooltipItem.dataset.data[tooltipItem.dataIndex]  )
        var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
        if (value === 0) {
            return false;
        } else {
            return true;
        }
    }
   }
  },
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  animations: {
    radius: {
      duration: 400,
      easing: 'linear',
      loop: (context: any) => context.active
    }
  },
  scales: {
    y: {
      ticks: {
        callback: (yValue: any) => {
          return Math.floor(yValue); // format to your liking
        },
      },
    },
  }
};

const LineChart = ({datasets, labels}: {datasets: any, labels: any}) => {

  const data = {
    labels: labels,
    datasets: datasets
  };
  
    return (
      <>
      <Line data={data} options={optionsForTicketTrendsChart} className="line-chart" />
      </>
    );
  };
  
  export default LineChart;
  