const initialState = {
    AllEmailTemplates: [],
    AllTemplateActions:[]
  }
  const EmailTemplatesReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'GET_TEMPLATES':
            return {
                ...state,
                AllEmailTemplates: action.payload,
            };
      case 'GET_ACTIONS':
            return {
                ...state,
                AllTemplateActions: action.payload,
            };
        default:
            return state;
    }
  }
  
  export default EmailTemplatesReducer;
  